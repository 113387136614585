import { useState, useEffect } from "react";
import axios from "axios";
import { Flex, Loader } from "@fluentui/react-northstar";
import { toast } from "react-toastify";
import { Button, CloseIcon, AcceptIcon } from "@fluentui/react-northstar";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components";
import { Input } from "@mui/material";
import { Edit, Trash } from "react-feather";

import { AlertDialog } from "../../../../../components/Dialog/AlertDialog";
import BulkUploadProfileData from "../BulkUpload";
import { useTranslation } from "react-i18next";

const Card = styled(Flex)`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const END_POINT = process.env.REACT_APP_EP_URL;

const ProfileInterests = (props) => {
  const [interests, setInterests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState({
    id: "",
    title: "",
    category: "",
    isEdit: false,
  });
  // const [addInterest, setAddInterest] = useState({
  //   isEdit: false,
  //   title: "",
  //   category: "",
  // });

  const [loading, setLoading] = useState(false);
  const [isBulkUploadScreen, setIsBulkUploadScreen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.user.slug && props.user.accessToken) {
      getInterests();
    }
  }, []);

  const getInterests = () => {
    setIsLoading(true);
    const slug = props.user.slug;
    const token = props.user.accessToken;
    axios
      .get(`${END_POINT}/api/profile/get-interests${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data?.data?.length) {
          setInterests(
            data.data.sort((a, b) => {
              let interest1 = a.category?.toUpperCase();
              let Interest2 = b.category?.toUpperCase();
              return interest1 < Interest2 ? -1 : interest1 > Interest2 ? 1 : 0;
            })
          );
        } else {
          setInterests([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddInterest = () => {
    setLoading(true);
    axios
      .post(
        `${END_POINT}/api/profile/add-interest${props.user.slug}`,
        {
          title: props.addInterest.title,
          category: props.addInterest.category,
        },
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        props.setAddInterest({ isEdit: false, title: "", category: "" });
        getInterests();
      })
      .catch((err) => {
        setLoading(false);
        setEdit({ id: "", title: "", category: "", isEdit: false });
        toast.error(err?.response?.data?.message);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    axios
      .put(
        `${END_POINT}/api/profile/update-interest${props.user.slug}`,
        {
          id: edit.id,
          title: edit.title,
          category: edit.category,
        },
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`,
          },
        }
      )
      .then((res) => {
        setEdit({ id: "", title: "", category: "", isEdit: false });
        getInterests();
      })
      .catch((err) => {
        // setEdit({ id: "", title: "", category: "", isEdit: false });
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    axios
      .delete(
        `${END_POINT}/api/profile/delete-interest/${edit.id}${props.user.slug}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`,
          },
        }
      )
      .then((res) => {
        setEdit({ id: "", title: "", category: "", isEdit: false });
        getInterests();
      })
      .catch((err) => {
        // setEdit({ id: "", title: "", category: "", isEdit: false });
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return isLoading ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <>
      <AlertDialog
        handleClose={handleClose}
        handleOk={handleDelete}
        open={open}
      />
      <div className="btsp interest-container">
        {!isBulkUploadScreen && (
          <Flex hAlign="end" wrap style={{ rowGap: "10px", columnGap: "10px" }}>
            <Button
              content={t("setting").profile[4]}
              onClick={() => {
                setIsBulkUploadScreen(true);
              }}
            />
            <Button
              content={t("setting").profile[5]}
              primary
              onClick={() => props.setAddInterest({ isEdit: true })}
            />
          </Flex>
        )}
        <div className="pt-3">
          {isBulkUploadScreen ? (
            <BulkUploadProfileData
              {...props}
              setIsBulkUploadScreen={setIsBulkUploadScreen}
              type={"interest"}
              refreshData={getInterests}
              header={"Interests"}
            />
          ) : (
            <div className="ms-Grid">
              {(props.addInterest.isEdit || !!interests.length) && (
                <Card className="ms-Grid-row my-3">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                    Title
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 text-center">
                    Category
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4"></div>
                </Card>
              )}
              {props.addInterest.isEdit && (
                <>
                  <Card className="ms-Grid-row my-3 ">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                      <Input
                        value={props.addInterest.title}
                        fullWidth
                        sx={{ fontSize: 12 }}
                        onChange={(e) => {
                          props.setAddInterest({
                            ...props.addInterest,
                            title: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                      <Input
                        value={props.addInterest.category}
                        fullWidth
                        sx={{ fontSize: 12 }}
                        onChange={(e) => {
                          props.setAddInterest({
                            ...props.addInterest,
                            category: e.target.value?.toLowerCase(),
                          });
                        }}
                      />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 text-right">
                      <LoadingButton
                        loading={loading}
                        startIcon={!loading && <AcceptIcon />}
                        iconOnly
                        title="Submit"
                        onClick={handleAddInterest}
                        disabled={
                          !props.addInterest.title?.trim()?.length ||
                          !props.addInterest.category?.trim()?.length
                        }
                        style={{
                          color:
                            !props.addInterest.title?.trim()?.length ||
                            !props.addInterest.category?.trim()?.length
                              ? "#B2B2B2"
                              : "#585A96",
                          borderRadius: 5,
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
                          border: "1px solid rgb(225, 223, 221)",
                          width: 33,
                          minWidth: 0,
                          height: 31,
                        }}
                      />
                      <Button
                        icon={<CloseIcon />}
                        iconOnly
                        title="Cancel"
                        onClick={() =>
                          props.setAddInterest({
                            isEdit: false,
                            title: "",
                            category: "",
                          })
                        }
                        style={{ color: "#DE5854", borderRadius: 5 }}
                      />
                    </div>
                  </Card>
                </>
              )}
              {!!interests.length &&
                interests.map((data) => {
                  return (
                    <>
                      <Card className="ms-Grid-row my-3">
                        {edit.isEdit && edit.id === data.id ? (
                          <>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                              <Input
                                value={edit.title}
                                fullWidth
                                sx={{ fontSize: 12 }}
                                onChange={(e) => {
                                  setEdit({ ...edit, title: e.target.value });
                                }}
                              />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 text-center">
                              <Input
                                value={edit.category}
                                fullWidth
                                sx={{ fontSize: 12 }}
                                onChange={(e) => {
                                  setEdit({
                                    ...edit,
                                    category: e.target.value?.toLowerCase(),
                                  });
                                }}
                              />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 text-right">
                              <LoadingButton
                                loading={loading}
                                startIcon={
                                  !loading && (
                                    <AcceptIcon style={{ margin: 0 }} />
                                  )
                                }
                                iconOnly
                                title="Submit"
                                onClick={handleEdit}
                                disabled={!edit.title?.trim()?.length}
                                style={{
                                  color: !edit.title?.trim()?.length
                                    ? "#B2B2B2"
                                    : "#585A96",
                                  borderRadius: 5,
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
                                  border: "1px solid rgb(225, 223, 221)",
                                  width: 33,
                                  minWidth: 0,
                                  height: 31,
                                }}
                              />
                              <Button
                                icon={<CloseIcon />}
                                iconOnly
                                title="Cancel"
                                onClick={() => {
                                  setEdit({
                                    id: "",
                                    title: "",
                                    category: "",
                                    isEdit: false,
                                  });
                                }}
                                style={{ color: "#DE5854", borderRadius: 5 }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                              {data.title?.length
                                ? data.title
                                    .split(" ")
                                    .map((word) =>
                                      word?.length
                                        ? word[0].toUpperCase() + word.substr(1)
                                        : ""
                                    )
                                    .join(" ")
                                : ""}
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 text-center">
                              {data.category}
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 text-right">
                              <Button
                                icon={<Edit />}
                                iconOnly
                                title="Edit Interest"
                                onClick={() => {
                                  setEdit({
                                    id: data.id,
                                    title: data.title,
                                    category: data.category,
                                    isEdit: true,
                                  });
                                }}
                                style={{ color: "#585A96", borderRadius: 5 }}
                              />
                              <LoadingButton
                                loading={data.id === edit.id ? loading : false}
                                startIcon={
                                  (data.id !== edit.id || !loading) && (
                                    <Trash
                                      height={18}
                                      width={18}
                                      style={{ margin: 0 }}
                                    />
                                  )
                                }
                                iconOnly
                                title="Delete"
                                onClick={() => {
                                  setOpen(true);
                                  setEdit({ id: data.id, isEdit: false });
                                }}
                                style={{
                                  color: "#DE5854",
                                  borderRadius: 5,
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
                                  border: "1px solid rgb(225, 223, 221)",
                                  width: 33,
                                  minWidth: 0,
                                  height: 31,
                                }}
                                sx={{ imageResolution: 10 }}
                              />
                            </div>
                          </>
                        )}
                      </Card>
                    </>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ProfileInterests;
