import React, { useEffect, useState } from "react";
import FilterDrawer from "./Drawer";
import axios from "axios";
const END_POINT = process.env.REACT_APP_EP_URL;

function FilterCollection({
  setFilteredCollections,
  setLoading,
  open,
  setCollection,
  collection,
  setSearch,
  search,
  filterOption,
  setSelectedFilter,
  slug,
  token,
  allCollection,
}) {
  const [filterDataWithKeys, setFilterDataWithKeys] = useState({
    age: "",
    author: [],
    tags: [],
  });
  const [isFilterApply, setFilterApply] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const sortByContet = [
    "Sort by :",
    "Newest",
    "MostLiked",
    "Title(A-Z)",
    "Author(A-Z)",
  ];

  const selectArray = [
    {
      title: "Age",
      label: ["7 Days", "1 Months", "3 Months", "6 Months"],
      id: "age",
      search: false,
      selected: filterDataWithKeys?.age ? 1 : 0,
    },
    {
      title: "Author",
      label: filterOption?.authors,
      id: "author",
      search: true,
      selected: filterDataWithKeys?.author?.length || 0,
    },
    {
      title: "Tags",
      label: filterOption?.tags,
      id: "tags",
      search: true,
      selected: filterDataWithKeys?.tags?.length || 0,
    },
  ];

  const handleSearch = () => {
    if (!!search.trim()) {
      const searchedCollections = collection.filter((collection) =>
        collection.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredCollections(searchedCollections);
    } else {
      const searchedCollections = allCollection?.filter((collection) =>
        collection?.name?.toLowerCase()?.includes(search.toLowerCase())
      );
      setFilteredCollections(searchedCollections);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    fetchAllCollection();
  }, []);

  const fetchFilteredData = async (body) => {
    axios
      .post(
        `${process.env.REACT_APP_EP_URL_WHITEBOARD_API}/get-filtered-templates-data-for-superadmin${slug}`,
        body
      )
      .then(async (res) => {
        setSelectedFilter(res?.data?.result?.resources?.length > -1);
        setCollection(res.data.result.resources);
      })
      .catch((err) => {
        console.error(err, err.response);
      });
  };

  const removeFilters = async () => {
    try {
      setLoading(true);
      const { result } = await fetchAllCollection({});
      setFilteredCollections(result.resources);
      setFilterDataWithKeys({
        ...filterDataWithKeys,
        age: "",
        author: [],
        tags: [],
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchAllCollection = (sort, search) => {
    try {
      axios
        .get(
          `${END_POINT}/api/user/fetch-collection${slug}${
            search ? "&search=" + search : ""
          }${sort ? "&sort=" + sort : ""}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setSelectedFilter(result?.data?.result?.resources?.length > -1);
          setFilteredCollections(result?.data?.result?.resources);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error("errror", error);
    }
  };

  const onSortCollection = async (index) => {
    if (index === 1) {
      setFilterData([]);
      setFilterDataWithKeys({
        age: "",
        author: [],
        tags: [],
      });
    }
    setLoading(true);
    try {
      setSearch("");
      const { result } = await fetchAllCollection(index);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="d-flex align-items-center" style={{ display: "flex" }}>
      <input
        type="text"
        id="search"
        name="search"
        placeholder="Search Collection"
        className="m-0 w-100 mt-lg-0 mt-3 d-block"
        style={{
          padding: "8px 15px",
          margin: " 8px 0",
          borderRadius: "4px",
          border: "1.5px solid #bebebe",
        }}
        value={search || ""}
        onChange={(e) => {
          const value = e.target.value === "" ? "" : e.target.value;
          setSearch(value);
          if (value === null) {
            setFilteredCollections(collection);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: "2%",
        }}
      >
        {sortByContet.map((sortText, index) => {
          return (
            <p
              className="text-nowrap"
              style={{ cursor: "pointer" }}
              onClick={() => onSortCollection(index)}
            >
              {sortText}
            </p>
          );
        })}
        <div className="d-flex justify-content-end pt-lg-0 align-items-center">
          <div>
            <FilterDrawer
              selectArray={selectArray}
              isOpen={open}
              removeFilters={removeFilters}
              filterApply={isFilterApply}
              setFilterDataWithKeys={setFilterDataWithKeys}
              filterDataWithKeys={filterDataWithKeys}
              filterData={filterData}
              setFilterData={setFilterData}
              setFilterApply={setFilterApply}
              fetchFilteredData={fetchFilteredData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterCollection;
