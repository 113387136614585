import React, { useRef, useState } from "react";
import "../ACVExtension/ACVExtension.css";
import whiteboard from "../../Assets/images/svg/whiteboard.svg";
import assignment from "../../Assets/images/svg/assignment.svg";
import dashboard from "../../Assets/images/svg/dashboard.svg";
import events from "../../Assets/images/svg/event.svg";
import attendance from "../../Assets/images/svg/attendance.svg";
import feedback from "../../Assets/images/svg/feedback.svg";
import ConfirmDialog from "../Assignment/Components/ConfirmDialog/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { ReCaptcha } from "../../components/ReCaptcha";
import { CircularProgress, IconButton } from "@mui/material";
import {
  drawCaptchaOnCanvas,
  generateCaptchaText,
} from "../../utils/reCaptcha";

const extensionsName = {
  ASSIGNMENT: "assignment",
  ATTANDANCE: "attendance",
  DASHBOARD: "dashboard",
  WHITEBOARD: "whiteBoard",
  EVENTS: "events",
  FEEDBACK: "feedback",
};

function ACVExtension(props) {
  const extensions = [
    {
      id: 1,
      name: extensionsName.ATTANDANCE,
      image: attendance,
      background: "bg-primary",
      shadow: "shadow-primary",
      isSelected: true,
    },
    {
      id: 2,
      name: extensionsName.ASSIGNMENT,
      image: assignment,
      background: "bg-warning",
      shadow: "shadow-warning",
      isSelected: true,
    },
    {
      id: 3,
      name: extensionsName.DASHBOARD,
      image: dashboard,
      background: "bg-danger",
      shadow: "shadow-danger",
      isSelected: true,
    },
    {
      id: 4,
      name: extensionsName.WHITEBOARD,
      image: whiteboard,
      background: "bg-info",
      shadow: "shadow-info",
      isSelected: true,
    },
    {
      id: 5,
      name: extensionsName.EVENTS,
      image: events,
      background: "bg-success",
      shadow: "shadow-success",
      isSelected: true,
    },
    {
      id: 6,
      name: extensionsName.FEEDBACK,
      image: feedback,
      background: "bg-dark",
      shadow: "shadow-dark",
      isSelected: true,
    },
  ];
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [storeExtention, setStoreExtention] = useState({});
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const canvasRef = useRef(null);
  const { t } = useTranslation();

  const initializeCaptcha = (ctx) => {
    setUserInput("");
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  return (
    <>
      {isOpenConfirmationModal && (
        <ConfirmDialog
          id="extension-dialog"
          isConfirmDelete={isOpenConfirmationModal}
          setisConfirmDelete={() => {
            setIsOpenConfirmationModal(false);
            props.setCheckInputCaptchaBlankOrNot(false);
          }}
          cancelButton={t("extention").cancel}
          confirmButton={
            <div className="button-confirm">
              {t("extention").continue}
              {props.loader && (
                <IconButton className="loader-button">
                  <CircularProgress className="progressbar-loader" />
                </IconButton>
              )}
            </div>
          }
          content={
            <div className="captcha-content">
              <ReCaptcha
                captchaText={captchaText}
                setCaptchaText={setCaptchaText}
                userInput={userInput}
                setUserInput={setUserInput}
                canvasRef={canvasRef}
                initializeCaptcha={initializeCaptcha}
                setCheckInputCaptchaBlankOrNot={
                  props.setCheckInputCaptchaBlankOrNot
                }
                checkInputCaptchaBlankOrNot={props.checkInputCaptchaBlankOrNot}
              />
              {t("extention").content}
            </div>
          }
          header={`${
            props.selectedExtension[storeExtention.name]
              ? t("extention").deactivate
              : t("extention").activate
          } ${t("extention").extension}`}
          onConfirm={() => {
            props.updateExtension(
              {
                [storeExtention.name]:
                  !props.selectedExtension[storeExtention.name],
              },
              setIsOpenConfirmationModal,
              storeExtention,
              userInput,
              captchaText,
              canvasRef,
              initializeCaptcha
            );
          }}
          onCancel={() => {
            setIsOpenConfirmationModal(false);
            props.setCheckInputCaptchaBlankOrNot(false);
          }}
        />
      )}
      <div className="container">
        <div className="row mx-n2 btsp">
          {extensions.map((data, index) => {
            return (
              <>
                <div className="col-lg-2 col-sm-3 px-2">
                  <div
                    className={`card hover-shadow-lg mb-3 hover-card ${
                      props.selectedExtension[data.name] ? "active" : ""
                    }`}
                    onClick={() => {
                      setIsOpenConfirmationModal(true);
                      setStoreExtention(data);
                    }}
                  >
                    <span
                      className={`${
                        props.selectedExtension[data.name]
                          ? "active-round position-absolute top-1 left-1 p-2 border border-light rounded-circle"
                          : ""
                      }`}
                    ></span>
                    <div className="card-body text-center py-4 px-0">
                      <div className="mb-3 mt-2">
                        <div
                          className={`icon icon-shape icon-lg text-white overflow-hidden ${data.background} ${data.shadow}`}
                        >
                          <img src={data.image} alt="dashboard-image" />
                        </div>
                      </div>
                      <h5 className="h5 font-weight-bolder mb-0 text-capitalize">
                        {data.name}
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ACVExtension;
