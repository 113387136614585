import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Button,
  EditIcon,
  Loader,
  Flex,
  Dialog,
  CloseIcon,
} from "@fluentui/react-northstar";
import { CameraIcon, TranscriptIcon } from "@fluentui/react-icons-northstar";
import { TrashCanIcon } from "@fluentui/react-icons-northstar";
import styled from "styled-components";
import { Modal } from "antd";
import { Avatar } from "@fluentui/react-northstar";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { withTranslation } from "react-i18next";

import { NotificationAlert } from "../../components/Notification/Notification";

import Header from "../../components/Header/Header";
import EditProfile from "./Components/EditProfile";
import Profile from "./Components/Profile";
import CustomProgressIndicator from "../../components/Loader/CustomProgressIndicator";
import StudentProgress from "../../components/studentProgress";

import "./Account.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import i18n from "../../helper/i18n";

const ImageEdit = "ImageEdit";
const ImageDelete = "ImageDelete";

const EditButton = styled(Button)`
  padding: 5px 10px;
  border-radius: 3px !important;
`;

const SocialMediaButtonConatiner = styled(Flex)`
  margin-top: 5px;
  row-gap: 5px;
  column-gap: 5px;
`;

const SocialMediaButton = styled(Button)`
  border-radius: 50% !important;
`;

const MyProgressButton = styled(Button)`
  padding: 5px 10px;
  border-radius: 3px !important;
`;

const url = process.env.REACT_APP_EP_URL;

const Account = (props) => {
  const { refreshUserProfileData, t, settings } = props;
  const translation = t("feedback");
  const feedbackTranslation = translation.body.mainPage;

  const inputRef = useRef(null);
  const [userContentEditable, setUserContentEditable] = useState(false);
  const [fileData, setFileData] = useState(props.user.UrlPhoto);
  const [fileObject, setFileObject] = useState(null);
  const [croppedFileObject, setCroppedFileObject] = useState(null);
  const [croppedImage, setCroppedImage] = useState(props.user.UrlPhoto);
  const [openModal, setOpenModal] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [userDetails, setUserDetails] = useState({ ...props.user });
  const [userProfileData, setUserProfileData] = useState({});
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loadingImage, setLoadingImage] = useState({
    type: null,
    isLoading: false,
  });
  const [open, setOpen] = useState(false);
  const [isOpenAvatarGenerator, setIsOpenAvatarGenerator] = useState(false);
  const [pathHeader, setPathHeader] = useState("");
  const history = useHistory();

  const headers = {
    Authorization: "Bearer " + props.user.accessToken,
    "content-type": "multipart/form-data",
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const languageMappings = {
    en: "English",
    "en-GB": "English",
    "en-US": "English",
    es: "Spanish",
    pt: "Portuguese",
    de: "German",
    fr: "French",
    hi: "Hindi",
    // Add other languages as needed
  };

  const currentLanguage = languageMappings[i18n.language];

  // const handleFileChange = (event) => {
  //   const fileObj = event.target.files && event.target.files[0];
  //   if (!fileObj) return;

  //   if (fileObj.size > 100000) {
  //     toast.warning("File size too large!");
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.onload = function (event) {
  //     setFileData(reader.result);
  //   };
  //   reader.readAsDataURL(fileObj);
  //   const formData = new FormData();
  //   formData.append("file", fileObj);
  //   axios
  //     .post(`${url}/api/user/update-user-profile${props.user.slug}`, formData, {
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       setFileData(props.user.UrlPhoto);
  //       toast.success(res?.data?.message);
  //       // console.log(res.data);
  //     })
  //     .catch((err) => {
  //       setFileData(props.user.UrlPhoto);
  //       toast.error(err?.response?.data?.message || err?.response?.data?.error);
  //       console.log(err, err.response);
  //       if (err.response.data.status === "Error") {
  //         toast.warning(err.response.data.error);
  //       }
  //     });
  // };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) return;
    setFileObject(fileObj);
    const reader = new FileReader();
    reader.onload = function (event) {
      setFileData(reader.result);
    };
    reader.readAsDataURL(fileObj);
    $("#exampleModal").modal("show");
  };

  const handleUpdateProfileImage = () => {
    setLoadingImage({
      type: ImageEdit,
      isLoading: true,
    });
    $("#exampleModal").modal("hide");

    const formData = new FormData();
    formData.append("file", croppedFileObject);

    axios
      .post(`${url}/api/user/update-user-profile${props.user.slug}`, formData, {
        headers: headers,
      })
      .then((res) => {
        setFileData(props.user.UrlPhoto);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        setFileData(props.user.UrlPhoto);
        toast.error(err?.response?.data?.message || err?.response?.data?.error);
        console.log(err, err.response);
      })
      .finally(() => {
        setLoadingImage({
          type: null,
          isLoading: false,
        });
        refreshUserProfileData();
      });
  };

  const handleDeleteProfileImage = () => {
    setLoadingImage({
      type: ImageDelete,
      isLoading: true,
    });

    $("#exampleModal").modal("hide");

    axios
      .delete(`${url}/api/user/delete-user-profile-image${props.user.slug}`, {
        headers: headers,
      })
      .then((res) => {
        setFileData(props.user.UrlPhoto);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        setFileData(props.user.UrlPhoto);
        toast.error(err?.response?.data?.message || err?.response?.data?.error);
        console.log(err);
      })
      .finally(() => {
        refreshUserProfileData();
        setLoadingImage({
          type: null,
          isLoading: false,
        });
      });
  };

  const handleUserInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const [cropConfig, setCropConfig] = useState({
    unit: "px",
    width: 100,
    height: 100,
  });
  const [imageRef, setImageRef] = useState(null);

  function getCroppedImage(sourceImage, cropConfig) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error(feedbackTranslation.emptyCanvasError));
          return;
        }

        //  Split the file name into parts using the period (.) as the delimiter
        const parts = fileObject.name.split(".");

        // The last part of the split result is the file extension
        const file_extension = parts.pop();

        const fileName = `${props.user?.mail}.${file_extension}`;
        const file = new File([blob], fileName, {
          type: fileObject.type,
        });
        setCroppedFileObject(file);
        blob.name = fileName;
        const croppedImageUrl = window.URL.createObjectURL(blob);
        resolve(croppedImageUrl);
      }, fileObject.type);
    });
  }

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(imageRef, crop);
      setCroppedImage(croppedImage);
    }
  }

  const editButtonHandler = () => {
    setIsEditProfile(true);
  };

  const getProfileData = async () => {
    try {
      setIsLoadingData(true);
      const { slug, mail, accessToken } = props.user;
      const { data } = await axios.get(
        `${url}/api/profile/get-profile/${mail}${slug}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (data?.data) {
        let profileData = {
          ...data.data,
          skills: data.data.ProfileSkills,
          interests: data.data.ProfileInterests,
        };
        setUserProfileData(profileData);
      } else {
        setUserProfileData({});
      }
    } catch (error) {
      NotificationAlert(
        typeof error?.response?.data?.message === "string"
          ? error.response.data.message
          : feedbackTranslation.saveError,
        "error"
      );
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, [isRefreshData]);

  useEffect(() => {
    setFileData(props.user.UrlPhoto);
    setCroppedImage(
      props.user.UrlPhoto
        ? props.user.UrlPhoto + `&date=${new Date().getSeconds()}`
        : null
    );
  }, [props.user]);

  // useEffect(() => {
  //   return () => {
  //     if(isEditProfile){
  //       setOpen(true);
  //     }
  //     console.log("open", open);
  //   };
  // }, []);
  const postCrossDomainMessage = () => {
    const iframeEl = document.getElementsByClassName("avatar-video-generator");
    // Make sure you are sending a string, and to stringify JSON
    iframeEl[0]?.contentWindow?.postMessage(
      {
        source: "gan",
        message: { accessToken: props.user.accessToken },
      },
      process.env.REACT_APP_GAN
    );
  };

  const iframeSrc = `${process.env.REACT_APP_GAN}${props.user.slug}&owner=${
    props.user.mail
  }&language=${currentLanguage}&host=gan.acv.app&gpu=0&database=${
    process.env.REACT_APP_STORAGE === "acvstgpreprod" ? "preprod" : "prod"
  }`;

  return (
    <>
      <Header
        path="account"
        user={props.user}
        logout={props.logout}
        accessToken={props.user.accessToken}
        getDb={props.getDb.bind()}
        switchDb={props.switchDb}
        isEditProfile={isEditProfile}
        setOpen={setOpen}
        setPathHeader={setPathHeader}
        selectedExtension={props.selectedExtension}
        setSelectedExtension={props.setSelectedExtension}
      />
      <CustomProgressIndicator
        isLoading={loadingImage.isLoading}
        style={{
          progressTrack: {
            backgroundColor: "#ffffff",
          },
        }}
      />

      {/* <Dialog
        open={open}
        onConfirm={() => {
          pathHeader === "attendance"
            ? history.push("/attendance")
            : pathHeader === "assignments"
            ? history.push("/assignments")
            : pathHeader === "dashboard"
            ? history.push("/dashboard")
            : pathHeader === "whiteboard"
            ? history.push("/whiteboard")
            : pathHeader === "/events/my-events"
            ? history.push("/events/my-events")
            : pathHeader === "/feedback/all-feedback"
            ? history.push("/feedback/all-feedback")
            : pathHeader === "/settings"
            ? history.push("/settings")
            : null;
          setOpen(false);
        }}
        confirmButton="Okay"
        content={
          "Do you really want change this page? You will lose all the changes that are not saved."
        }
        header="Return to Home page"
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => setOpen(false),
        }}
        styles={{ width: "fit-content !important" }}
      /> */}
      {props?.user?.role !== "Student" && (
        <Dialog
          open={isOpenAvatarGenerator}
          onLoad={postCrossDomainMessage}
          content={
            <iframe
              className="avatar-video-generator"
              src={iframeSrc}
              style={{
                height: "calc(100vh - 190px)",
                width: "100%",
                border: 0,
              }}
            ></iframe>
          }
          headerAction={{
            icon: <CloseIcon />,
            title: "Close",
            onClick: () => setIsOpenAvatarGenerator(false),
          }}
        />
      )}

      <div className="btsp account-container">
        <div className="container">
          {isEditProfile ? (
            <EditProfile
              {...props}
              {...{
                user: { ...props.user },
                userProfileData,
                setUserProfileData,
                isEditProfile,
                setIsEditProfile,
                setIsRefreshData,
              }}
            />
          ) : (
            <div>
              <div className="profile_block_1 p-4">
                <Flex>
                  <div className="avatar_div">
                    <div className="avatar_profile">
                      {!croppedImage ? (
                        <img
                          accept="image/*"
                          src="https://preview.webpixels.io/quick-website-ui-kit/assets/img/theme/light/person-auth.jpg"
                          alt={feedbackTranslation.userProfile}
                          className="w-100 h-100 shade"
                        />
                      ) : (
                        croppedImage && (
                          <Avatar
                            image={croppedImage ? croppedImage : ""}
                            size="largest"
                            className="shade"
                            name={props.user?.name.toUpperCase()}
                          />
                        )
                      )}

                      <div className="camera_icon">
                        <div className="icon-block">
                          <CameraIcon
                            onClick={handleClick}
                            className="icons"
                            id="cameraIcon"
                          />
                          <TrashCanIcon
                            className="trash_icon"
                            onClick={() => setOpenModal(true)}
                          />
                        </div>
                      </div>
                      <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className="profile_info">
                    {/* <h2 className="profile_name text-center"> */}
                    <h2 className="profile_name">
                      <b>{props?.user?.displayName ?? props?.user?.name}</b>
                    </h2>
                    <span>{props.user.role}</span>
                    <p>{props.user.mail}</p>

                    {/* social media buttons */}
                    <SocialMediaButtonConatiner wrap>
                      {!!userProfileData?.instagramProfile?.length && (
                        <SocialMediaButton
                          circular
                          icon={<InstagramIcon style={{ color: "#8134AF" }} />}
                          title={feedbackTranslation.socialProfile.instagram}
                          onClick={() => {
                            window.open(
                              userProfileData.instagramProfile,
                              "_blank"
                            );
                          }}
                        />
                      )}
                      {!!userProfileData?.facebookProfile?.length && (
                        <SocialMediaButton
                          circular
                          icon={<FacebookIcon style={{ color: "#4267B2" }} />}
                          title={feedbackTranslation.socialProfile.facebook}
                          onClick={() => {
                            window.open(
                              userProfileData.facebookProfile,
                              "_blank"
                            );
                          }}
                        />
                      )}
                      {!!userProfileData?.linkedinProfile?.length && (
                        <SocialMediaButton
                          circular
                          icon={<LinkedInIcon style={{ color: "#0077b5" }} />}
                          title={feedbackTranslation.socialProfile.linkedIn}
                          onClick={() => {
                            window.open(
                              userProfileData.linkedinProfile,
                              "_blank"
                            );
                          }}
                        />
                      )}
                      {!!userProfileData?.twitterProfile?.length && (
                        <SocialMediaButton
                          circular
                          icon={<TwitterIcon style={{ color: "#1DA1F2" }} />}
                          title={feedbackTranslation.socialProfile.twitter}
                          onClick={() => {
                            window.open(
                              userProfileData.twitterProfile,
                              "_blank"
                            );
                          }}
                        />
                      )}
                    </SocialMediaButtonConatiner>
                  </div>
                </Flex>
                {props?.user?.role !== "Student" &&
                  settings?.generateAvatar && (
                    <Button
                      onClick={() => {
                        setIsOpenAvatarGenerator(true);
                      }}
                    >
                      Generate Avatar
                    </Button>
                  )}
              </div>

              {/* cropped User Profile modal */}
              <div
                className="modal fade crop-image-modal"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-target="#cameraIcon"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {feedbackTranslation.cropImage}
                      </h5>
                    </div>
                    <div className="modal-body">
                      <ReactCrop
                        style={{
                          maxHeight: "350px",
                          maxWidth: "350px",
                        }}
                        imageStyle={{
                          maxHeight: "350px",
                          maxWidth: "350px",
                          height: "100%",
                          width: "100%",
                        }}
                        src={fileData}
                        crop={cropConfig}
                        ruleOfThirds
                        onImageLoaded={(imageRef) => setImageRef(imageRef)}
                        onComplete={(cropConfig) => cropImage(cropConfig)}
                        onChange={(cropConfig) => setCropConfig(cropConfig)}
                        setCroppedImage={(croppedImage) =>
                          setCroppedImage(croppedImage)
                        }
                        crossorigin="anonymous"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        data-dismiss="modal"
                        onClick={() => setCroppedImage(props.user.UrlPhoto)}
                      >
                        {translation.common.close}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleUpdateProfileImage}
                      >
                        {translation.common.save}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Modal
                  title={feedbackTranslation.deleteImage}
                  style={{ top: 20 }}
                  open={openModal}
                  onOk={() => {
                    setOpenModal(false);
                    handleDeleteProfileImage();
                  }}
                  onCancel={() => setOpenModal(false)}
                  okButtonProps={{
                    style: {
                      borderRadius: "5px",
                      backgroundColor: "#d3313a",
                      borderColor: "#d3313a",
                    },
                  }}
                  cancelButtonProps={{
                    style: {
                      borderRadius: "5px",
                      color: "#273444",
                      backgroundColor: "#eaecf3",
                      borderColor: "#eaecf3",
                    },
                  }}
                >
                  <p>{feedbackTranslation.deleteImageConfirmation}</p>
                </Modal>
              </div>
              <div className="ml-3 profile-action-wrapper">
                <EditButton
                  disabled={isLoadingData}
                  icon={<EditIcon size="small" />}
                  content={feedbackTranslation.editProfile}
                  onClick={editButtonHandler}
                />
                {props.user?.role?.toLowerCase() === "student" && (
                  <StudentProgress
                    {...props}
                    triggerButton={(clickHandler) => (
                      <MyProgressButton
                        icon={<TranscriptIcon outline size="large" />}
                        content={feedbackTranslation.myProgress}
                        onClick={() => clickHandler(props.user?.mail)}
                      />
                    )}
                    headerText={feedbackTranslation.myProgress}
                  />
                )}
              </div>

              <div>
                <hr />
              </div>
              {isLoadingData ? (
                <div>
                  <Loader size="medium" style={{ paddingTop: "25px" }} />
                </div>
              ) : (
                <div className="pt-1 pb-4">
                  <Profile
                    {...{
                      data: userProfileData,
                      isEditProfile,
                      setIsEditProfile,
                      user: props.user,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default withTranslation()(Account);
