import React, { useEffect, useRef, useState } from "react";

import Header from "../../../components/Header/Header";

import {
  CloseIcon,
  Datepicker,
  Dropdown,
  Flex,
  Grid,
  Image,
  Loader,
  Segment,
  Table,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import CustomProgressIndicator from "../../../components/Loader/CustomProgressIndicator";
import { Subscription } from "../../../components/Subscription/Subscription";
import { classTypeOption } from "../../../constant/classTypeOption";
import { useChartContext } from "../../../context/ChartContext";
import Layout from "../Layout/Layout";
import { reportTypes } from "../Reports/constant";
import Tour from "../Tour";
import ColumnChart from "../components/ApexCharts/ColumnChart/ColumnChart";
import PieChart from "../components/ApexCharts/PieChart/PieChart";
import Controls from "../components/Controls/Controls";

import NoDataAvailableImage from "../../../Assets/images/svg/NoAssignmentFound.svg";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TextField,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";
import "./Charts.css";
import { Col, Row } from "antd";

const url = process.env.REACT_APP_EP_URL;

const Charts = (props) => {
  const {
    showClassType,
    report,
    headerFilterFields,
    isLoading,
    showControls,
    isEmptyReport,
    hideChartsContent = false,
    allClassNames,
    dropPlaceHolder,
    getFilters,
    setFilters,
    stateChangeHandler,
  } = props;

  const { t } = useTranslation();

  const [boxData, setBoxData] = useState(
    props.boxData ? Object.entries(props.boxData) : []
  );

  const [isExpire, setIsExpire] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [load, setLoad] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { isLoadingRBACData, selectedReportPath } = useChartContext();

  useEffect(() => {
    if (props.user?.dbData?.subscription) {
      if (props.user?.dbData?.subscription?.dashboard) {
        setIsSubscribed(true);
        setLoad(false);
        return;
      }
      setIsSubscribed(false);
      setLoad(false);
    } else {
      setIsSubscribed(false);
      setLoad(false);
    }

    if (props.user?.dbData?.dayRemaining <= 0) {
      setIsExpire(true);
    }

    let classes = document.querySelector(".ui-icon svg").classList.value;
    if (document.querySelector(".css-1gpjby2")) {
      let dropDowns = document.querySelectorAll(".css-1gpjby2");
      [].forEach.call(dropDowns, function (dropDown) {
        dropDown.innerHTML = `<svg role="presentation" focusable="false" viewBox="2 2 16 16" class="${classes}" style="width: 16px"><path class="ui-icon__outline br" d="M15.8527 7.64582C16.0484 7.84073 16.0489 8.15731 15.854 8.35292L10.389 13.8374C10.1741 14.0531 9.82477 14.0531 9.60982 13.8374L4.14484 8.35292C3.94993 8.15731 3.95049 7.84073 4.1461 7.64582C4.34171 7.4509 4.65829 7.45147 4.85321 7.64708L9.99942 12.8117L15.1456 7.64708C15.3406 7.45147 15.6571 7.4509 15.8527 7.64582Z"></path><path class="ui-icon__filled cs" d="M15.793 7.73271C16.0787 8.03263 16.0672 8.50737 15.7672 8.79306L10.5168 13.7944C10.2271 14.0703 9.77187 14.0703 9.4822 13.7944L4.23173 8.79306C3.93181 8.50737 3.92028 8.03263 4.20597 7.73271C4.49166 7.43279 4.96639 7.42125 5.26631 7.70694L9.99949 12.2155L14.7327 7.70694C15.0326 7.42125 15.5073 7.43279 15.793 7.73271Z"></path></svg>`;
      });
    }
  }, [props]);

  useEffect(() => {
    if (props.boxData) {
      setBoxData(Object.entries(props.boxData));
    }
  }, [props.boxData]);

  const bollItems = ["All", "Si", "No"];

  // let CFData = props.customFields ? Object.entries(props.customFields) : [];

  const multiSelectRef = useRef();

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const tourPath = "";

  return (
    <div className={`Charts s-ctr`} key={props.path}>
      <Header
        path={props.path}
        header={props.header}
        user={{ ...props.user }}
        logout={props.logout.bind()}
        accessToken={props.user.accessToken}
        getDb={() => props.getDb()}
        switchDb={props.switchDb}
        openDashboardMenu={handleClickOpen}
        closeDashboardMenu={handleClose}
        open={open}
        selectedExtension={props.selectedExtension}
        setSelectedExtension={props.setSelectedExtension}
        selectedReportPath={selectedReportPath}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            position: "sticky",
            zIndex: 2,
            top: 0,
            backgroundColor: "white",
            padding: "6px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: 600,
          }}
        >
          <Typography variant="h5" style={{ paddingLeft: "10px" }}>
            Filter a Value
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {showClassType && (
            <Box mb={2}>
              <Typography variant="subtitle1">
                {t("attendance").controls.classType[0]}
              </Typography>
              <Autocomplete
                size="small"
                options={classTypeOption}
                getOptionLabel={(option) => option}
                defaultValue="All"
                onChange={(event, value) => {
                  props?.setCheckPhysical(value === "All" ? null : value);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                sx={{ width: "100%" }}
                className="select-class-type"
              />
            </Box>
          )}
          {allClassNames && (
            <Box mb={2} className="tour-filter-by-class">
              <Typography variant="subtitle1">
                {dropPlaceHolder
                  ? dropPlaceHolder
                  : t("chart").screens.subject.controls.dropdown.class}
              </Typography>
              <Box display="flex" alignItems="center">
                <Autocomplete
                  size="small"
                  multiple
                  options={allClassNames.map((item) => ({
                    label: item.header ? item.header : item,
                    value: item.key ? item.key : item,
                  }))}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => {
                    const selectedClass = value.map(({ value }) => value);
                    stateChangeHandler(selectedClass);
                  }}
                  className="select-class-dropdown"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        dropPlaceHolder ||
                        t("chart").screens.subject.controls.dropdown.teacher
                      }
                    />
                  )}
                  ref={multiSelectRef}
                  sx={{ flex: 1 }}
                />
              </Box>
            </Box>
          )}
          {/* {CFData?.map((cf, key) => (
            <Box mb={2} key={key} className="">
              <Typography variant="subtitle1" title={cf[0]}>
                {cf[1]?.name?.Name || "No Data Given"}
              </Typography>
              <Box className={`${cf[1]?.name?.Name}Selector seg`}>
                {cf[1].name.Type === "Text" || cf[1].name.Type === "Number" ? (
                  <Autocomplete
                    size="small"
                    multiple
                    options={cf[1].res.map((cfd) => ({
                      label: cfd[cf[0]],
                      value: cfd[cf[0]],
                    }))}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => {
                      const selectedFilters = value.map(({ value }) => value);
                      const filters = getFilters;
                      filters[cf[0]] = selectedFilters || [];
                      setFilters(filters);
                    }}
                    className="filter-multi-select"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("chart").cf_placeholder}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    options={
                      cf[1].name.Type === "Boolean"
                        ? ["All", ...cf[1].res.map((cfd) => cfd[cf[0]])]
                        : cf[1].res.map((cfd) => cfd[cf[0]])
                    }
                    multiple={cf[1].name.Type === "Text"}
                    onChange={(event, value) => {
                      let filters = getFilters;
                      if (cf[1].name.Type === "Boolean") {
                        filters[cf[0]] = value === "All" ? null : value;
                      } else {
                        filters[cf[0]] = filters[cf[0]]
                          ? [...filters[cf[0]], value]
                          : [value];
                      }
                      setFilters(filters);
                    }}
                    className="filter-multi-select"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("chart").cf_placeholder}
                      />
                    )}
                  />
                )}
              </Box>
            </Box>
          ))} */}
        </DialogContent>
        <DialogActions
          sx={{
            position: "sticky",
            zIndex: 1,
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>

      {isSubscribed && !isExpire && showControls && (
        <>
          {isMobileView ? (
            <Controls path={props.path}>
              <Grid
                item
                style={{ alignItems: "center", display: "flex", gap: "8px" }}
              >
                <Segment
                  content={
                    props.startDateLabel
                      ? props.startDateLabel
                      : t("chart").screens.subject.controls.date.date2[0]
                  }
                  className="mobile-seg1"
                  style={{
                    padding: " 0 8px",
                    fontSize: "14px",
                    fontWeight: "600",
                    boxShadow: "none",
                  }}
                />
                <Segment
                  content={
                    <Datepicker
                      inputPlaceholder={
                        t("chart").screens.subject.controls.date.date1[0]
                      }
                      defaultSelectedDate={props.startDate}
                      onDateChange={(e, v) => {
                        props.startChangeHandler(v.value);
                      }}
                      className="date-hidden"
                    />
                  }
                  className="seg2"
                />
                <Segment
                  content={
                    props.endDateLabel
                      ? props.endDateLabel
                      : t("chart").screens.subject.controls.date.date3[0]
                  }
                  className="mobile-seg1"
                  style={{
                    padding: " 0 8px",
                    fontSize: "14px",
                    fontWeight: "600",
                    boxShadow: "none",
                  }}
                />
                <Segment
                  content={
                    <Datepicker
                      inputPlaceholder={
                        t("chart").screens.subject.controls.date.date1[0]
                      }
                      defaultSelectedDate={props.endDate}
                      onDateChange={(e, v) => {
                        props.endChangeHandler(v.value);
                      }}
                      className="date-hidden"
                    />
                  }
                  className="seg2"
                />
                <Tour
                  path={`${props.path}/${props.mainPath}`}
                  isMobileView={isMobileView}
                />
              </Grid>
              {headerFilterFields ? headerFilterFields : null}
            </Controls>
          ) : (
            <Controls path={props.path}>
              <div className="leftControls tour-start-date-filter">
                <Segment
                  content={
                    props.startDateLabel
                      ? props.startDateLabel
                      : t("chart").screens.subject.controls.date.date2[0]
                  }
                  className="seg1"
                />
                <Segment
                  content={
                    <Datepicker
                      inputPlaceholder={
                        t("chart").screens.subject.controls.date.date1[0]
                      }
                      defaultSelectedDate={props.startDate}
                      style={{ margin: 0, marginLeft: "31px" }}
                      onDateChange={(e, v) => {
                        props.startChangeHandler(v.value);
                      }}
                    />
                  }
                  className="seg2"
                />
              </div>
              <div className="leftControls tour-end-date-filter">
                <Segment
                  content={
                    props.endDateLabel
                      ? props.endDateLabel
                      : t("chart").screens.subject.controls.date.date3[0]
                  }
                  className="seg1"
                />
                <Segment
                  content={
                    <Datepicker
                      inputPlaceholder={
                        t("chart").screens.subject.controls.date.date1[0]
                      }
                      defaultSelectedDate={props.endDate}
                      style={{ margin: 0, marginLeft: "31px" }}
                      onDateChange={(e, v) => {
                        props.endChangeHandler(
                          new Date(
                            moment(v.value)
                              .add({
                                hours: 23,
                                minutes: 59,
                              })
                              .toISOString()
                          )
                        );
                      }}
                    />
                  }
                  className="seg2"
                />
              </div>
              {showClassType && (
                <div className="leftControls tour-select-class-type">
                  <Segment
                    content={t("attendance").controls.classType[0]}
                    className="seg1"
                  />

                  <Segment
                    content={
                      <Dropdown
                        items={classTypeOption}
                        checkable
                        defaultValue="All"
                        onChange={(e, { value }) => {
                          props?.setCheckPhysical(
                            value === "All" ? null : value
                          );
                        }}
                        getA11ySelectionMessage={{
                          onAdd: (item) => `${item} has been selected.`,
                        }}
                      />
                    }
                    className="seg2 class-type classSelector"
                  />
                </div>
              )}
              {props.allClassNames ? (
                <div className="leftControls tour-filter-by-class">
                  {/* {props.showLbl?.[0] ? ( */}
                  <Segment
                    content={
                      props.dropPlaceHolder
                        ? props.dropPlaceHolder
                        : t("chart").screens.subject.controls.dropdown.class
                    }
                    className="seg1"
                  />
                  <Segment
                    content={
                      <div className="multi-select">
                        <ReactMultiSelectCheckboxes
                          getDropdownButtonLabel={({
                            placeholderButtonLabel,
                            value,
                          }) => {
                            if (!value) {
                              return placeholderButtonLabel;
                            }

                            if (Array.isArray(value)) {
                              if (value.length === 0) {
                                document
                                  .querySelector(".classSelector")
                                  .classList.remove("filterSelected");
                                return placeholderButtonLabel;
                              }

                              const assignmentReportsTypes = [
                                reportTypes.assignmentStudent,
                                reportTypes.assignmentSubject,
                                reportTypes.assignmentTeacher,
                              ];

                              if (
                                value.length !== 0 &&
                                !assignmentReportsTypes.includes(report)
                              ) {
                                document
                                  .querySelector(".classSelector")
                                  .classList.add("filterSelected");
                              }

                              if (value.length === 1) {
                                return value[0].label;
                              }

                              return "".concat(
                                value.length,
                                props.dropPlaceHolder
                                  ? " maestros seleccionados"
                                  : " clase seleccionada"
                              );
                            }
                          }}
                          options={props.allClassNames
                            ?.map((item) => {
                              return {
                                label: item.header ? item.header : item,
                                value: item.key ? item.key : item,
                              };
                            })
                            .sort()}
                          onChange={(data) => {
                            let selectedClass = data.map(({ value }) => value);
                            props.stateChangeHandler(selectedClass);
                          }}
                          placeholderButtonLabel={
                            props.dropPlaceHolder
                              ? props.dropPlaceHolder
                              : t("chart").screens.subject.controls.dropdown
                                  .teacher
                          }
                          ref={multiSelectRef}
                        />
                        <span
                          className="close_icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            const dropBox = document.getElementsByClassName(
                              "css-1pcexqc-container"
                            );

                            if (dropBox[0]) {
                              dropBox[0].style.display = "none";
                            }
                            multiSelectRef.current.state.value = [];
                            props.stateChangeHandler([]);
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    }
                    className="seg2 classSelector"
                  />
                </div>
              ) : null}
              {/* {CFData?.map((cf, key) => {
                return (
                  <div className="leftControls" key={key}>
                    <Segment
                      content={cf[1]?.name?.Name || "No Data Given"}
                      className="seg1"
                      title={cf[0]}
                    />
                    <Segment
                      content={
                        cf[1].name.Type === "Text" ||
                        cf[1].name.Type === "Number" ? (
                          <ReactMultiSelectCheckboxes
                            getDropdownButtonLabel={({
                              placeholderButtonLabel,
                              value,
                            }) => {
                              if (!value) {
                                return placeholderButtonLabel;
                              }

                              if (Array.isArray(value)) {
                                if (value.length === 0) {
                                  document
                                    .querySelector(
                                      `.${cf[1].name.Name}Selector`
                                    )
                                    .classList.remove("filterSelected");
                                  return placeholderButtonLabel;
                                }
                                if (value.length !== 0) {
                                  document
                                    .querySelector(
                                      `.${cf[1].name.Name}Selector`
                                    )
                                    .classList.add("filterSelected");
                                }

                                if (value.length === 1) {
                                  return value[0].label;
                                }

                                return "".concat(value.length, ` seleccionada`);
                              }
                            }}
                            options={
                              cf[1].res.length > 0
                                ? cf[1].res
                                    .map((cfd) => {
                                      return {
                                        label: cfd[cf[0]],
                                        value: cfd[cf[0]],
                                      };
                                    })
                                    .sort()
                                : []
                            }
                            onChange={(data) => {
                              let selectedFilters = data.map(
                                ({ value }) => value
                              );
                              // props.setFilters(filters);
                              let filerts = props.getFilters;
                              filerts[cf[0]] = selectedFilters || [];
                              props.setFilters(filerts);
                            }}
                            placeholderButtonLabel={t("chart").cf_placeholder}
                          />
                        ) : (
                          <Dropdown
                            multiple={cf[1].name.Type === "Text"}
                            items={
                              cf?.[1]?.res?.length
                                ? cf[1].name.Type === "Boolean"
                                  ? [
                                      "All",
                                      ...cf[1].res.map((cfd) => cfd[cf[0]]),
                                    ]
                                  : cf[1].res.map((cfd) => cfd[cf[0]])
                                : []
                            }
                            placeholder={t("chart").cf_placeholder}
                            getA11ySelectionMessage={{
                              onAdd: (item) => {
                                let filerts = props.getFilters;
                                if (cf[1].name.Type === "Boolean") {
                                  filerts[cf[0]] = item === "All" ? null : item;
                                } else {
                                  filerts[cf[0]] = filerts[cf[0]]
                                    ? [...filerts[cf[0]], item]
                                    : [item];
                                }
                                props.setFilters(filerts);
                              },
                              onRemove: (item) => {
                                let filerts = props.getFilters;
                                let updateFilter = filerts[cf[0]];
                                const index = updateFilter.indexOf(item);
                                if (index > -1) {
                                  updateFilter.splice(index, 1);
                                }
                                updateFilter = updateFilter.length
                                  ? updateFilter
                                  : null;
                                filerts[cf[0]] = updateFilter;
                                props.setFilters(filerts);
                              },
                            }}
                            disabled={!cf[1]?.res || cf[1]?.res?.length === 0}
                            className={
                              cf[1].name.Type === "Text"
                                ? "ColonySelector"
                                : "SmallSelector"
                            }
                            title="Scholarship"
                          />
                        )
                      }
                      className={`${cf[1]?.name?.Name}Selector seg2`}
                    />
                  </div>
                );
              })} */}
              {headerFilterFields ? headerFilterFields : null}
              <Tour path={`${props.path}/${props.mainPath}`} />
              {/* </Flex> */}
              {/* ---------------------CYNAMIC CF END----------------- */}
            </Controls>
          )}
          <div className="chart-loading-wrapper">
            <CustomProgressIndicator
              isLoading={isLoading}
              style={{
                progressTrack: {
                  backgroundColor: "#ffffff",
                },
                root: {
                  height: "2px",
                },
              }}
            />
          </div>
        </>
      )}

      {hideChartsContent ? (
        <div />
      ) : isLoadingRBACData ? (
        <Loader style={{ paddingTop: "30vh" }} />
      ) : isSubscribed && !isExpire ? (
        <Layout isReportAvailable={!isEmptyReport} isMobileView={isMobileView}>
          {boxData && boxData.length ? (
            <div className="Analytics">
              <Row gutter={[5, 5]} style={{ width: "100%" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Segment
                    content={
                      <Flex gap="gap.small" className="Boxes tour-boxes" wrap>
                        {boxData ? (
                          boxData.map((data, key) => {
                            return (
                              <Segment
                                key={key}
                                content={
                                  <>
                                    <Segment
                                      content={data[0]}
                                      className="ui-seg1"
                                    />
                                    <Segment
                                      content={
                                        <h1 className="seg-header Fcabin">
                                          {data[1]}
                                        </h1>
                                      }
                                    />
                                  </>
                                }
                                className="seg-Box"
                                title={data[0]}
                              />
                            );
                          })
                        ) : (
                          <Loader
                            size="medium"
                            style={{ paddingTop: "50px", paddingLeft: "300px" }}
                          />
                        )}
                      </Flex>
                    }
                    styles={{
                      gridColumn: "span 7",
                    }}
                    className="BoxChart"
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                  <Segment
                    content={
                      <Flex gap="gap.small" className="pieFlex">
                        <Segment
                          className={`chart-seg-1 ${
                            props.pieData?.pie1.data
                              ? "tour-first-pie-chart"
                              : ""
                          }`}
                          content={
                            <>
                              <h3 className="Fcabin">
                                <b>{props.pieData?.pie1.lable}</b>
                              </h3>
                              {props?.pieData?.pie1.data ? (
                                <PieChart data={props?.pieData?.pie1?.data} />
                              ) : (
                                <>Loading...</>
                              )}
                            </>
                          }
                          styles={{
                            gridColumn: "span 1",
                            justifySelf: "center",
                          }}
                        />
                        <Segment
                          className={`chart-seg-2 ${
                            props.pieData?.pie2.data
                              ? "tour-second-pie-chart"
                              : ""
                          }`}
                          content={
                            <>
                              <h3 className="Fcabin">
                                <b>{props.pieData?.pie2.lable}</b>
                              </h3>
                              {props.pieData?.pie2.data ? (
                                <PieChart data={props.pieData.pie2.data} />
                              ) : (
                                <>Loading...</>
                              )}
                            </>
                          }
                          styles={{
                            gridColumn: "span 1",
                            justifySelf: "center",
                          }}
                        />
                      </Flex>
                    }
                    styles={{
                      gridColumn: "span 6",
                    }}
                    className="PieChart"
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Flex wrap>
                    <Segment
                      content={
                        <Flex gap="gap.small" column>
                          <Segment
                            content={
                              <h3 className="Fcabin">
                                <b>{props.columnData.title}</b>
                              </h3>
                            }
                          />
                          <Segment
                            content={
                              <ColumnChart
                                series={props.columnData.series}
                                stacked={props.columnData.stacked}
                                categories={props.columnData.categories}
                                lableX={props.columnData.lableX}
                                lableY={props.columnData.lableY}
                                radius={props.columnData.radius}
                              />
                            }
                            styles={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Flex>
                      }
                      styles={{
                        gridColumn: "span 6",
                      }}
                      className="ColumnChart tour-bar-chart"
                    />
                    <Segment
                      content={
                        <Grid
                          columns="repeat(1,1fr)"
                          className="grid tour-table-data"
                        >
                          <Table
                            header={props.tableData.header}
                            aria-label="Static table with no rows"
                            className="gridHeader"
                          />
                          <Table
                            rows={props.tableData.rows}
                            aria-label="Static headless table"
                            className="gridRows"
                          />
                        </Grid>
                      }
                      styles={{
                        gridColumn: "span 6",
                      }}
                      className="Table"
                    />
                  </Flex>
                </Col>
              </Row>
              {/* <Grid columns="repeat(13, 1fr)" rows="auto">
              </Grid> */}
            </div>
          ) : (
            !isLoading && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: 82,
                  width: "100%",
                }}
              >
                <Flex column hAlign="center">
                  <Image
                    style={{ maxWidth: "280px", width: "100%" }}
                    src={NoDataAvailableImage}
                  />
                  <h2 style={{ marginTop: 20 }}>
                    {t("chart").noDataAvailable}
                  </h2>
                </Flex>
              </div>
            )
          )}
        </Layout>
      ) : (!isSubscribed && !load) || isExpire ? (
        <Subscription
          pageName="dashboard"
          isExpire={isExpire}
          role={props.user.role}
        />
      ) : (
        load && <Loader size="large" style={{ paddingTop: "30vh" }} />
      )}
    </div>
  );
};

export default Charts;
