import React, { useCallback } from "react";
import { Row, Col } from "antd";
import { ResponsiveImage } from "../../../components/blocks/Images";
import {
  Datepicker,
  Input,
  SearchIcon,
  Segment,
} from "@fluentui/react-northstar";

import "./FeedbackControls.scss";

const FeedbackControls = (props) => {
  const {
    startDate,
    endDate,
    changeStartDate,
    changeEndDate,
    onHandlePortal = () => {},
    searchValueChangeHandler,
    isMobileView,
  } = props;

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimizedFn = useCallback(debounce(searchValueChangeHandler), []);

  return (
    <div className="Controls" style={{boxShadow: "0 15px 18px 0px rgb(200 200 200 / 30%)"}}>
      <Segment
        style={{
          width: "100%",
          background: "#FFF",
          filter: "drop-shadow(rgba(0, 0, 0, 0.03) 0px 0px)",
          // boxShadow: "0 15px 18px 0px rgb(200 200 200 / 30%)",
          justifyContent: isMobileView && "start",
          display: isMobileView && "flex",
          // marginBottom: isMobileView && "10px",
        }}
        content={
          <div>
            <Row justify="end" gutter={[12, 12]}>
              {/* <Col>
              <div
                style={{
                  display: "flex",
                  marginLeft: 20,
                  marginRight: 20,
                  cursor: "pointer",
                  height: "100%",
                }}
                onClick={() => onHandlePortal(true)}
              >
                <ResponsiveImage
                  style={{ marginRight: 10, width: 15 }}
                  src={"/Images/Events/filter.svg"}
                />
                <p style={{ margin: 0, padding: 5 }}>Filter</p>
              </div>
            </Col> */}
              <Col>
                <div style={{ display: "flex", gap: isMobileView && "55px" }}>
                  <Input
                    icon={<SearchIcon />}
                    onChange={(e) => optimizedFn(e.target.value)}
                    clearable
                    placeholder="Global Search"
                  />
                  <div id="feedback-view-button"></div>
                </div>
              </Col>
            </Row>
          </div>
        }
        className="seg2 feedback-control-segment"
      />
    </div>
  );
};

export default FeedbackControls;
