import React, { Component } from "react";

import Chart from "../Container/Charts";

import { Button } from "@fluentui/react-northstar";

import axios from "axios";

import { withTranslation } from "react-i18next";
import { reportTypes } from "./constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();
class StudentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this?.props?.user?.slug,
      selectedClass: [],
      selectedColony: [],
      selectedInternetSpeed: [],
      LessonDate: [
        new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), 0, 0, 0, 0),
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
      ],
      allClassNames: null,
      colonyNames: [],
      allInternetSpeed: [],
      isEmployee: null,
      isScholarship: null,
      isPassing: null,
      filterBy: "Presente",
      filerts: {
        CF_1: null,
        CF_2: null,
        CF_3: null,
        CF_4: null,
        CF_5: null,
      },
      checkPhysical: null,
      loading: {
        attendance: false,
        chart: false,
      },
    };
  }

  stateChangeHandler(slug) {
    this.setState({
      loading: { ...this.state.loading, attendance: true },
    });
    axios
      .post(
        `${url}/api/chart/getAttendenceByStudent${slug}`,
        {
          ClassName: this.state.selectedClass.length
            ? this.state.selectedClass
            : null,
          LessonDate: this.state.LessonDate,
          // colonia: this.state.selectedColony.length
          //   ? this.state.selectedColony
          //   : null,
          // internetSpeed: this.state.selectedInternetSpeed.length
          //   ? this.state.selectedInternetSpeed
          //   : null,
          // isEmployee: this.state.isEmployee,
          // isScholarship: this.state.isScholarship,
          // isPassing: this.state.isPassing,
          ...this.state.filerts,
          ClassType: this.state.checkPhysical,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        const response = res.data.result;
        const StartTimeData = res.data.StartTimeData;
        const coloniaData = res.data.coloniaData;

        this.setState({ coloniaData });

        // console.log("getAttendenceByStudent => ",res);
        this.setState({
          studData: response.sort(
            (a, b) =>
              parseInt(b[this.state.filterBy]) -
              parseInt(a[this.state.filterBy])
          ),
          customFields: res.data.sendData,
          StartTimeData,
        });

        if (this.state.colonyNames === null) {
          let colonyNames = res.data.coloniaData
            ? res.data.coloniaData.map(
                (colony) => colony.StudentData.CF_1 || "No Data Given"
              )
            : [];
          this.setState({ colonyNames: colonyNames || [] });
        }

        if (this.state.allInternetSpeed === null) {
          let allInternetSpeed = res.data.internetData
            ? res.data.internetData.map((data) => data.internetSpeed)
            : [];
          this.setState({ allInternetSpeed });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          loading: { ...this.state.loading, attendance: false },
        });
      });
  }

  componentDidMount() {
    this.setState({
      loading: { ...this.state.loading, chart: true },
    });
    axios
      .post(
        `${url}/api/chart/get-chart-by-classname${this.props.user.slug}`,
        {
          ClassName: null,
          LessonDate: this.state.LessonDate,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        let classNames = response
          ? response.map((lsn) => {
              return lsn.ClassName;
            })
          : [];

        if (this.state.allClassNames === null) {
          this.setState({ allClassNames: classNames });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          loading: { ...this.state.loading, chart: false },
        });
      });
    this.stateChangeHandler(this.state.slug);
  }

  componentWillReceiveProps(nextProps) {
    this.stateChangeHandler(nextProps?.user?.slug);
  }

  filterByHandler(option) {
    // console.log(option);
    this.setState({ filterBy: option }, () => {
      this.setState({
        studData: this.state.studData.sort(
          (a, b) =>
            parseInt(b[this.state.filterBy]) - parseInt(a[this.state.filterBy])
        ),
      });
    });
  }

  render() {
    const { t } = this.props;
    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += parseInt(this[i][prop]);
      }
      return total;
    };

    let virtualCount = 0;

    if (this.state.studData?.length) {
      for (let i = 0; i < this.state.studData.length; i++) {
        const stud = this.state.studData[i];
        if (stud.StudentData.isVirtual) {
          virtualCount++;
        }
      }
    }

    let pie1data = {};

    if (this.state.studData) {
      // Step 1: Sort the array by the specified property in descending order
      const sortedStudentData = this.state.studData.sort(
        (a, b) => b.Total - a.Total
      );

      // Step 2: Take the first 10 records from the sorted array
      const top9StudentData = sortedStudentData.slice(0, 9);

      // Step 3: Get the remaining records starting from index 9
      const remainingStudentData = sortedStudentData.slice(9);
      // Step 4: Add the top 9 records to the pie1data object
      top9StudentData.forEach((student) => {
        let key =
          (student.StudentName.split(" ")[0] || "") +
          (student.StudentName.split(" ")[1] || "");
        pie1data[key] = Number(student.Presente);
      });
      pie1data["Others"] = Number(remainingStudentData.sum("Presente"));
    }

    let pie2data = {};

    if (this.state.StartTimeData) {
      // Step 1: Sort the array by "Total" in descending order
      const sortedData = this.state.StartTimeData.sort(
        (a, b) => b.Total - a.Total
      );

      // Step 2: Take the first 10 records from the sorted array
      const top10Data = sortedData.slice(0, 10);

      // Step 3: Add these records to the pie2data object
      top10Data.forEach((time) => {
        if (time.StartTime) {
          pie2data[time.StartTime.trim()] = time.Total;
        }
      });
    }

    return (
      // this.state.studData?(
      <Chart
        mainPath={this.props.mainPath}
        showControls={true}
        isLoading={this.state.loading.attendance || this.state.loading.chart}
        report={reportTypes.attendanceStudent}
        user={this.props.user}
        logout={() => this.props.logout()}
        switchDb={this.props.switchDb}
        getDb={() => this.props.getDb()}
        path={this.props.match.path}
        header={{
          report: t("chart").screens.header.reports[1],
          lable: t("chart").screens.header.lable,
        }}
        startDate={this.state.LessonDate[0]}
        endDate={this.state.LessonDate[1]}
        startChangeHandler={(selectedDate) => {
          // console.log(selectedDate)
          let LessonDate = [...this.state.LessonDate];
          LessonDate[0] = selectedDate;
          this.setState({ LessonDate }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        endChangeHandler={(selectedDate) => {
          let LessonDate = [...this.state.LessonDate];
          LessonDate[1] = selectedDate;
          this.setState({ LessonDate }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        allClassNames={this.state.allClassNames}
        showClassType={true}
        checkPhysical={this.state.checkPhysical}
        setCheckPhysical={(classType) => {
          this.setState({ checkPhysical: classType }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        stateChangeHandler={(selectedClass) => {
          this.setState({ selectedClass }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        classAdd={(item) => {
          let selectedClass = [...this.state.selectedClass];
          selectedClass.push(item);
          this.setState({ selectedClass }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        classRemove={(item) => {
          let selectedClass = [...this.state.selectedClass];
          selectedClass.splice(selectedClass.indexOf(item), 1);
          this.setState({ selectedClass }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        customFields={this.state.customFields}
        getFilters={this.state.filerts}
        setFilters={(filerts) => {
          this.setState({ filerts }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        colonyNames={this.state.colonyNames}
        colonyAdd={(item) => {
          let selectedColony = [...this.state.selectedColony];
          selectedColony.push(item);
          this.setState({ selectedColony }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        colonyRemove={(item) => {
          let selectedColony = [...this.state.selectedColony];
          selectedColony.splice(selectedColony.indexOf(item), 1);
          this.setState({ selectedColony }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        allInternetSpeed={this.state.allInternetSpeed}
        internetSpeedAdd={(item) => {
          let selectedInternetSpeed = [...this.state.selectedInternetSpeed];
          selectedInternetSpeed.push(item);
          this.setState({ selectedInternetSpeed }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        internetSpeedRemove={(item) => {
          let selectedInternetSpeed = [...this.state.selectedInternetSpeed];
          selectedInternetSpeed.splice(selectedInternetSpeed.indexOf(item), 1);
          this.setState({ selectedInternetSpeed }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        showLbl={[
          this.state.selectedClass.length ? false : true,
          this.state.selectedColony.length ? false : true,
          this.state.selectedInternetSpeed.length ? false : true,
        ]}
        setIsEmployee={(item) => {
          this.setState({ isEmployee: item === "All" ? null : item }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        setIsScholarship={(item) => {
          this.setState({ isScholarship: item === "All" ? null : item }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        setIsPassing={(item) => {
          this.setState({ isPassing: item === "All" ? null : item }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        boxData={
          this.state.studData
            ? {
                [t("chart").screens.student.boxs[0]]:
                  this.state.studData.length,
                [t("chart").screens.student.boxs[1]]:
                  this.state.studData.sum("Presente"),
                [t("chart").screens.student.boxs[2]]:
                  this.state.studData.sum("Ausente"),
                [t("chart").screens.student.boxs[3]]:
                  this.state.studData.sum("Retardo"),
                [t("chart").screens.student.boxs[4]]:
                  this.state.studData.sum("Justificado"),
                [t("chart").screens.student.boxs[5]]: virtualCount,
              }
            : null
        }
        pieData={{
          pie1: {
            lable: t("chart").screens.student.pie.pie1.lbl,
            data: pie1data,
          },
          pie2: {
            lable: t("chart").screens.student.pie.pie2.lbl,
            data: pie2data,
          },
        }}
        columnData={{
          radius: 10,
          title: t("chart").screens.student.column.title,
          lableY: t("chart").screens.student.column.lableY,
          lableX: t("chart").screens.student.column.lableX,
          series: this.state.studData
            ? [
                {
                  name: [t("chart").screens.subject.column.series[0]],
                  data: [...this.state.studData].slice(0, 10).map((stud) => {
                    return stud.Presente;
                  }),
                },
                // , {
                //     name: 'Absent',
                //     data:this.state.studData.slice(0,10).map(stud=>{
                //         return(stud.Ausente)
                //     })
                // }, {
                //     name: 'Late',
                //     data:this.state.studData.slice(0,10).map(stud=>{
                //         return(stud.Retardo)
                //     })
                // }, {
                //     name: 'Justification',
                //     data:this.state.studData.slice(0,10).map(stud=>{
                //         return(stud.Justificado)
                //     })
                // }
              ]
            : [],
          categories: this.state.studData
            ? [...this.state.studData].slice(0, 10).map((stud) => {
                // console.log(stud);
                return (
                  (stud.StudentName.split(" ")[0] || "") +
                  (stud.StudentName.split(" ")[1] || "")
                );
              })
            : [],
        }}
        tableData={{
          header: {
            key: "Headers",
            items: t("chart").screens.student.table.header.map(
              (header, key) => {
                return {
                  content:
                    key && key === 1 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Presente"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Presente")}
                      />
                    ) : key === 2 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Ausente"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Ausente")}
                      />
                    ) : key === 3 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Retardo"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Retardo")}
                      />
                    ) : key === 4 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Justificado"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Justificado")}
                      />
                    ) : key === 5 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Total"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Total")}
                      />
                    ) : (
                      header
                    ),
                  key: "Header-" + header,
                };
              }
            ),
          },
          rows: this.state.studData
            ? this.state.studData.map((stud, key) => {
                return {
                  key: stud.StudentEmailId + "key" + key,
                  items: [
                    {
                      content: stud.StudentName,
                      key: stud.StudentName + "StudentName" + key,
                    },
                    {
                      content: stud.Presente,
                      key: stud.Presente + "Presente" + key,
                    },
                    {
                      content: stud.Ausente,
                      key: stud.Ausente + "Ausente" + key,
                    },
                    {
                      content: stud.Retardo,
                      key: stud.Retardo + "Retardo" + key,
                    },
                    {
                      content: stud.Justificado,
                      key: stud.Justificado + "Justificado" + key,
                    },
                    { content: stud.Total, key: stud.Total + "Total" + key },
                  ],
                };
              })
            : [],
        }}
        selectedExtension={this.props.selectedExtension}
        setSelectedExtension={this.props.setSelectedExtension}
      />
      // )
      // :<Loader size="largest" style={{paddingTop:"45vh"}}/>
    );
  }
}

export default withTranslation()(StudentReport);
