import React, { useEffect, useState } from "react";

import moment from "moment";
import axios from "axios";
import { useHistory } from "react-router-dom";

import {
  ArrowLeftIcon,
  Avatar,
  Button,
  CardBody,
  CardHeader,
  CloseIcon,
  Dialog,
  EditIcon,
  Flex,
  FlexItem,
  Loader,
  Text,
  TextArea,
} from "@fluentui/react-northstar";

import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Card } from "antd";
import { withTranslation } from "react-i18next";

import AttachmentOption from "../AttachmentOption/AttachmentOption";
import { useAssignment } from "../../../../context/AssignmentContext";
import { gradingStatusDropDown as gradingStatusDropDown } from "../../constant";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import CustomProgressIndicator from "../../../../components/Loader/CustomProgressIndicator";

import "../AssignmentHome/AssignmentHome.css";
import "react-toastify/dist/ReactToastify.css";

const url = process.env.REACT_APP_EP_URL;

const GradingPage = (props) => {
  const history = useHistory();

  const { selectedSubmission, user, t } = props;

  const {
    currentAssignmentData,
    setIsSubmissionsPage,
    setIsGradingPage,
    setCurrentPage,
    setIsRubricHasPoints,
  } = useAssignment();

  const translation = t("assignment").body;

  const gradingTranslation = translation.gradingPage;

  const [isEditGrades, setIsEditGrades] = useState(true);
  const [studentGradings, setStudentGradings] = useState([]);
  const [isUpdateGrade, setIsUpdateGrade] = useState(false);
  const [selectedGrade, setselectedGrade] = useState(null);
  const [isReasonForResubmitDialogOpen, setIsReasonForResubmitDialogOpen] =
    useState(false);

  const [reasonForResubmitText, setReasonForResubmitText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStudentSubmission, setSelectedStudentSubmission] = useState({
    attachments: [],
  });

  useEffect(() => {
    const getStudentsSubmission = async () => {
      setIsLoading(true);
      try {
        let response = await axios({
          method: "get",
          url: `${url}/api/assignment/get-assignment-submission${
            user.slug
          }&id=${selectedSubmission.id}&aId=${
            currentAssignmentData.id
          }&studentId=${
            selectedSubmission.StudentEmailId || selectedSubmission.studentId
          }`,
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        });
        if (response.status === 200) {
          let responseData = response.data?.results;
          if (responseData) {
            let formattedData = { ...responseData };
            if (responseData.attachmentData?.length) {
              let responseAttachments = responseData.attachmentData?.filter(
                (item) => item.aId === currentAssignmentData.id
              );
              let attachments = [
                {
                  type: "text",
                  info: responseAttachments?.find((item) => item.type == "text")
                    ?.url,
                },
                {
                  type: "voice",
                  info:
                    responseAttachments?.find(
                      (item) => item.type == "voice" || item.type == "audio"
                    )?.url || "",
                },
                {
                  type: "video",
                  info:
                    responseAttachments?.find((item) => item.type == "video")
                      ?.url || "",
                },
                {
                  type: "link",
                  info: responseAttachments?.find((item) => item.type == "link")
                    ?.url,
                },
                {
                  type: "file",
                  info: responseAttachments?.find((item) => item.type == "file")
                    ?.url,
                },
              ];
              formattedData.attachments = await formatAttachmentData(
                attachments
              );
            }

            setSelectedStudentSubmission(formattedData);
            let gradingData = responseData?.data?.Grades;
            if (gradingData?.length) {
              setStudentGradings(
                gradingData.map((item) => ({
                  criteriaId: item.criteriaId,
                  grades: item.givenGrade,
                  rubricItemId: item.rubricItemId,
                  title: item.rubricItemTitle,
                  id: item.id,
                }))
              );
            }
          }
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };
    setCurrentPage("grading");

    if (
      currentAssignmentData &&
      currentAssignmentData.rubric?.rubricItems?.length
    ) {
      let isHavePoints = currentAssignmentData.rubric.rubricItems.some(
        (item) => item.havePoints
      );
      let points = currentAssignmentData.rubric.rubricItems.some((item) => {
        if (item.levels?.length) {
          return item.levels.some((item) => item.points > 0);
        } else {
          return false;
        }
      });
      setIsRubricHasPoints(isHavePoints && points);
    } else {
      setIsRubricHasPoints(false);
    }

    if (selectedSubmission?.id) getStudentsSubmission();
    return () => {
      setSelectedStudentSubmission({});
      setStudentGradings([]);
    };
  }, []);

  const formatAttachmentData = async (attachments) => {
    const getTextContentFromUrl = (url) => {
      if (url) {
        try {
          return fetch(url)
            .then((response) => response.text())
            .then((data) => data);
        } catch (error) {
          return "";
        }
      } else {
        return "";
      }
    };

    const getLinkContentFromUrl = (url) => {
      if (url) {
        try {
          return fetch(url)
            .then((response) => response.text())
            .then((data) => data.split(","));
        } catch (error) {
          return "";
        }
      } else {
        return "";
      }
    };
    try {
      const textInfo = await getTextContentFromUrl(
        attachments?.find((item) => item.type == "text")?.info
      );
      const linkInfo = await getLinkContentFromUrl(
        attachments?.find((item) => item.type == "link")?.info
      );
      return [
        {
          type: "text",
          info: textInfo,
        },
        {
          type: "voice",
          info: attachments?.find((item) => item.type == "voice")?.info || "",
        },
        {
          type: "video",
          info: attachments?.find((item) => item.type == "video")?.info || "",
        },
        {
          type: "link",
          info: linkInfo,
        },
        {
          type: "file",
          info:
            attachments
              ?.find((item) => item.type == "file")
              ?.info?.split(",") || [],
        },
      ];
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const updateStudentGradings = async ({ status, resubmitReason }) => {
    const finalPayload = {
      id: selectedSubmission.id,
      assingmentId: selectedSubmission.assingmentId,
      submissionStatus:
        status === "Completed" || status === "updateGradings"
          ? "Completed"
          : status === "Resubmit"
          ? "Resubmit"
          : status,
      gradingStatus: status,
      reason: status === "Resubmit" ? resubmitReason : null,
      gradedOn:
        status === "Completed" ? moment().format("YYYY-MM-DDTHH:mm:ssZ") : null,
      gradingsData:
        status !== "Resubmit"
          ? studentGradings.length
            ? {
                rubricId: currentAssignmentData.rubric.rubricId,
                totalPoint: currentAssignmentData.markingType,
                givenPoint: studentGradings.reduce(
                  (prevTotal, currentItem) =>
                    (Number(currentItem.grades) || 0) + prevTotal,
                  0
                ),
                gradings: studentGradings.map((item) => ({
                  rubricItemId: item.rubricItemId,
                  rubricItemTitle: item.title,
                  criteriaId: item.criteriaId,
                  criteriaTitle: item.criteriaTitle,
                  grades: item.grades,
                  id: item.id || 0,
                })),
              }
            : null
          : null,
    };
    try {
      setIsLoading(true);
      let response = await axios({
        method: "post",
        url: `${url}/api/assignment/teacher-submit-assignment${user.slug}`,
        data: finalPayload,
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.data?.status === "success") {
        // setCurrentExCard(null);
        if (status === "Completed") {
          NotificationAlert("Assignment Graded", "success");
        } else if (status === "Resubmit") {
          NotificationAlert(
            `Re Submit instruction sent to ${selectedSubmission?.studentName} `,
            "success"
          );
        } else {
          NotificationAlert(
            `You have updated gradings of ${selectedSubmission?.studentName} `,
            "success"
          );
        }
        setIsSubmissionsPage(true);
        setIsGradingPage(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkStudentSubmissionLate = (dueDate, submissionDate) => {
    let isLate = false;
    if (moment(dueDate).isValid() && moment(submissionDate).isValid()) {
      return moment(submissionDate) > moment(dueDate);
    }
    return isLate;
  };

  const getSubmissionStatus = (submissionStatus) => {
    const statusTranslation = translation.studentSubmissionStatus;
    switch (submissionStatus) {
      case "Pending":
        return statusTranslation.pending;
      case "Grades Pending":
        return statusTranslation.gradesPending;
      case "Resubmit":
        return statusTranslation.resubmit;
      case "Completed":
        return statusTranslation.completed;
      default:
        return submissionStatus;
    }
  };

  const getGradingDropdownItems = () => {
    const formattedItems = selectedStudentSubmission.attachments?.length
      ? gradingStatusDropDown
      : gradingStatusDropDown.filter((item) => item.key === 2);

    return formattedItems.map((item) => {
      switch (item.key) {
        case 1:
          return { ...item, text: gradingTranslation.markCompleted };
        case 2:
          return { ...item, text: gradingTranslation.reSubmit };
        case 3:
          return { ...item, text: gradingTranslation.updateGradings };
      }
    });
  };

  return (
    <>
      <CustomProgressIndicator
        isLoading={isLoading}
        style={{
          progressTrack: {
            backgroundColor: "#ffffff",
          },
        }}
      />

      <div className="mt-4">
        <Dialog
          headerAction={{
            icon: <CloseIcon />,
            title: translation.close,
            onClick: () => {
              setIsReasonForResubmitDialogOpen(false);
              setReasonForResubmitText("");
            },
          }}
          header={
            <Text weight={"regular"}>{gradingTranslation.resubmitReason}</Text>
          }
          content={
            <Flex column gap="gap.medium">
              <FlexItem>
                <TextArea
                  fluid
                  value={reasonForResubmitText}
                  rows={10}
                  onChange={(e, { value }) => {
                    setReasonForResubmitText(value);
                  }}
                />
              </FlexItem>
            </Flex>
          }
          open={isReasonForResubmitDialogOpen}
          confirmButton={{
            disabled: !reasonForResubmitText.length,
            content: translation.done,
            primary: true,
          }}
          onConfirm={() => {
            setIsReasonForResubmitDialogOpen(false);
            updateStudentGradings({
              status: "Resubmit",
              resubmitReason: reasonForResubmitText,
            });
          }}
        />

        <Flex space="between" wrap style={{ rowGap: "10px" }}>
          <Flex gap="gap.small" vAlign="center">
            <ArrowLeftIcon
              title={translation.back}
              className="ml-3"
              onClick={() => {
                setIsGradingPage(false);
                setIsSubmissionsPage(true);
              }}
              style={{ cursor: "pointer" }}
            />
            <Text weight="bold" size="large">
              {gradingTranslation.allSubmissions}
            </Text>
          </Flex>
          <Flex
            gap="gap.small"
            className="tour-submit-grade-student-submission"
          >
            {(selectedSubmission?.submissionStatus === "Completed" ||
              selectedStudentSubmission?.data?.Grades?.length) &&
            !isUpdateGrade ? (
              <Button
                content={
                  <span>
                    <EditIcon /> {gradingTranslation.editGradings}
                  </span>
                }
                onClick={() => {
                  setIsUpdateGrade(true);
                }}
              />
            ) : (
              <>
                <Dropdown
                  options={getGradingDropdownItems(
                    selectedStudentSubmission.attachments?.length
                  )}
                  dropdownWidth="auto"
                  placeholder={gradingTranslation.selectAction}
                  className="ml-2"
                  onChange={(e, option) => {
                    if (option.key === 2) {
                      setStudentGradings([]);
                    }
                    setselectedGrade(option.key);
                  }}
                />
                <Button
                  content={translation.apply}
                  disabled={isLoading}
                  onClick={() => {
                    // history.push({
                    //   pathname: "/assignments",
                    // });
                    if (selectedGrade == 1) {
                      updateStudentGradings({ status: "Completed" });
                    } else if (selectedGrade == 2) {
                      setIsReasonForResubmitDialogOpen(true);
                    } else if (selectedGrade == 3) {
                      updateStudentGradings({
                        status: "updateGradings",
                      });
                    }
                  }}
                  primary={!!selectedGrade}
                />
              </>
            )}
          </Flex>
        </Flex>
        <div className="ms-Grid">
          <div className="ms-Grid-row mt-3">
            <div
              className={`ms-Grid-col ms-sm12 ms-md12 ms-xl6`}
              style={{ minWidth: "280px" }}
            >
              <div className="ms-depth-4 student-submission-card-container tour-view-student-submission">
                <Card className="student-submission-title-card">
                  <CardHeader>
                    <div className="ms-Grid-row student-submission-title-card-section">
                      <Flex
                        wrap
                        space="between"
                        hAlign="center"
                        style={{ rowGap: "5px" }}
                      >
                        {/* <Flex> */}
                        <Flex gap="gap.small">
                          <Avatar
                            image={
                              selectedSubmission.imgUrl
                                ? selectedSubmission.imgUrl + user?.SASToken
                                : ""
                            }
                            name={selectedSubmission?.studentName}
                          />
                          <Flex column space="between">
                            <Text
                              size="medium"
                              content={selectedSubmission?.studentName}
                              weight="bold"
                            />
                            <Text
                              content={getSubmissionStatus(
                                selectedSubmission?.submissionStatus
                              )}
                              className={
                                selectedSubmission?.submissionStatus ===
                                "Grades Pending"
                                  ? "text-warning"
                                  : selectedSubmission?.submissionStatus ===
                                    "Pending"
                                  ? "text-danger"
                                  : selectedSubmission?.submissionStatus ===
                                    "Completed"
                                  ? "published"
                                  : "resubmit"
                              }
                              size="medium"
                              style={{ marginTop: "5px" }}
                            />
                          </Flex>
                        </Flex>
                        {/* </Flex> */}
                        {/* <Flex> */}
                        <Flex column>
                          <Flex gap="gap.smaller">
                            <Text
                              content={`${gradingTranslation.submissionDate}:`}
                              color="brand"
                              size="medium"
                            />
                            <Text size="medium">
                              {selectedSubmission?.submissionDate
                                ? moment(selectedSubmission?.submissionDate)
                                    .isValid
                                  ? moment(
                                      selectedSubmission?.submissionDate
                                    ).format("MMM DD,yyyy | HH:mm")
                                  : translation.NA
                                : translation.NA}
                            </Text>
                          </Flex>
                          <div
                            className="late-label"
                            style={{ alignSelf: "end", marginTop: "5px" }}
                          >
                            <Text align="center">
                              {checkStudentSubmissionLate(
                                selectedSubmission?.dueDate,
                                selectedSubmission?.submissionDate
                              )
                                ? translation.late
                                : ""}
                            </Text>
                          </div>
                        </Flex>
                        {/* </Flex> */}
                      </Flex>
                    </div>
                    {/* </Flex> */}
                  </CardHeader>
                </Card>
                <Card>
                  <div className="student-submission-detail-card-section">
                    {selectedStudentSubmission?.attachmentData?.length ? (
                      <CardBody>
                        <div>
                          <AttachmentOption
                            isGradingPage={true}
                            selectedStudentAttachments={
                              selectedStudentSubmission?.attachments
                            }
                            settings={props.settings}
                          />
                        </div>
                      </CardBody>
                    ) : (
                      <CardBody>
                        <div>{gradingTranslation.noSubmissions}</div>
                      </CardBody>
                    )}
                  </div>
                </Card>
              </div>

              {currentAssignmentData.rubricBankId && (
                <div className="mt-4" style={{ marginBottom: "15px" }}>
                  <Button
                    content={
                      currentAssignmentData &&
                      currentAssignmentData?.rubric?.rubricItems?.length
                        ? translation.assignmentDetails.viewRubric
                        : translation.assignmentDetails.createRubric
                    }
                    onClick={() => {
                      setIsGradingPage(true);
                      setIsSubmissionsPage(false);
                      props.setState({ isCreatingRubric: true });

                      // history.push(
                      //   {
                      //     pathname: "/assignments/assignmnet/rubric",
                      //   },
                      //   {
                      //     callBackPage: "gradingPage",
                      //     selectedSubmission,
                      //   }
                      // );
                      // onSubmissionSelect(selectedSubmission?.StudentEmailId);
                      // setIsGradingPage(true);
                    }}
                  />
                </div>
              )}
            </div>
            {currentAssignmentData &&
              currentAssignmentData?.rubric?.rubricItems?.[0]?.havePoints && (
                <div
                  className={`ms-Grid-col ms-sm12 ms-md12 ms-xl6  student-submission-grading-card-container tour-rubric-grade-student-submission`}
                  style={{ minWidth: "280px" }}
                >
                  <div className="ms-depth-4 student-submission-grading-card-wrapper ">
                    <Card className="student-submission-grading-title-card">
                      <CardHeader className="card-outer header-shadow">
                        <Flex gap="gap.small" space="between" vAlign="center">
                          <Flex column>
                            <Text weight="bold" size="large">
                              {gradingTranslation.gradings}
                            </Text>
                          </Flex>
                          <Flex hAlign="end">
                            <div className="grades">
                              <Text>
                                {`${
                                  studentGradings?.length
                                    ? studentGradings?.reduce(
                                        (prevTotal, currentItem) =>
                                          (Number(currentItem.grades) || 0) +
                                          prevTotal,
                                        0
                                      )
                                    : 0
                                } / ${currentAssignmentData?.markingType}`}
                              </Text>
                            </div>
                          </Flex>
                        </Flex>
                      </CardHeader>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className="">
                          {/* <div className="card-body-height"> */}
                          <div className="ms-Grid">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm4 ms-md-4 ms-lg4 mt-4">
                                <Text>{gradingTranslation.rubricCriteria}</Text>
                              </div>
                              <div className="ms-Grid-col ms-sm4 ms-md-4 ms-lg8 mt-4">
                                <Text>{gradingTranslation.points}</Text>
                              </div>
                            </div>
                          </div>
                          <div className="ms-Grid">
                            {currentAssignmentData?.rubric?.rubricItems?.length
                              ? currentAssignmentData?.rubric?.rubricItems?.map(
                                  (rubric, index) => {
                                    let studentGradForCurrentRubric =
                                      studentGradings?.find(
                                        (grade) =>
                                          grade.rubricItemId === rubric.id
                                      );

                                    return (
                                      <div key={index}>
                                        <div className="ms-Grid-row mt-3">
                                          <div className="ms-Grid-col ms-sm12 ms-md-4 ms-lg4 mt-2">
                                            <Text weight="semibold">
                                              {rubric.title}
                                            </Text>
                                          </div>
                                          <div className="ms-Grid-col ms-sm12 ms-md-8 ms-lg8 mt-2">
                                            {rubric.levels &&
                                            rubric.levels.length > 0
                                              ? rubric.levels.map(
                                                  (level, index) => {
                                                    return (
                                                      <Button
                                                        key={index}
                                                        className={`marks-distribution mr-2 mb-1 ${
                                                          studentGradForCurrentRubric?.grades ===
                                                            level.points &&
                                                          studentGradForCurrentRubric?.criteriaId ===
                                                            level.criteriaId
                                                            ? "btn-bg-primary text-white"
                                                            : ""
                                                        }`}
                                                        style={{
                                                          padding: "5px",
                                                        }}
                                                        iconOnly
                                                        content={
                                                          level.points ?? 0
                                                        }
                                                        disabled={
                                                          (!!selectedStudentSubmission
                                                            ?.data?.Grades
                                                            ?.length &&
                                                            !isUpdateGrade) ||
                                                          selectedGrade == 2 ||
                                                          !selectedGrade
                                                        }
                                                        onClick={() => {
                                                          // selectedButton.push(keycustom)
                                                          if (isEditGrades) {
                                                            let currentSelectedPoints =
                                                              studentGradings?.length
                                                                ? studentGradings
                                                                    .filter(
                                                                      (
                                                                        grades
                                                                      ) =>
                                                                        grades.rubricItemId !=
                                                                        rubric.id
                                                                    )
                                                                    ?.reduce(
                                                                      (
                                                                        prevTotal,
                                                                        currentItem
                                                                      ) =>
                                                                        (Number(
                                                                          currentItem.grades
                                                                        ) ||
                                                                          0) +
                                                                        prevTotal,
                                                                      0
                                                                    )
                                                                : 0;
                                                            if (
                                                              currentSelectedPoints +
                                                                Number(
                                                                  level.points
                                                                ) <=
                                                              Number(
                                                                currentAssignmentData?.markingType
                                                              )
                                                            ) {
                                                              setStudentGradings(
                                                                (
                                                                  prevGrades
                                                                ) => {
                                                                  let gradForCurrentRubric =
                                                                    prevGrades?.find(
                                                                      (grade) =>
                                                                        grade.rubricItemId ===
                                                                        rubric.id
                                                                    );
                                                                  if (
                                                                    gradForCurrentRubric
                                                                  ) {
                                                                    return [
                                                                      ...prevGrades.map(
                                                                        (
                                                                          grade
                                                                        ) =>
                                                                          grade.rubricItemId ==
                                                                          rubric.id
                                                                            ? {
                                                                                ...grade,
                                                                                grades:
                                                                                  level.points,
                                                                                criteriaTitle:
                                                                                  level.title,
                                                                                criteriaId:
                                                                                  level.id,
                                                                              }
                                                                            : grade
                                                                      ),
                                                                    ];
                                                                  } else {
                                                                    return [
                                                                      ...prevGrades,
                                                                      {
                                                                        rubricItemId:
                                                                          rubric.id,
                                                                        title:
                                                                          rubric.title,
                                                                        grades:
                                                                          level.points,
                                                                        criteriaId:
                                                                          level.id,
                                                                        criteriaTitle:
                                                                          level.title,
                                                                      },
                                                                    ];
                                                                  }
                                                                }
                                                              );
                                                            }
                                                          }
                                                        }}
                                                      />
                                                    );
                                                  }
                                                )
                                              : null}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(GradingPage);
