import { CloseIcon, Dialog, Loader, Text } from "@fluentui/react-northstar";
import { Approval } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import collectionPlaceholderImage from "../../Assets/images/svg/collectionPlaceholderImage.svg";
import noPermission from "../../Assets/images/svg/no-permission.svg";
import userAvatar from "../../Assets/images/svg/userAvatar.svg";
import unpublished_icon from "../../Assets/images/unpublished_icon.png";
import verify from "../../Assets/images/verify.png";
import ConfirmDialog from "../Assignment/Components/ConfirmDialog/ConfirmDialog";
import LibraryCarousel from "./LibraryCarousel";
import { CollectionImagePreview } from "./collectionImagePreview";
import "./collections.css";
import RejectCollectionDialog from "./rejectCollectionDialog";
import moment from "moment";
import FilterCollection from "./FilterCollection";

const END_POINT = process.env.REACT_APP_EP_URL;
const WHITEBOARD_URL = process.env.REACT_APP_EP_URL_WHITEBOARD_API;

export const Collection = (props) => {
  const [collection, setCollection] = useState([]);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState({});
  const [open, setOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [previewCollectionImageDialog, setPreviewCollectionImageDialog] =
    useState(false);
  const [currentLibraryId, setCurrentLibraryId] = useState("");
  const [previewCollectionImage, setPreviewCollectionImage] = useState("");

  const [rejectCollection, setRejectCollection] = useState({
    id: "",
    reason: "",
  });
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [search, setSearch] = useState("");
  const [filterOption, setFilterOption] = useState({
    authors: [],
    tags: [],
  });
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [conformationModal, setConformationModal] = useState(false);
  const [id, setId] = useState(null);

  const getTemplet = () => {
    axios
      .get(
        `${process.env.REACT_APP_EP_URL_WHITEBOARD_API}/get-templates-filter-options`
      )
      .then(async (res) => {
        setFilterOption(res.data.result);
      })
      .catch((err) => {
        console.error(err, err.response);
      });
  };

  useEffect(() => {
    getTemplet();
  }, []);

  useEffect(() => {
    var svgs = document.getElementsByTagName("svg");
    console.log(svgs);

    // Iterate through the SVG elements and remove the height attribute
    for (var i = 0; i < svgs.length; i++) {
      svgs[i].removeAttribute("height");
    }
  }, [previewCollectionImageDialog]);

  const getCollection = (slug, token) => {
    axios
      .get(`${END_POINT}/api/user/get-collections${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setCollection(res.data.result);
        setFilteredCollections(res.data.result);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err?.response?.data?.message);
        console.error(err, err.response);
      });
  };

  useEffect(() => {
    const user = props.user;
    if (user && user.slug && user.accessToken) {
      getCollection(user.slug, user.accessToken);
    }
  }, [props.user]);

  const handleApproveLibrary = async (id) => {
    setId(id);
    setConformationModal(true);
  };

  const approveLibrary = async () => {
    setConformationModal(false);
    setLoading((oldState) => ({ ...oldState, [id]: true }));
    try {
      const response = await fetch(`${WHITEBOARD_URL}/approve-collocation`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      getCollection(props.user.slug, props.user.accessToken);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } catch (error) {
      console.log(error);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    }
  };

  const handleUnPublishLibrary = async (id) => {
    setLoading((oldState) => ({ ...oldState, [id]: true }));
    try {
      const response = await fetch(`${WHITEBOARD_URL}/unpublish-collocation`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      getCollection(props.user.slug, props.user.accessToken);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } catch (error) {
      console.log(error);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reasonInputChangeHandler = (reasonText, id) => {
    setRejectCollection({ ...rejectCollection, reason: reasonText });
  };

  const handleReject = async (id) => {
    try {
      const response = await fetch(
        `${WHITEBOARD_URL}/reject-collection?id=${rejectCollection.id}`,
        {
          method: "DELETE",
          body: JSON.stringify({ reason: rejectCollection.reason }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      getCollection(props.user.slug, props.user.accessToken);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } catch (error) {
      console.log(error);
      setLoading((oldState) => ({ ...oldState, [id]: false }));
    } finally {
      setOpen(false);
    }
  };

  const rejectHandlerLibrary = async (id) => {
    setRejectCollection({ ...rejectCollection, id });
    setOpen(true);
  };

  const myCollections_ =
    !!search.trim() || selectedFilter !== null
      ? filteredCollections
      : collection;

  return load ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <div>
      {isModelOpen && (
        <ConfirmDialog
          isConfirmDelete={isModelOpen}
          setisConfirmDelete={setIsModelOpen}
          cancelButton="cancel"
          confirmButton="unpublish"
          content="Are you sure you want to unpublish library?"
          header="Unpublish library"
          onConfirm={() => {
            setIsModelOpen(false);
            handleUnPublishLibrary(currentLibraryId);
          }}
          onCancel={() => {
            setIsModelOpen(false);
          }}
        />
      )}

      {conformationModal && (
        <Dialog
          headerAction={{
            icon: <CloseIcon />,
            title: "Close",
            onClick: () => {
              setConformationModal(false);
            },
          }}
          header={<Text weight={"medium"}>Are you sure?</Text>}
          content={"Are you sure you want to perform this action?"}
          open={conformationModal}
          onConfirm={approveLibrary}
          confirmButton={"Okey"}
        />
      )}

      {/* Preview collection image dialog */}
      <Dialog
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setPreviewCollectionImageDialog(false);
          },
        }}
        header={<Text weight={"regular"}>Preview collection Image</Text>}
        content={
          <div>
            {Array.isArray(previewCollectionImage) ? (
              previewCollectionImage.length > 0 &&
              previewCollectionImage.length > 1 ? (
                <LibraryCarousel
                  images={previewCollectionImage}
                  index={1}
                  name="dialog"
                />
              ) : (
                <CollectionImagePreview
                  previewURL={previewCollectionImage[0]}
                  index={1}
                />
              )
            ) : (
              <CollectionImagePreview
                previewURL={previewCollectionImage}
                index={1}
              />
            )}
          </div>
        }
        id="preview-dialog"
        className="preview-dialog"
        open={previewCollectionImageDialog}
      />

      <FilterCollection
        setFilteredCollections={setFilteredCollections}
        setLoading={setLoading}
        open={open}
        setCollection={setFilteredCollections}
        collection={filteredCollections}
        setSearch={setSearch}
        search={search}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        setSelectedFilter={setSelectedFilter}
        token={props.user.accessToken}
        slug={props.user.slug}
        allCollection={collection}
      />

      {myCollections_.length > 0 ? (
        <div
          className="btsp collection-main"
          style={{
            height: props.isTabletOrMobileView
              ? "calc(100vh - 190px)"
              : "calc(100vh - 350px)",
            gridTemplateColumns: "repeat(auto-fill, minmax(245px, 1fr))",
            alignItems: "start",
            gap: "10px",
          }}
        >
          {myCollections_?.map((collection, index) => {
            return (
              <div
                className="collection-card p-4"
                style={{ maxWidth: "300px", width: "100%", height: "460px" }}
              >
                <div className="header d-flex justify-content-between mb-2">
                  <h5 className="collection-title fs-5 d-inline-block fw-bolder mb-0">
                    {collection.name.length > 20
                      ? collection.name.substr(0, 20) + "..."
                      : collection.name}
                  </h5>
                  {(collection.status === "Approved" ||
                    collection.status === "Unpublished") && (
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          collection.status === "Approved"
                            ? verify
                            : unpublished_icon
                        }
                        alt=""
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div
                    className="img-div"
                    onClick={() => {
                      setPreviewCollectionImageDialog(true);
                      setPreviewCollectionImage(
                        collection.preview
                          ? collection.preview
                          : collectionPlaceholderImage
                      );
                    }}
                  >
                    {Array.isArray(collection.preview) ? (
                      collection.preview.length > 0 &&
                      collection.preview.length > 1 ? (
                        <LibraryCarousel
                          images={collection.preview}
                          index={index}
                          name="card"
                        />
                      ) : (
                        <CollectionImagePreview
                          previewURL={collection.preview[0]}
                          index={index}
                        />
                      )
                    ) : (
                      <CollectionImagePreview
                        previewURL={collection.preview}
                        index={index}
                      />
                    )}
                  </div>
                  <div className="collection-info d-flex mt-2">
                    <div className="user-info d-flex">
                      <div className="user-image">
                        <img
                          src={userAvatar}
                          className="w-100 h-auto"
                          alt="user-image"
                        />
                      </div>
                      <div className="username ms-2">
                        {collection.authors[0]?.name}
                      </div>
                    </div>
                    {/* <div className="date text-nowrap">{collection.created}</div> */}
                    <div className="date text-left">
                      {moment(collection.created).format("DD-MM-YY")}
                    </div>
                  </div>
                  <div className="mt-3 text-left collection-name">
                    {collection.description.length > 65
                      ? collection.description.substr(0, 65) + "..."
                      : collection.description}
                  </div>
                </div>

                <div className="footer">
                  <div className="btns d-flex gap-2 justify-content-end">
                    <LoadingButton
                      loading={loading[collection.id] || false}
                      sx={{
                        backgroundColor:
                          collection.status === "Approved" ||
                          collection.status === "Unpublished"
                            ? "#F25767"
                            : collection.status === "Rejected"
                            ? "#F25767"
                            : "#585A96",
                      }}
                      loadingPosition="start"
                      startIcon={
                        collection.status === "Approved" ||
                        collection.status === "Unpublished" ? (
                          <img
                            src={noPermission}
                            style={{ marginRight: "5px" }}
                          />
                        ) : (
                          <Approval />
                        )
                      }
                      variant="contained"
                      onClick={() => {
                        collection.status === "Under review" &&
                          handleApproveLibrary(collection.id);

                        if (collection.status === "Approved") {
                          setCurrentLibraryId(collection.id);
                          setIsModelOpen(true);
                        }
                      }}
                    >
                      {collection.status === "Under review"
                        ? "Approve"
                        : collection.status === "Approved"
                        ? "UnPublish"
                        : collection.status}
                    </LoadingButton>
                    {/* <a
                  className="addToCollection bg-white px-2 py-1 cursor-pointer text-decoration-none mx-2"
                  href={`https://wb-lib-approval-view.azurewebsites.net?element=${collection.source}`}
                  target="_blank"
                >
                  Link
                </a> */}

                    {collection.status === "Under review" && (
                      <button
                        className="addToCollection bg-white px-2 py-1 ml-2 cursor-pointer text-decoration-none"
                        onClick={() => rejectHandlerLibrary(collection.id)}
                      >
                        Reject
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <RejectCollectionDialog
            open={open}
            handleClose={handleClose}
            reasonInputChangeHandler={reasonInputChangeHandler}
            handleReject={handleReject}
          />
        </div>
      ) : (
        <p
          style={{
            justifyContent: "center",
            display: "flex",
            margin: "20px",
            fontSize: "17px",
          }}
        >
          No Collection Available
        </p>
      )}
    </div>
  );
};
