import React from "react";
import {
  ArrowRightIcon,
  Button,
  DownloadIcon,
  Flex,
  Segment,
  Table,
} from "@fluentui/react-northstar";
import "./startupInformation.scss";
import { ArrowLeftIcon } from "@fluentui/react-icons-northstar";
import {
  LessonDayTemplate,
  LessonTemplate,
  MembersTemplate,
} from "../../Helper/ExportTemplate";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { membersData } from "../../Helper/TempData";
import studentSmpl from "../UploadData/Sample-Zip/student-sample.zip";
import teacherSmpl from "../UploadData/Sample-Zip/teacher-sample.zip";
import allFilesZip from "../StartupInformation/Sample-Zip/allSampleFile.zip";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const url = process.env.REACT_APP_EP_URL;

const StartupInformation = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const lessonTemplateRef = useRef("");
  const lessonDayTemplateRef = useRef("");

  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 550) {
        setSmallDevice(true);
      } else {
        setSmallDevice(false);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const onBackBtnHander = () => {
    axios
      .post(`${url}/api/startup/update-DB-data${props.data.user.slug}`, {
        plan: "Current",
        email: props.data?.user?.username
          ? props.data?.user?.username
          : props.data?.user.mail,
        isFirstTime: 0,
      })
      .then((res) => {
        // props.getDb();
        history.push("/start-up");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setErr(t("startUp").initial[8]);
        console.log(err);
      });
  };

  const header = {
    items: [
      <h1>{t("startUpInfo").header[0]}</h1>,
      <Button
        icon={<DownloadIcon />}
        content={t("startUpInfo").buttons[1]}
        onClick={() => document.getElementById("all-zip-upload-files").click()}
        primary
      />,
      // !smallDevice && <h1>{t("startUpInfo").header[1]}</h1>,
    ],
  };
  const rows = [
    {
      key: 1,
      items: [
        <div>
          <div>
            <h2>{t("startUp").controls.navigation[0]}</h2>
            <ul>
              <li>
                {t("startUpInfo").lessonData.details[0]}
                <a
                  rel="noopener noreferrer"
                  onClick={() => lessonTemplateRef.current.click()}
                >
                  {t("startUpInfo").lessonData.links[0]}
                </a>{" "}
                {t("startUpInfo").lessonData.details[1]}
              </li>
              <div> {t("startUpInfo").lessonData.details[2]}</div>
              <li>
                {t("startUpInfo").lessonData.details[0]}
                <a
                  rel="noopener noreferrer"
                  onClick={() => lessonTemplateRef.current.click()}
                >
                  {t("startUpInfo").lessonData.links[1]}
                </a>{" "}
                {t("startUpInfo").lessonData.details[1]}
              </li>
              <li> {t("startUpInfo").lessonData.details[3]}</li>
              <li>{t("startUpInfo").lessonData.details[4]}</li>
              <li> {t("startUpInfo").lessonData.details[5]}</li>
            </ul>
          </div>

          {smallDevice && (
            <div style={{ margin: "20px 0" }}>
              <h2>{t("startUpInfo").header[1]}</h2>
              <Flex column={true}>
                <Flex column={true}>
                  <span> {t("startUpInfo").lessonData.FileDetails[0]}</span>
                  <LessonTemplate ref={lessonTemplateRef} />
                </Flex>
                <Flex column={true}>
                  <span className="mt-2">
                    {t("startUpInfo").lessonData.FileDetails[1]}
                  </span>
                  <LessonDayTemplate ref={lessonDayTemplateRef} />
                </Flex>
              </Flex>
            </div>
          )}
        </div>,
        !smallDevice && (
          <div>
            <Flex column={true}>
              <Flex column={true}>
                <span> {t("startUpInfo").lessonData.FileDetails[0]}</span>
                <LessonTemplate ref={lessonTemplateRef} />
              </Flex>
              <Flex column={true}>
                <span className="mt-2">
                  {t("startUpInfo").lessonData.FileDetails[1]}
                </span>
                <LessonDayTemplate ref={lessonDayTemplateRef} />
              </Flex>
            </Flex>
          </div>
        ),
      ],
    },
    {
      key: 2,
      items: [
        <div>
          <h2>{t("startUp").controls.navigation[1]}</h2>
          <ul>
            <li>{t("startUpInfo").memberData.details[0]}</li>
            <li>{t("startUpInfo").memberData.details[1]}</li>
            <li>{t("startUpInfo").memberData.details[2]}</li>
            <ul>
              <li>
                <span className="fw-600">
                  {t("startUpInfo").memberData.key[0]} :
                </span>{" "}
                {t("startUpInfo").memberData.details[3]}
              </li>
              <li>
                <span className="fw-600">
                  {t("startUpInfo").memberData.key[1]} :
                </span>{" "}
                {t("startUpInfo").memberData.details[4]}
              </li>
              <li>
                <span className="fw-600">
                  {t("startUpInfo").memberData.key[2]} :
                </span>
                {t("startUpInfo").memberData.details[5]}
              </li>
            </ul>
          </ul>
          {smallDevice && (
            <div style={{ margin: "20px 0" }}>
              <h2>{t("startUpInfo").header[1]}</h2>
              <Flex column={true}>
                <span>{t("startUpInfo").memberData.FileDetails[0]}</span>
                <MembersTemplate lessonExcelData={membersData} />
              </Flex>
            </div>
          )}
        </div>,
        !smallDevice && (
          <Flex column={true}>
            <span>{t("startUpInfo").memberData.FileDetails[0]}</span>
            <MembersTemplate lessonExcelData={membersData} />
          </Flex>
        ),
      ],
    },
    {
      key: 3,
      items: [
        <div>
          <h2>{t("startUp").controls.navigation[2]}</h2>
          <ul>
            <li>{t("startUpInfo").studentImg.details[0]}</li>
            <li>{t("startUpInfo").studentImg.details[1]}</li>
            <li>{t("startUpInfo").studentImg.details[2]}</li>
            <li>{t("startUpInfo").studentImg.details[3]}</li>
          </ul>
          {smallDevice && (
            <div style={{ margin: "20px 0" }}>
              <h2>{t("startUpInfo").header[1]}</h2>
              <Flex column={true}>
                <span>{t("startUpInfo").studentImg.FileDetails[0]}</span>
                <a
                  href={studentSmpl}
                  // target="_blank"
                  id="zip-std-info"
                  style={{ display: "none" }}
                  download
                >
                  {t("startUp").controls.action[0]}
                </a>
                <Button
                  icon={<DownloadIcon />}
                  content={t("startUp").controls.action[0]}
                  onClick={() =>
                    document.getElementById("zip-std-info").click()
                  }
                  className={"w-fit-content"}
                />
              </Flex>
            </div>
          )}
        </div>,
        !smallDevice && (
          <Flex column={true}>
            <span>{t("startUpInfo").studentImg.FileDetails[0]}</span>
            <a
              href={studentSmpl}
              // target="_blank"
              id="zip-std-info"
              style={{ display: "none" }}
              download
            >
              {t("startUp").controls.action[0]}
            </a>
            <Button
              icon={<DownloadIcon />}
              content={t("startUp").controls.action[0]}
              onClick={() => document.getElementById("zip-std-info").click()}
              className={"w-fit-content"}
            />
          </Flex>
        ),
      ],
    },
    {
      key: 4,
      items: [
        <div>
          <h2>{t("startUp").controls.navigation[3]}</h2>
          <ul>
            <li>{t("startUpInfo").teacherImg.details[0]}</li>
            <li>{t("startUpInfo").teacherImg.details[1]}</li>
            <li>{t("startUpInfo").teacherImg.details[2]}</li>
            <li>{t("startUpInfo").teacherImg.details[3]}</li>
          </ul>
          {smallDevice && (
            <div style={{ margin: "20px 0" }}>
              <h2>{t("startUpInfo").header[1]}</h2>
              <Flex column={true}>
                <span>{t("startUpInfo").teacherImg.FileDetails[0]}</span>
                <a
                  href={teacherSmpl}
                  // target="_blank"
                  id="zip-tch-info"
                  style={{ display: "none" }}
                  download
                >
                  {t("startUp").controls.action[0]}
                </a>
                <Button
                  icon={<DownloadIcon />}
                  content={t("startUp").controls.action[0]}
                  onClick={() =>
                    document.getElementById("zip-tch-info").click()
                  }
                  className={"w-fit-content"}
                />
              </Flex>
            </div>
          )}
        </div>,
        !smallDevice && (
          <Flex column={true}>
            <span>{t("startUpInfo").teacherImg.FileDetails[0]}</span>
            <a
              href={teacherSmpl}
              // target="_blank"
              id="zip-tch-info"
              style={{ display: "none" }}
              download
            >
              {t("startUp").controls.action[0]}
            </a>
            <Button
              icon={<DownloadIcon />}
              content={t("startUp").controls.action[0]}
              onClick={() => document.getElementById("zip-tch-info").click()}
              className={"w-fit-content"}
            />
          </Flex>
        ),
      ],
    },
  ];

  return (
    <Segment className="information-container">
      <div>
        <Flex space="between" hAlign="flex-end">
          <div>
            <a
              href={allFilesZip}
              // target="_blank"
              id="all-zip-upload-files"
              style={{ display: "none" }}
              download
            >
              {t("startUpInfo").buttons[1]}
            </a>
            <Button
              content={"Back"}
              onClick={onBackBtnHander}
              icon={<ArrowLeftIcon size="small" />}
              primary
            />
            {/* <Button
              icon={<DownloadIcon />}
              content={t("startUpInfo").buttons[1]}
              onClick={() =>
                document.getElementById("all-zip-upload-files").click()
              }
              primary
            /> */}
          </div>
          <div>
            <Button
              icon={<ArrowRightIcon />}
              content={t("startUpInfo").buttons[0]}
              onClick={() => history.push("/start-up/upload")}
              primary
            />
          </div>
        </Flex>
      </div>
      <Table
        header={header}
        className="startup-info-table"
        rows={rows}
        aria-label="Static table"
      />
      <div className="hidden-element">
        <LessonTemplate ref={lessonTemplateRef} />
        <LessonDayTemplate ref={lessonDayTemplateRef} />
      </div>
    </Segment>
  );
};

export default StartupInformation;
