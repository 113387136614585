import React, { useEffect, useState } from "react";
import { IconButton } from "@fluentui/react";
import {
  Dropdown,
  Flex,
  Input,
  SearchIcon,
  Text,
  CardHeader,
  CardBody,
  Card,
  Segment,
  Image,
  Divider,
  Loader,
  Tooltip,
} from "@fluentui/react-northstar";
import { useCallback } from "react";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { withTranslation } from "react-i18next";

import { ResponsiveImage } from "../../../../components/blocks/Images";
import NoClassRooms from "../../../../Assets/images/svg/school.svg";
import userSvg from "../../../../Assets/images/svg/user.svg";

import "./Classrooms.scss";
import { Col, Row } from "antd";

const inputItems = [25, 50, 75, 100];

const url = process.env.REACT_APP_EP_URL;

const SelectedClassroom = (props) => {
  const { user, t } = props;

  const { classroomId } = useParams();
  const { state } = useLocation();
  const history = useHistory();

  const [studentsList, setStudentsList] = useState([]);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [classRoomData, setClassRoomData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const translation = t("feedbackModule");

  const feedbackTranslation = translation.body.classroom;

  const getClassroom = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${url}/api/classroom/${classroomId}${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result) {
        const { ClassID, ClassName, StudentData, ImageURL } = data.result;

        setClassRoomData({
          classroomId: ClassID,
          classroomTitle: ClassName,
          students: StudentData?.length
            ? StudentData.map(
                ({ firstName, lastName, email, photo, ...rest }) => ({
                  ...rest,
                  studentEmailId: email,
                  name: `${firstName}${lastName ?? ""}`,
                  studentImageUrl: photo,
                })
              )
            : [],
          classroomsImageUrl: ImageURL,
        });
      } else {
        setClassRoomData(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (classroomId) {
      if (state?.classroom && Object.entries(state.classroom).length) {
        setClassRoomData({ ...state.classroom });
      } else {
        getClassroom();
      }
    }
  }, [classroomId]);

  useEffect(() => {
    if (classRoomData) {
      let data =
        Array.isArray(classRoomData.students) && classRoomData.students.length
          ? classRoomData.students
          : [];
      if (data.length) {
        setStudentsList([...data]);
        setFilteredStudentList([...data]);
        setPaginatedData(data.slice((page - 1) * count, count));
      } else {
        setStudentsList([]);
        setFilteredStudentList([]);
        setPaginatedData([]);
      }
    }
  }, [classRoomData]);

  useEffect(() => {
    setPage(1);
    let text = searchText.replace(/\s/g, "");
    if (text.length) {
      let fiteredData = studentsList.filter((student) =>
        student.name
          ?.toLowerCase()
          .replace(/\s/g, "")
          .includes(text.toLowerCase())
      );
      setFilteredStudentList([...fiteredData]);
    } else {
      setFilteredStudentList([...studentsList]);
    }
  }, [searchText, studentsList]);

  useEffect(() => {
    let data = filteredStudentList.slice(0, count);
    setPaginatedData([...data]);
  }, [filteredStudentList]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (value) => {
    setSearchText(value);
  };

  const handleCountChange = (value) => {
    setPage(1);
    setCount(value);
    setPaginatedData(filteredStudentList.slice(0, value));
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const handlePaginationChange = (value) => {
    let page = value.selected + 1;
    setPage(page);
    setPaginatedData(
      filteredStudentList.slice((page - 1) * count, count * page)
    );
  };

  return (
    <div style={{ marginTop: "30px" }}>
      {isLoading ? (
        <Loader style={{ paddingTop: "50px" }} />
      ) : classRoomData ? (
        <div className="classrooms-container">
          <Flex style={{ padding: "0 0 0 25px" }}>
            <IconButton
              iconProps={{ iconName: "ChromeBack" }}
              title={translation.common.back}
              ariaLabel={translation.common.back}
              onClick={() => {
                history.push("/feedback/classrooms");
              }}
            />
            <Text
              color="brand"
              size="larger"
              weight="semibold"
              style={{ marginRight: "10px" }}
            >
              {classRoomData.classroomTitle}
            </Text>
          </Flex>
          <div style={{ marginTop: "10px" }}>
            <Flex vAlign="center" hAlign="center" wrap>
              <Input
                icon={<SearchIcon />}
                clearable
                placeholder={feedbackTranslation.search}
                onChange={(e, { value }) => optimizedFn(value)}
                className="search-input"
              />
              <ReactPaginate
                previousLabel={feedbackTranslation.previous}
                nextLabel={feedbackTranslation.next}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={filteredStudentList.length / count}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePaginationChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
              <Dropdown
                items={inputItems}
                checkable
                value={count}
                getA11ySelectionMessage={{
                  onAdd: (item) => {
                    handleCountChange(item);
                  },
                }}
                className="pagination-dropdown"
                placeholder={feedbackTranslation.pageLimit}
              />
            </Flex>
            {/* <Flex wrap style={{ rowGap: "10px" }} space="around">
          <Flex.Item styles={{ maxWidth: "300px" }}>
            <Input
              fluid
              icon={<SearchIcon />}
              placeholder="Search..."
              onChange={(e) => optimizedFn(e.target.value)}
            />
          </Flex.Item>
          <Flex.Item styles={{ maxWidth: "300px" }}>
            <Pagination
              page={page}
              onChange={handlePaginationChange}
              shape="rounded"
              count={filteredStudentList.length / count}
            />
          </Flex.Item>
          <Flex.Item styles={{ maxWidth: "300px" }}>
            <Dropdown
              fluid
              items={[5, 10, 25, 50, 100]}
              value={count}
              onChange={(e, { value }) => {
                handleCountChange(value);
              }}
              placeholder="Start typing a name"
              noResultsMessage="We couldn't find any matches."
              a11ySelectedItemsMessage="Press Delete or Backspace to remove"
            />
          </Flex.Item>
        </Flex> */}
          </div>
          <Divider />
          <div
            style={{ marginTop: "10px" }}
            className="classroom-students-cards-wrapper"
          >
            {paginatedData?.length ? (
              <Row gutter={[10, 10]} style={{ margin: 0 }}>
                {paginatedData.map((student, index) => (
                  <Col xxl={4} xl={6} sm={12} xs={24}>
                    <Segment
                      style={{ width: "100%", margin: "0px !important" }}
                      key={student.studentEmailId + index}
                      content={
                        <Flex
                          style={{ flexDirection: "column", maxWidth: "100% " }}
                        >
                          <Image
                            src={
                              student.studentImageUrl
                                ? student.studentImageUrl
                                : userSvg
                            }
                            width="100%"
                            height="200px"
                            className="img"
                          />
                          <Tooltip
                            trigger={
                              <Text
                                content={
                                  student.name ??
                                  student.studentEmailId?.split("@")?.[0]
                                }
                                className="Karla fw6 m1 tcap"
                                style={{
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              />
                            }
                            content={
                              student.name ??
                              student.studentEmailId?.split("@")?.[0]
                            }
                          />
                        </Flex>
                      }
                      className="seg"
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <Flex fill column hAlign="center" vAlign="center">
                <Text
                  style={{ marginTop: "50px" }}
                  size="larger"
                  align="center"
                >
                  {studentsList.length
                    ? feedbackTranslation.noStudentForFilter
                    : feedbackTranslation.noStudent}
                </Text>
              </Flex>
            )}
          </div>
        </div>
      ) : (
        <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
          <ResponsiveImage src={NoClassRooms} style={{ width: "450px" }} />
          <Text weight="semibold" size="large">
            {feedbackTranslation.noClassroom}
          </Text>
        </Flex>
      )}
    </div>
  );
};

export default withTranslation()(SelectedClassroom);
