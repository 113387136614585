import { Image, Loader } from "@fluentui/react-northstar";
import axios from "axios";
import { createRef, useMemo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CameraIcon } from "@fluentui/react-icons-northstar";
import "./LessonImagePicker.css";
import { useTranslation } from "react-i18next";

const END_POINT = process.env.REACT_APP_EP_URL;

export const LessonImagePicker = (props) => {
  const [lessons, setLessons] = useState([]);
  const [uniqueLessons, setUniqueLessons] = useState([]);
  const [load, setLoad] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.user.slug && props.user.accessToken) {
      getAllLessons(props.user.slug, props.user.accessToken);
    }
  }, [props]);

  const uniqueLesson = useMemo(() => {
    const lesson = lessons.map((d) => ({
      id: d.Title,
      className: d.ClassName?.substring(0, d.ClassName.lastIndexOf("-") + 1)
        .slice(0, -1)
        .trim(),
      file: null,
      lessonImage: d.lessonImage?.ImageURL ? d.lessonImage.ImageURL : null,
      classId: d.ClassID,
    }));

    return [
      ...new Map(lesson.map((item) => [item["className"], item])).values(),
    ];
  }, [lessons]);

  const refs = uniqueLesson.reduce((acc, lesson) => {
    acc[lesson.id] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    setUniqueLessons(uniqueLesson);
  }, [uniqueLesson]);

  const getAllLessons = (slug, token) => {
    axios
      .get(`${END_POINT}/api/lession/get-lessions${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoad(false);
        const lessions = res.data;
        setLessons(lessions);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
        if (err.response?.status === 401) {
          this.setState({ accDlg: true });
        }
      });
  };

  const uploadLessonImage = (slug, token) => {
    const formData = new FormData();
    for (let i = 0; i < uniqueLessons.length; i++) {
      formData.append(uniqueLessons[i].classId, uniqueLessons[i].file);
      // console.log(uniqueLessons[i]);
    }
    axios
      .post(`${END_POINT}/api/lession/upload-lesson-image${slug}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // console.log(res.data);
        toast.success("Lesson image upload successfully!");
        // setLessons(lessions);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        // if (err.response?.status === 401) {
        //   this.setState({ accDlg: true });
        // }
      });
  };

  return load ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <>
      <div
        className="lesson"
        style={{
          width: 500,
          // margin: "50px auto",
          overflowY: "scroll",
          position: "relative",
          height: props.isTabletOrMobileView
            ? "calc(100vh - 230px)"
            : "calc(100vh - 300px)",
        }}
      >
        {uniqueLessons.map((lesson) => (
          <div className="btsp main_card_div">
            <span className="">
              {t("setting").lesson[0]}: {lesson.className}
            </span>
            <div
              className="imgPreview text-center "
              onClick={() => refs[lesson.id].current.click()}
            >
              {lesson.lessonImage || lesson.file ? (
                <>
                  <Image
                    className="cardImg"
                    src={
                      lesson.file
                        ? window.URL.createObjectURL(lesson.file)
                        : lesson.lessonImage + props.user.SASToken
                    }
                  />
                  <div className="camera_icon">
                    <CameraIcon />
                  </div>
                </>
              ) : (
                <>
                  <span className="h6 pt-5">+</span>
                  <div>Upload</div>
                </>
              )}
            </div>

            <input
              type={"file"}
              hidden
              ref={refs[lesson.id]}
              onChange={(e) => {
                const lessonIndex = uniqueLessons.findIndex(
                  (obj) => obj.id == lesson.id
                );
                uniqueLessons[lessonIndex].file = e.target.files[0];
                setUniqueLessons([...uniqueLessons]);
              }}
            />
          </div>
        ))}
        <div
          className="btsp"
          // style={{ position: "sticky", zIndex: "2", bottom: "0" }}
          style={{ position: "sticky", bottom: "0" }}
        >
          {uniqueLessons.length > 0 ? (
            <button
              className="btn text-white py-1 mt-3 btn-block "
              disabled={
                props.user.slug === "?slug=acvpreprod" ||
                props.user.slug === "?slug=acvapp"
              }
              style={{ fontSize: 14, backgroundColor: "rgb(98, 100, 167)" }}
              onClick={() =>
                uploadLessonImage(props.user.slug, props.user.accessToken)
              }
            >
              Upload
            </button>
          ) : (
            <p className="text-center">There is no lesson available!</p>
          )}
        </div>
      </div>
    </>
  );
};
