import {
  AcceptIcon,
  CloseIcon,
  Dialog,
  Divider,
  ExclamationCircleIcon,
  Flex,
  Image,
  Loader,
  PollIcon,
  ShiftActivityIcon,
  Status,
  SurveyIcon,
  Text,
} from "@fluentui/react-northstar";
import { Grid } from "@mui/material";
import React from "react";

function TeacherCard({
  teacher,
  loadedInfo,
  userSvg,
  props,
  t,
  getInfo,
  isMobileView,
  changeRoleHandler,
  load2,
}) {
  return (
    <Dialog
      key={teacher.TeacherEmailID}
      confirmButton={t("setting").init[3]}
      content={
        <Flex wrap style={{ justifyContent: "center" }}>
          <Image
            src={
              teacher.UrlPhoto
                ? user.slug === "?slug=acvpreprod" ||
                  props.user.slug === "?slug=acvapp"
                  ? teacher.UrlPhoto
                  : teacher.UrlPhoto + props.user.SASToken
                : userSvg
            }
            style={{ height: "100px", width: "100px" }}
          />
          <Flex style={{ flexDirection: "column", marginLeft: "10px" }}>
            <Flex wrap style={{ justifyContent: "center" }}>
              <Text
                content={teacher.TeacherName}
                className="Karla fw6 m1 tcap"
              />
              {teacher.role == "Admin" ? (
                <>
                  <Text
                    color="brand"
                    content={
                      <>
                        <Status
                          style={{
                            backgroundColor: "#6264a7",
                            margin: "0 3px 3px 0",
                          }}
                        />
                        Admin
                      </>
                    }
                    className="Karla fw6 m1 tcap"
                  />
                  {props.user.actualRole === "SuperAdmin"
                    ? changeRoleHandler(teacher.TeacherEmailID)
                    : null}
                </>
              ) : teacher.role === "SuperAdmin" ? (
                <Text
                  color="Yellow"
                  content={
                    <>
                      <Status
                        style={{
                          backgroundColor: "#FFAA44",
                          margin: "0 3px 3px 0",
                        }}
                      />
                      SuperAdmin
                    </>
                  }
                  className="Karla fw6 m1 tcap"
                />
              ) : props.user.actualRole === "SuperAdmin" ? (
                changeRoleHandler(teacher.TeacherEmailID)
              ) : null}
            </Flex>
            <Text content={teacher.TeacherEmailID} className="Karla fw6 m1" />
            <br />
            <Divider content={t("setting").teacher[0]} />
            {loadedInfo ? (
              <>
                <Text
                  content={
                    <>
                      <PollIcon outline className="pb2 pr2" />
                      <Text
                        content={t("setting").teacher[1]}
                        color="brand"
                        className="fw6"
                      />{" "}
                      - {loadedInfo.Total}
                    </>
                  }
                  className="Karla m1 tcap"
                />
                <Text
                  content={
                    <>
                      <AcceptIcon outline className="pb2 pr2" />
                      <Text
                        content={t("setting").teacher[2]}
                        color="brand"
                        className="fw6"
                      />{" "}
                      - {parseInt(loadedInfo.Presente)}
                    </>
                  }
                  className="Karla m1 tcap"
                />
                <Text
                  content={
                    <>
                      <CloseIcon outline className="pb2 pr2" />
                      <Text
                        content={t("setting").teacher[3]}
                        color="brand"
                        className="fw6"
                      />{" "}
                      - {parseInt(loadedInfo.Ausente)}
                    </>
                  }
                  className="Karla m1 tcap"
                />
                <Text
                  content={
                    <>
                      <ShiftActivityIcon outline className="pb2 pr2" />
                      <Text
                        content={t("setting").teacher[4]}
                        color="brand"
                        className="fw6"
                      />{" "}
                      - {parseInt(loadedInfo.Retardo)}
                    </>
                  }
                  className="Karla m1 tcap"
                />
                <Text
                  content={
                    <>
                      <SurveyIcon outline className="pb2 pr2" />
                      <Text
                        content={t("setting").teacher[5]}
                        color="brand"
                        className="fw6"
                      />{" "}
                      - {parseInt(loadedInfo.Justificado)}
                    </>
                  }
                  className="Karla m1 tcap"
                />
              </>
            ) : load2 ? (
              <Loader size="small" style={{ paddingTop: "50px" }} />
            ) : (
              <Text
                content={
                  <>
                    <ExclamationCircleIcon outline className="pb2 pr2" />
                    <Text content={t("setting").teacher[7]} className="fw6" />
                  </>
                }
                className="Karla m1 tcap"
                color="red"
              />
            )}
          </Flex>
        </Flex>
      }
      trigger={
        <Grid
          item
          xs={isMobileView ? 5 : 3}
          key={teacher.TeacherEmailID}
          style={{
            cursor: "pointer",
            boxShadow:
              " 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)",
            borderRadius: "8px",
            margin: "10px",
            alignSelf: "stretch",
            paddingLeft: 0,
          }}
          className="seg"
          onClick={() => getInfo(teacher.TeacherEmailID)}
          title={teacher.TeacherEmailID}
        >
          <Flex style={{ flexDirection: "column" }}>
            <Image
              src={
                teacher.UrlPhoto
                  ? user.slug === "?slug=acvpreprod" ||
                    props.user.slug === "?slug=acvapp"
                    ? teacher.UrlPhoto
                    : teacher.UrlPhoto + props.user.SASToken
                  : userSvg
              }
              style={{
                // borderRadius: "6px",
                // width: "100%",
                // height: "70px",
                // margin: "10px 0px 0px",
                borderRadius: "6px",
                width: "80%",
                height: "72%",
                margin: "3px 12px 0px",
              }}
            />
            <Flex wrap>
              <Text
                content={teacher.TeacherName}
                className="Karla fw6  tcap"
                style={{
                  margin: "10px 10px 0",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: " 100%",
                  textAlign: "center",
                }}
              />
              {teacher.role === "Admin" ? (
                <Text
                  color="brand"
                  content={
                    <>
                      <Status
                        style={{
                          backgroundColor: "#6264a7",
                          margin: "0 3px 3px 0",
                        }}
                      />
                      Admin
                    </>
                  }
                  className="Karla fw6 m1 tcap"
                />
              ) : teacher.role === "SuperAdmin" ? (
                <Text
                  color="Yellow"
                  content={
                    <>
                      <Status
                        style={{
                          backgroundColor: "#FFAA44",
                          margin: "0 3px 3px 0",
                        }}
                      />
                      Admin
                    </>
                  }
                  className="Karla fw6 tcap"
                  style={{
                    margin: " 0px 10px 0",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                />
              ) : (
                ""
              )}
            </Flex>
          </Flex>
        </Grid>
      }
    />
  );
}

export default TeacherCard;
