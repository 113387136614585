import React, { useEffect, useState } from "react";
import {
  Flex,
  Image,
  Text,
  Loader,
  Divider,
  Tooltip,
  Button,
  Dropdown,
  // Status
} from "@fluentui/react-northstar";

import {
  FlagIcon,
  PollIcon,
  AcceptIcon,
  CloseIcon,
  ShiftActivityIcon,
  SurveyIcon,
  RetryIcon,
  CallVideoIcon,
  VolumeIcon,
  ItalicIcon,
  BookmarkIcon,
  PresenterIcon,
  ChevronEndIcon,
  ChevronStartIcon,
} from "@fluentui/react-icons-northstar";
import axios from "axios";
import userSvg from "../../Assets/images/svg/user.svg";
import ProgressChart from "../../components/studentProgress/ProgressChart";
import { useTranslation } from "react-i18next";
import { Badge, Calendar, Card, Col, Row } from "antd";
import moment from "moment";

const url = process.env.REACT_APP_EP_URL;

const chartTypes = {
  attendance: "attendance",
  assignment: "assignment",
  event: "event",
  feedback: "feedback",
};

const student = {
  StudentEmailID: "student.teams@tangibleitdemo.com",
  StudentName: "Student",
  studPhoto: {
    Foto: null,
  },
};

const StudentData = (props) => {
  const { t } = useTranslation();
  const [loadedInfo, setLoadedInfo] = React.useState(null);
  const [customFieldInfo, setCustomFieldInfo] = React.useState(null);
  const [selectedChart, setSelectedChart] = React.useState(
    chartTypes.attendance
  );
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = React.useState(false);

  const student = loadedInfo ? loadedInfo.info.studentAccountInfo : {};

  const getInfo = (email) => {
    setLoader(true);
    setLoadedInfo(null);
    setCustomFieldInfo(null);
    setSelectedChart(chartTypes.attendance);
    axios
      .put(
        `${url}/api/admin/get-student-info/${email}${props.user.slug}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        const { assignment, feedback, event, info, chart } = res.data;
        setLoadedInfo({
          ...response,
          Ausente: response?.Ausente ?? 0,
          Justificado: response?.Justificado ?? 0,
          Presente: response?.Presente ?? 0,
          Retardo: response?.Retardo ?? 0,
          StudentEmailId: response?.StudentEmailId ?? "",
          StudentName: response?.StudentName ?? "",
          Total: response?.Total ?? 0,

          assignment: {
            total: assignment?.total ?? 0,
            reSubmit: assignment?.Resubmit ?? 0,
            completed: assignment?.Completed ?? 0,
            pending: assignment?.Pending ?? 0,
            gradesPending: assignment?.["Grades Pending"] ?? 0,
          },
          event: {
            registered: event?.registered ?? 0,
            bookmarked: event?.bookmarked ?? 0,
            organized: event?.organized ?? 0,
            total: event?.total ?? 0,
          },
          feedback: {
            total: feedback?.total ?? 0,
            poll: feedback?.poll ?? 0,
            text: feedback?.text ?? 0,
            audio: feedback?.audio ?? 0,
            video: feedback?.video ?? 0,
            mixed: feedback?.mixed ?? 0,
            any: feedback?.any ?? 0,
          },
          info: {
            ...info,
            subjects:
              info.subjects && Array.isArray(info.subjects)
                ? info.subjects
                : [],
            classNumber: info.classNumber ?? "",
            attendanceAverage: info.attendanceAverage ?? 0,
            assignmentAverage: info.assignmentAverage ?? 0,
          },
          chart,
        });
        setCustomFieldInfo(res.data.custom_field);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.message);
        console.log(err);
      });
  };

  const getRegisteredEvents = (email) => {
    setIsLoadingEvents(true);
    axios
      .get(
        `${url}/api/registrations/${email}${props.user.slug}&status=registered`,
        null,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        response.length ? setEvents(response) : events.length && setEvents([]);
        setIsLoadingEvents(false);
      })
      .catch((err) => {
        setIsLoadingEvents(false);
        console.log(err);
      });
  };

  const chartSelectHandler = (type) => {
    setSelectedChart(type);
  };

  const scrollButtonHandler = (isScrollToRight) => {
    document.getElementById("scroll-statictics-container").scrollBy({
      left: isScrollToRight ? 150 : -150,
      top: 0,
      behavior: "smooth",
    });
  };

  const getListData = (value) => {
    const dateString = value.format("YYYY-MM-DD");
    const findedEvents = events.filter(
      (eventData) =>
        moment(eventData.event.startDate).format("YYYY-MM-DD") === dateString
    );
    return findedEvents.length ? findedEvents : [];
  };

  const EventCell = ({ event }) => {
    const title = event.event.title || "";
    return (
      <li>
        <Tooltip
          content={title}
          trigger={
            <div
              style={{
                whiteSpace: "nowrap",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
                color: "#fff",
                backgroundColor: "#6264a7",
                padding: "0 5px",
                borderRadius: "5px",
                fontSize: "10px",
              }}
            >
              {title}
            </div>
          }
        />
      </li>
    );
  };

  const CalendarCell = ({ date, events }) => {
    return (
      <div
        style={{
          cursor: "default",
          margin: "0 4px",
          padding: "4px 0px 10px",
          border: 0,
          textAlign: "left",
          backgroundColor: events.length ? "#eae6ff" : "white",
          borderTop: events.length ? "2px solid #6264a7" : "2px solid #f0f0f0",
        }}
      >
        <div style={{ fontSize: 16, paddingLeft: 4 }}>{date.format("D")}</div>
        <div
          style={{
            minHeight: "45px",
          }}
        >
          <div>
            <ul
              style={{
                listStyle: "none",
                padding: "0 3px",
                margin: "4px 0",
              }}
            >
              {events.map((item) => (
                <EventCell key={item.id} event={item} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const cellRenderFunc = (date) => {
    const evs = getListData(date);
    evs.length && console.log("events founded", evs);
    return <CalendarCell date={date} events={evs} />;
  };

  useEffect(() => {
    if (props.assignedStudents[0]) {
      setSelectedStudent(props.assignedStudents[0]);
      getInfo(props.assignedStudents[0].key);
      getRegisteredEvents(props.assignedStudents[0].key);
    }
  }, [props.assignedStudents[0]]);

  return (loadedInfo === null && loader) || props.isLoadingStudents ? (
    <Flex
      fill
      hAlign="center"
      vAlign="center"
      column
      style={{ height: "400px" }}
    >
      <Loader size="medium" />
    </Flex>
  ) : (
    <Row gutter={[5, 5]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
        <Card className="student-progress-card">
          {loadedInfo ? (
            <Flex gap="gap.medium" className="student-data-wrapper">
              <Flex
                column
                className="student-image-info-wrapper"
                gap="gap.medium"
              >
                <Image
                  src={
                    student?.studPhoto?.Foto
                      ? props.user.slug === "?slug=acvpreprod" ||
                        props.user.slug === "?slug=acvapp"
                        ? student.studPhoto.Foto
                        : student.studPhoto.Foto + props.user.SASToken
                      : userSvg
                  }
                  className="m-img"
                />
                {loadedInfo ? (
                  <Flex
                    column
                    className="student-info-wrapper"
                    gap="gap.smaller"
                  >
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[0]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Tooltip
                        content={student.StudentName}
                        trigger={
                          <Text
                            content={student.StudentName}
                            className="Karla tcap field-value"
                          />
                        }
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[1]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Tooltip
                        content={student.StudentEmailID}
                        trigger={
                          <Text
                            content={student.StudentEmailID}
                            className="Karla tlow field-value"
                          />
                        }
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[2]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Text
                        content={
                          loadedInfo?.info?.classNumber?.join(', ') ?? ""
                        }
                        className="Karla tcap"
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[3]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Text
                        content={loadedInfo?.info?.attendanceAverage ?? ""}
                        className="Karla tcap"
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[4]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Text
                        content={loadedInfo?.info?.assignmentAverage ?? ""}
                        className="Karla tcap"
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[5]}:`}
                        className="Karla fw6 tcap"
                      />
                      <div className="Karla tcap">
                        {Array.isArray(loadedInfo?.info?.subjects) ? (
                          <Flex column className="subjects-wrapper">
                            {loadedInfo.info.subjects.map((item) => (
                              <span key={item}>{item}</span>
                            ))}
                          </Flex>
                        ) : (
                          <Text
                            content={`${t("setting").student.info[6]}`}
                            className="Karla tcap"
                          />
                        )}
                      </div>
                    </Flex>
                  </Flex>
                ) : (
                  ""
                )}

                {loadedInfo?.StudentData ? (
                  <Flex gap="gap.large" wrap>
                    <Flex column>
                      <Divider content={t("setting").student.attendance[1]} />
                      {customFieldInfo?.map((cf) => {
                        return (
                          <Text
                            content={
                              <>
                                <FlagIcon outline className="pb2 pr2" />
                                {/* <Status state={cf.IsEnable?"info":"unknown"} className="pb2 pr2"/> */}
                                <Text
                                  content={" " + cf.Name}
                                  color="brand"
                                  className="fw6"
                                  disabled={!cf.IsEnable}
                                />{" "}
                                -{" "}
                                {
                                  loadedInfo.StudentData[
                                    "CF_" + cf.CustomFieldID
                                  ]
                                }
                              </>
                            }
                            className="Karla m1 tcap"
                            key={cf.CustomFieldID}
                            disabled={!cf.IsEnable}
                          />
                        );
                      })}
                    </Flex>
                  </Flex>
                ) : null}
              </Flex>
              <Flex column gap="gap.smaller" className="info-chart-wrapper">
                <Flex vAlign="center">
                  <div className="scroll-button-wrapper">
                    <Button
                      className="scroll-button"
                      onClick={() => {
                        scrollButtonHandler(false);
                      }}
                    >
                      <ChevronStartIcon size="large" />
                    </Button>
                  </div>
                  <div
                    className="statictics-container"
                    id="scroll-statictics-container"
                  >
                    <Flex className="statictics-wrapper" gap="gap.large">
                      <Flex column>
                        <Divider
                          content={t("setting")?.student?.attendance[0]}
                          onClick={() =>
                            chartSelectHandler(chartTypes.attendance)
                          }
                          className={`selectable-option ${
                            selectedChart === chartTypes.attendance
                              ? "active-chart"
                              : ""
                          }`}
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <PollIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.attendance[2]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {loadedInfo.Total}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <AcceptIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.attendance[3]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.Presente)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <CloseIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.attendance[4]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.Ausente)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <ShiftActivityIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.attendance[5]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.Retardo)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <SurveyIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.attendance[6]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.Justificado)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                      </Flex>

                      <Flex column>
                        <Divider
                          content={t("setting").student.assignment[0]}
                          onClick={() =>
                            chartSelectHandler(chartTypes.assignment)
                          }
                          className={`selectable-option ${
                            selectedChart === chartTypes.assignment
                              ? "active-chart"
                              : ""
                          }`}
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <PollIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[1]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {loadedInfo.assignment.total}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <AcceptIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[2]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.assignment.completed)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <RetryIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[3]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.assignment.reSubmit)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <ShiftActivityIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[4]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.assignment.pending)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <SurveyIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[5]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.assignment.gradesPending)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                      </Flex>
                      <Flex column>
                        <Divider
                          content={t("setting").student.event[0]}
                          onClick={() => chartSelectHandler(chartTypes.event)}
                          className={`selectable-option ${
                            selectedChart === chartTypes.event
                              ? "active-chart"
                              : ""
                          }`}
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <AcceptIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.event[2]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.event.registered)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <BookmarkIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.event[3]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.event.bookmarked)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <PresenterIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.event[4]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.event.organized)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                      </Flex>

                      <Flex column>
                        <Divider
                          content={t("setting").student.feedback[0]}
                          onClick={() =>
                            chartSelectHandler(chartTypes.feedback)
                          }
                          className={`selectable-option ${
                            selectedChart === chartTypes.feedback
                              ? "active-chart"
                              : ""
                          }`}
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <PollIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[1]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {loadedInfo.feedback.total}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <PollIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[2]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.feedback.poll)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <ItalicIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[3]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.feedback.text)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <VolumeIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[4]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.feedback.audio)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <Flex gap="gap.small">
                              <CallVideoIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[5]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.feedback.video)}
                            </Flex>
                          }
                          className="Karla m1 tcap"
                        />
                      </Flex>
                    </Flex>
                  </div>
                  <div className="scroll-button-wrapper">
                    <Button
                      className="scroll-button"
                      onClick={() => {
                        scrollButtonHandler(true);
                      }}
                    >
                      <ChevronEndIcon size="large" />
                    </Button>
                  </div>
                </Flex>

                <ProgressChart
                  selectedChart={selectedChart}
                  data={loadedInfo.chart}
                  chartTypes={chartTypes}
                />
              </Flex>
            </Flex>
          ) : (
            loader && (
              <Flex
                fill
                hAlign="center"
                vAlign="center"
                column
                style={{ height: "100%" }}
              >
                <Loader size="medium" />
              </Flex>
            )
          )}
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
        <Flex gap="gap.smaller" column>
          <Card>
            <Dropdown
              disabled={false}
              fluid
              items={props.assignedStudents}
              placeholder={"Select Student"}
              value={selectedStudent}
              onChange={(e, { value }) => {
                setSelectedStudent(value);
                getInfo(value.key);
                getRegisteredEvents(value.key);
              }}
              noResultsMessage={"No results"}
              className="select-provider-dropdown"
            />
          </Card>
          <Card className="student-event-calender-wrapper">
            <Calendar onSelect={null} dateFullCellRender={cellRenderFunc} />
          </Card>
        </Flex>
      </Col>
    </Row>
  );
};

export default StudentData;
