import {
  CloseIcon,
  Dialog,
  Dropdown,
  Flex,
  SplitButton,
  Text,
  Tooltip,
  Button as FButton,
} from "@fluentui/react-northstar";
import { TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from "antd";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import moment from "moment";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { withTranslation } from "react-i18next";

import { NotificationAlert } from "../../Notification/Notification";
import { ResponsiveImage } from "../../blocks/Images";
import SelectorModal from "./SelectorModal";
import EventLogin from "./components/EventLogin";

import AttachmentOption from "../../../pages/Events/Components/AttachmentOption/AttachmentOption";
import { uploadFileToBlob } from "../../../utils/uploadFileToBlob";

import { PublicClientApplication } from "@azure/msal-browser";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { loginRequest, msalConfig } from "../../../App/msal-auth/msalAuth";
import EventMagicBarWrapper from "./components/EventMagicBarWrapper";

import GoogleLogin from "react-google-login";
import i18n from "../../../helper/i18n";

import "./event-form.css";
import StockImagesModel from "./components/StockImagesModel";
import { getFileFromImageUrl } from "../../../utils/file";
import { toast } from "react-toastify";
import { IconButton } from "@fluentui/react";

const { t } = i18n;

const { Dragger } = Upload;
const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class EventForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event: props.event || {},
      title: props.event ? "Edit event details" : "Create new event",
      buttonText: props.event ? "Update Changes" : "Schedule Event",
      selectedSpeakers: [],
      editorState: EditorState.createEmpty(),
      selectedCoverImage: null,
      selectedAttachments: [],
      imageUrl: null,
      // showError: {
      //   title: false,
      //   startDate: false,
      //   endDate: false,
      //   categories: false,
      //   tags: false,
      // },
      showError: false,
      categoryModal: false,
      openEventLoginModal: {
        type: null,
        isOpen: false,
        value: false,
      },
      isShowStockImageModel: false,
    };

    const description = props?.event?.description;
    if (!!description) {
      let contentBlocks = null;
      try {
        contentBlocks = convertFromHTML(JSON.parse(description));
      } catch (error) {
        contentBlocks = convertFromHTML("");
      }
      const contentState = ContentState.createFromBlockArray(
        contentBlocks.contentBlocks,
        contentBlocks.entityMap
      );

      this.state.editorState = EditorState.createWithContent(contentState);
    }
    if (!!props.teacherData?.length && !!props.event?.speakers) {
      this.state.selectedSpeakers = props.event?.speakers || [];
    }
  }

  componentDidMount() {
    const { teacherData, event } = this.props;
    if (teacherData?.length && event?.speakers.length) {
      this.setState({
        selectedSpeakers: event?.speakers,
      });
    }
    let updatedEvent = event ? event : {};

    updatedEvent.attachments = Array.isArray(updatedEvent?.attachments)
      ? updatedEvent.attachments
      : [
          { type: "link", info: "" },
          { type: "file", info: [] },
          // { type: "text", info: "" },
          // { type: "voice", info: "" },
          // { type: "video", info: "" },
        ];
    this.setState({
      event: updatedEvent,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { teacherData, event } = this.props;
    const { selectedSpeakers } = this.state;
    if (
      teacherData?.length &&
      !selectedSpeakers.length &&
      event?.speakers?.length
    ) {
      this.setSpeakers(prevState);
    }
  }

  setSpeakers = async (prevState) => {
    const { event } = this.state;
    const { teacherData } = this.props;
    let selectedSpeakers = [];
    if (Array.isArray(event.speakers) && event?.speakers?.length) {
      selectedSpeakers = await Promise.all(
        !!event.speakers &&
          event.speakers?.map((x) => {
            const [teacher] = teacherData.filter((y) => x == y.key);
            return teacher;
          })
      );
    }
    try {
      if (
        JSON.stringify(selectedSpeakers || "") !==
        JSON.stringify(prevState.selectedSpeakers || "")
      ) {
        this.setState({
          selectedSpeakers,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const { event } = this.state;
    if (name === "availability") {
      event[name] = value === 1 ? "PRIVATE" : "PUBLIC";
    } else if (name === "eventType") {
      event[name] = value === 1 ? "AUDIO" : "VIDEO_CONFERENCE";
    } else {
      event[name] = value;
    }
    this.setState({
      event,
    });
  };

  onSelectChange = (name, value) => {
    const { event } = this.state;
    event[name] = value;
    this.setState({
      event,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleMeeting = (event) => {
    this.setState({
      event,
    });
  };

  setAttachmentData = (data) => {
    const event = this.state.event;

    event.attachments = data;
    this.setState({
      event,
    });
  };

  onSuccess = (res) => {
    localStorage.setItem("GoogleMeetToken", JSON.stringify(res));

    const payload = { ...this.state.event };
    payload.isGoogleMeetMeeting = true;
    payload.isMSTeamMeeting = false;

    payload.availability = null;
    this.setState({ event: payload }, () => {
      this.submitHandler();
    });
  };

  onFailure = (res) => {
    if (typeof res?.details === "string") {
      if (res.error === "idpiframe_initialization_failed") {
        // NotificationAlert(eventTranslation.disabledCookiesWarning, "warning");
        NotificationAlert(
          "Cookies are not enabled. Please enable it if you want to create Google Meet.",
          "warning"
        );
      } else {
        NotificationAlert(res.details, "warning");
      }
    }

    const payload = { ...this.state.event };
    payload.isGoogleMeetMeeting = false;

    payload.availability = null;
    this.setState({ event: payload });
  };

  handleMSLogin = (value) => {
    const instance = new PublicClientApplication({ ...msalConfig, cache: {} });
    instance
      .loginPopup(loginRequest)
      .then((data) => {
        localStorage.setItem("MSTeamsToken", JSON.stringify(data));
        let payload = { ...this.state.event };
        payload.isGoogleMeetMeeting = false;
        payload.isMSTeamMeeting = true;

        if (!payload.eventType) {
          payload.eventType = "VIDEO_CONFERENCE";
        }
        if (!!payload.availability) {
          payload.availability = "PUBLIC";
        }

        this.setState({ event: payload }, () => {
          this.submitHandler();
        });
      })
      .catch((e) => {
        this.setState({
          event: { ...this.state.event, isMSTeamMeeting: false },
        });
      });
  };

  handleLogin = (type, value) => {
    if (value) {
      if (type === "isMSTeamMeeting") {
        const isMSLogin = this.props.user.authType === "microsoft";
        if (isMSLogin) {
          localStorage.setItem("MSTeamsToken", this.props.user.accessToken);
          let payload = { ...this.state.event };
          payload.isGoogleMeetMeeting = false;
          payload.isMSTeamMeeting = true;

          if (!payload.eventType) {
            payload.eventType = "VIDEO_CONFERENCE";
          }
          if (!!payload.availability) {
            payload.availability = "PUBLIC";
          }

          this.setState(
            {
              event: payload,
            },
            () => {
              this.submitHandler();
            }
          );
        } else {
          let token = localStorage.getItem("MSTeamsToken");
          if (token) {
            this.handleMSLogin();
          } else {
            this.setState({
              openEventLoginModal: {
                type: "isMSTeamMeeting",
                isOpen: true,
                value,
              },
            });
          }
        }
      } else if (type === "isGoogleMeetMeeting") {
        const isGoogleLogin = this.props.user.authType === "google";
        if (isGoogleLogin) {
          this.googleLoginHandler();
        } else {
          let tokenData = localStorage.getItem("GoogleMeetToken");
          let token = null;

          if (tokenData) {
            try {
              token = JSON.parse(tokenData)?.accessToken;
            } catch (error) {}
          }

          if (token) {
            this.googleLoginHandler();
          } else {
            this.setState({
              openEventLoginModal: {
                type: "isGoogleMeetMeeting",
                isOpen: true,
                value,
              },
            });
          }
        }
      }
    } else {
      let payload = { ...this.state.event };
      payload[type] = value;
      if (!payload.isGoogleMeetMeeting) {
        payload.availability = "PUBLIC";
      }

      this.setState(
        {
          event: payload,
        },
        () => {
          this.submitHandler();
        }
      );
    }
  };

  uploadEventAttachmentsToAzure = async (eventId) => {
    const attachments = this.state.event?.attachments;

    if (Array.isArray(attachments)) {
      const containerName = `event`;
      const storageAccountName = "acvstorageprod";

      let storagePath = `attachments/${eventId}/${new Date().toISOString()}`;

      const newAttachmentsPromise = Promise.all(
        attachments
          .map(async (media) => {
            if (media.type === "file") {
              let formattedUrl = null;
              if (media.info?.length) {
                let urlArray = [];
                for (const fileItem of media.info) {
                  if (
                    typeof fileItem === "string" &&
                    fileItem?.includes("blob.core.windows.net")
                  ) {
                    urlArray.push(fileItem);
                  } else {
                    let azureUrl = await uploadFileToBlob({
                      file: fileItem.file,
                      storagePath: `${storagePath}/file/${`${fileItem.file?.name}`}`,
                      containerName,
                      storageAccountName,
                    });
                    urlArray.push(azureUrl);
                  }
                }

                formattedUrl = urlArray.join(",");
              }
              return {
                ...media,
                type: "file",
                info: formattedUrl,
              };
            } else if (media.type === "video") {
              let formattedUrl = null;
              if (media.info) {
                if (
                  typeof media.info === "string" &&
                  media.info?.includes("blob.core.windows.net")
                ) {
                  formattedUrl = media.info;
                } else {
                  let azureUrl = await uploadFileToBlob({
                    file: await fetch(media.info).then((r) => r.blob()),
                    storagePath: `${storagePath}/video`,
                    containerName,
                    storageAccountName,
                  });
                  formattedUrl = azureUrl;
                }
              }
              return { ...media, type: "video", info: formattedUrl };
            } else if (media.type === "voice") {
              const formattedUrl = null;
              if (media.info) {
                if (
                  typeof media.info === "string" &&
                  media.info?.includes("blob.core.windows.net")
                ) {
                  formattedUrl = media.info;
                } else {
                  let azureUrl = await uploadFileToBlob({
                    file: await fetch(media.info).then((r) => r.blob()),
                    storagePath: `${storagePath}/audio`,
                    containerName,
                    storageAccountName,
                  });
                  formattedUrl = azureUrl;
                }
              }
              return { ...media, type: "voice", info: formattedUrl };
            } else if (media.type === "text") {
              let formmatedUrl = null;
              if (media.info?.length) {
                let azureUrl = await uploadFileToBlob({
                  file: new Blob([media.info], { type: "text/plain" }),
                  storagePath: `${storagePath}/note`,
                  containerName,
                  storageAccountName,
                });

                formmatedUrl = azureUrl;
              }
              return { ...media, type: "text", info: formmatedUrl };
            } else if (media.type === "link") {
              let formmatedUrl = null;
              if (media.info?.length) {
                let azureUrl = await uploadFileToBlob({
                  file: new Blob([media.info], { type: "text/plain" }),
                  storagePath: `${storagePath}/link`,
                  containerName,
                  storageAccountName,
                });
                formmatedUrl = azureUrl;
              }
              return { ...media, type: "link", info: formmatedUrl };
            }
            return null;
          })
          .filter((item) => item)
      );

      let newAttachments = [];

      newAttachments = await newAttachmentsPromise;

      return newAttachments;
    }
  };

  onSubmit = async (_, { status = "published" } = {}) => {
    const { event } = this.state;

    if (status === "published") {
      if (event.isMSTeamMeeting) {
        this.handleLogin("isMSTeamMeeting", true);
      } else if (event.isGoogleMeetMeeting) {
        this.handleLogin("isGoogleMeetMeeting", true);
      } else {
        this.submitHandler();
      }
    } else {
      this.submitHandler();
    }
  };

  submitHandler = async () => {
    const {
      event,
      selectedSpeakers,
      editorState,
      selectedCoverImage,
      selectedAttachments,
    } = this.state;

    const status = event.status;

    // const translation = t("events");
    // const eventTranslation = translation.body.eventForm;

    if (
      !event.title?.length ||
      !moment(event.startDate).isAfter(moment()) ||
      !(!!event.categories && event.categories?.length) ||
      !(!!event.tags && event.tags?.length) ||
      !(event.startDate && moment(event.startDate).isValid()) ||
      !(
        event.endDate &&
        moment(event.endDate).isValid() &&
        moment(event.endDate).isAfter(event.startDate)
      ) ||
      (event.registrationCloseDate &&
        moment(event.registrationCloseDate).isValid() &&
        (moment(event.registrationCloseDate).isSameOrBefore(moment()) ||
          moment(event.startDate).isBefore(event.registrationCloseDate)))
    ) {
      if (
        event.endDate &&
        moment(event.endDate).isValid() &&
        !moment(event.endDate).isAfter(event.startDate)
      ) {
        // NotificationAlert(eventTranslation.date.endDateValidation, "error");
        NotificationAlert("End Date must be less than Start Date", "error");
      }
      return;
    }
    event.speakers =
      !!selectedSpeakers &&
      selectedSpeakers.filter((x) => !!x).map((x) => x.key || x);
    event.description = JSON.stringify(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    if (selectedCoverImage) {
      event.coverImage = selectedCoverImage;
    } else {
      event.coverImage = event.coverImage;
    }

    event.status = status ? status : "published";

    // event.attachments = selectedAttachments;

    // if (!event.attachments) {
    //   event.files = null;
    // }

    this.props?.onSubmit(event, this.uploadEventAttachmentsToAzure);
  };

  googleLoginHandler = () => {
    const googleLoginBtn = document.querySelector(".google-login-btn");
    if (googleLoginBtn) {
      googleLoginBtn.click();
    }
  };

  render() {
    const {
      title,
      buttonText,
      event,
      selectedSpeakers,
      editorState,
      showError,
      openEventLoginModal,
    } = this.state;
    const {
      onDelete,
      onCancel,
      categories,
      tags,
      teacherData,
      isNew,
      t,
      isLoading,
    } = this.props;

    const notSelectedTeachersForSpeaker = teacherData?.length
      ? teacherData.filter((teacher) => {
          const isSelected = selectedSpeakers?.length
            ? selectedSpeakers.find(
                (speaker) => speaker?.key && speaker.key === teacher.key
              )
            : false;
          if (isSelected) {
            return false;
          } else {
            return true;
          }
        })
      : [];

    const modifiedTags = tags.map((tag) => {
      return { ...tag, key: tag.id, header: tag.title };
    });
    const modifiedCategories = categories.map((category) => {
      return { ...category, key: category.id, header: category.title };
    });

    const eventSelectedTags = event?.tags
      ? Array.isArray(event.tags)
        ? event.tags.map((tag) => {
            const modifiedTag = modifiedTags.find((tag_) => tag === tag_.id);
            return modifiedTag;
          })
        : modifiedTags.find((tag_) => event.tags === tag_.id)
      : [];

    const eventSelectedCategories = event?.categories
      ? Array.isArray(event.categories)
        ? event.categories.map((category) => {
            const modifiedCategory = modifiedCategories.find(
              (category_) => category === category_.id
            );
            return modifiedCategory;
          })
        : modifiedTags.find((category_) => event.categories === category_.id)
      : [];

    const translation = t("events");
    const eventTranslation = translation.body.eventForm;
    const eventAttachmentTranslation = translation.body.attachments;
    const commonTranslation = translation.common;

    const categoryChilds = [];
    const tagChilds = [];

    if (!!categories && categories.length) {
      for (let i = 0; i < categories.length; i++) {
        categoryChilds.push(
          <Option key={categories[i].id}>{categories[i].title}</Option>
        );
      }
    }
    if (!!tags && tags.length) {
      for (let i = 0; i < tags.length; i++) {
        tagChilds.push(<Option key={tags[i].id}>{tags[i].title}</Option>);
      }
    }

    const cprops = {
      // action: (file) => {
      //     this.setState({
      //         selectedCoverImage: file,
      //     });
      // },
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      customRequest: dummyRequest,
      beforeUpload: (file) => {
        const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg";

        if (!isJpgOrPng) {
          message.error(eventTranslation.imageFormatValidation);
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
          message.error(eventTranslation.imageSizeValidation);
        }
        this.setState({
          selectedCoverImage: file,
        });
        //return isJpgOrPng && isLt2M;
      },
      onRemove: (file) => {
        this.setState({ selectedCoverImage: null });
      },
      onChange: (info) => {
        if (info.file.status === "done") {
          getBase64(info.file.originFileObj, (url) => {
            this.setState({
              imageUrl: url,
            });
          });
        }
        if (info.file.status == "removed") {
          this.setState({
            imageUrl: null,
          });
        }
      },
      accept: ".png,.jpg,.jpeg",
      showPreviewIcon: true,
    };
    const dprops = {
      name: "file",
      multiple: true,
      FileList: (() => {
        if (this.state.selectedAttachments?.length) {
          let files = [];
          for (let file of this.state.selectedAttachments) {
            if (file.url?.includes("blob.core.windows.net")) {
              files.push(file);
            } else {
              try {
                files.push({ url: URL.createObjectURL(file.originFileObj) });
              } catch (error) {}
            }
          }
          return files;
        } else {
          return [];
        }
      })(),
      customRequest: dummyRequest,
      onChange: (files) => {
        this.setState({
          selectedAttachments: files.fileList || [],
        });
      },
      onRemove: (files) => {
        this.setState({
          selectedAttachments: files.fileList || [],
        });
      },
    };
    if (event.files != undefined && event?.files) {
      dprops.defaultFileList =
        event &&
        event.files.length > 0 &&
        event.files.map((url, index) => ({
          url: url + this.props.user?.SASToken,
          name: `${eventTranslation.attachment} ${index + 1}`,
        }));
    }

    const getButtonTranslation = (buttonText) => {
      switch (buttonText) {
        case "Edit event details":
          return eventTranslation.editEvent;
        case "Create new event":
          return eventTranslation.createEvent;
        case "Update Changes":
          return eventTranslation.updateChanges;
        case "Schedule Event":
          return eventTranslation.scheduleEvent;
        case "Draft Event":
          return eventTranslation.draftEvent;
        default:
          return buttonText;
      }
    };

    const handleAIGeneratedData = (data) => {
      const { event } = this.state;
      if (data) {
        event.title = data.title ?? "";

        const contentBlocks = convertFromHTML(data.description);
        const contentState = ContentState.createFromBlockArray(
          contentBlocks.contentBlocks,
          contentBlocks.entityMap
        );
        const newEditorState = EditorState.createWithContent(contentState);

        this.setState({
          event,
          editorState: newEditorState,
        });
      }
    };

    const onSaveClickHandler = ({ status = "published" }) => {
      this.setState(
        {
          showError: true,
          event: { ...this.state.event, status },
        },
        () => {
          this.onSubmit(eventTranslation, { status });
        }
      );
    };

    return (
      <div className="event-wrapper">
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          onSuccess={this.onSuccess}
          onFailure={this.onFailure}
          cookiePolicy={"single_host_origin"}
          scope="https://www.googleapis.com/auth/calendar.events"
          redirectUri={process.env.REACT_APP_URI}
          //Given google-login-btn class to access button through DOM
          // as ref is not working on this component.
          className="event-form google-login-btn"
        />

        <StockImagesModel
          onClose={() => {
            this.setState({ isShowStockImageModel: false });
          }}
          eventTitle={this.state.event.title}
          open={this.state.isShowStockImageModel}
          onSelectImage={async (image) => {
            const res = await getFileFromImageUrl(image.src, image.title);
            if (res.success) {
              this.setState({
                imageUrl: image.src,
                selectedCoverImage: res.file,
                isShowStockImageModel: false,
              });
            } else {
              toast.error("Please try again!");
            }
          }}
        />

        <Dialog
          cancelButton={translation.common.cancel}
          confirmButton={eventTranslation.signIn}
          open={openEventLoginModal.isOpen}
          onCancel={() => {
            this.setState({
              openEventLoginModal: { isOpen: false, type: null, value: false },
            });
          }}
          onConfirm={() => {
            this.setState({
              openEventLoginModal: { ...openEventLoginModal, isOpen: false },
            });
            if (openEventLoginModal.type === "isMSTeamMeeting") {
              this.handleMSLogin({ type: "isMSTeamMeeting", value: true });
            } else if (openEventLoginModal.type === "isGoogleMeetMeeting") {
              this.googleLoginHandler();
            }
          }}
          closeOnOutsideClick={false}
          content={
            <div>
              <Flex wrap>
                {openEventLoginModal.type === "isMSTeamMeeting"
                  ? eventTranslation.MSTeamsMeeting.signInConfirmation
                  : eventTranslation.googleMeetMeeting.signInConfirmation}
              </Flex>
            </div>
          }
          header={eventTranslation.signIn}
          headerAction={{
            icon: <CloseIcon />,
            title: "Close",
            onClick: () => {
              this.setState({
                openEventLoginModal: {
                  isOpen: false,
                  type: null,
                  value: false,
                },
              });
            },
          }}
        />

        <Flex gap="gap.medium" className="event-head">
          <Flex vAlign="center">
            <IconButton
              iconProps={{
                iconName: "ChromeBack",
              }}
              style={{ color: "rgb(98 100 167)" }}
              title={commonTranslation.back}
              ariaLabel={commonTranslation.back}
              onClick={onCancel}
            />
            <p className="event-title">{getButtonTranslation(title)}</p>
          </Flex>
          <div className="action-buttons">
            <Button
              disabled={isLoading}
              style={{
                cursor: "pointer",
              }}
              onClick={() => onCancel()}
            >
              {translation.common.cancel}
            </Button>
            {!isNew && (
              <Button
                style={{
                  cursor: "pointer",
                }}
                disabled={isLoading}
                onClick={() => onDelete(event)}
                type="danger"
              >
                {translation.common.delete}
              </Button>
            )}
            <SplitButton
              className="tour-my-event-schedule-event save-event-button"
              menu={[
                {
                  key: "Published",
                  status: "published",
                  content:
                    event?.status?.toLowerCase() === "draft"
                      ? getButtonTranslation("Schedule Event")
                      : getButtonTranslation(buttonText),
                },

                {
                  key: "Draft",
                  status: "draft",
                  content: (
                    <Tooltip
                      position="below"
                      trigger={
                        <Text>{getButtonTranslation("Draft Event")}</Text>
                      }
                      content={
                        event?.status === "PUBLISHED"
                          ? eventTranslation.draftEventValidation
                          : eventTranslation.draftEventDescription
                      }
                    />
                  ),
                  disabled: event?.status === "PUBLISHED",
                },
              ]}
              button={{
                content:
                  event?.status?.toLowerCase() === "draft"
                    ? getButtonTranslation("Schedule Event")
                    : getButtonTranslation(buttonText),
              }}
              primary
              onMainButtonClick={() => {
                onSaveClickHandler({ status: "published" });
              }}
              onMenuItemClick={(e, data) => {
                onSaveClickHandler({ status: data.status });
              }}
              disabled={isLoading}
            />
          </div>
        </Flex>
        <Row
          wrap={true}
          justify={"space-around"}
          gutter={[5, 5]}
          className="event-form-wrapper"
        >
          <Col
            xxl={10}
            md={10}
            sm={24}
            className="tour-create-event-left-details "
          >
            <Flex hAlign="end" className="event-chatgpt-wrapper">
              <EventMagicBarWrapper
                disabled={isLoading}
                {...this.props}
                handleAIGeneratedData={handleAIGeneratedData}
              />
            </Flex>
            <div style={{ marginBottom: "20px" }}>
              <Input
                placeholder={eventTranslation.title.text}
                name="title"
                value={event.title}
                onChange={this.onChange}
                status={showError ? (!event.title?.length ? "error" : "") : ""}
                disabled={isLoading}
              />
              {showError ? (
                !event.title?.length ? (
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {eventTranslation.title.validation}
                  </p>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            <Editor
              editorState={editorState}
              onEditorStateChange={this.onEditorStateChange}
              editorClassName="demo-editor input"
              placeholder={eventTranslation.eventDescription}
              readOnly={isLoading}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "list",
                  "textAlign",
                  "history",
                ],
                inline: { inDropdown: true },
                textAlign: { inDropdown: false },
                link: { inDropdown: false },
                history: { inDropdown: true },

                // list: { inDropdown: true },
                // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Row
                className="event-date-wrapper"
                wrap={true}
                justify="space-evenly"
                gutter={[16, 16]}
              >
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p style={{ alignSelf: "flex-start" }} className="sub-title">
                    {eventTranslation.date.startHeader}
                  </p>

                  <DateTimePicker
                    value={
                      !!event.startDate && moment(event.startDate).isValid()
                        ? moment(event.startDate)
                        : null
                    }
                    disablePast
                    disabled={isLoading}
                    onChange={(v) => {
                      this.setState((prevState) => {
                        let event = Object.assign({}, prevState.event);
                        if (!!v) {
                          event.startDate = moment(v.$d).format(
                            "YYYY-MM-DDTHH:mm:ssZ"
                          );
                          // event.startDate = v.toISOString();
                          if (
                            !event.endDate ||
                            moment(event.startDate).isAfter(event.endDate)
                          ) {
                            event.endDate = moment(v.add(1, "hour").$d).format(
                              "YYYY-MM-DDTHH:mm:ssZ"
                            );
                            // event.endDate = v.add(1, "days").toISOString();
                          }
                        } else {
                          event.startDate = null;
                        }
                        return { event };
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        disabled={isLoading}
                        {...params}
                        size="small"
                        variant="outlined"
                        value={
                          !!event.startDate && moment(event.startDate).isValid()
                            ? moment(event.startDate).format(
                                "DD/MM/YYYY | hh:mm A"
                              )
                            : eventTranslation.date.validation
                        }
                        inputProps={{ style: { fontSize: 13 } }}
                        error={
                          showError
                            ? !(
                                event.startDate &&
                                moment(event.startDate).isValid() &&
                                moment(event.startDate).isAfter(moment())
                              )
                            : false
                        }
                      />
                    )}
                  />
                  {showError ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        display: "flex",
                        width: "90%",
                        alignSelf: "flex-start",
                      }}
                    >
                      {!(event.startDate && moment(event.startDate).isValid())
                        ? eventTranslation.date.startDateValidation
                        : !moment(event.startDate).isAfter(moment())
                        ? eventTranslation.date.startTimeValidation
                        : ""}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p style={{ alignSelf: "flex-start" }} className="sub-title">
                    {eventTranslation.date.endHeader}
                  </p>

                  <DateTimePicker
                    value={
                      !!event.endDate && moment(event.endDate).isValid()
                        ? moment(event.endDate)
                        : null
                    }
                    disablePast
                    disabled={isLoading}
                    minDate={!!event.startDate && moment(event.startDate)}
                    onChange={(v) => {
                      this.setState((prevState) => {
                        let event = Object.assign({}, prevState.event);
                        if (!!v) {
                          event.endDate = moment(v.$d).format(
                            "YYYY-MM-DDTHH:mm:ssZ"
                          );
                          // event.endDate = v.toISOString();
                          if (
                            !event.startDate ||
                            moment(event.startDate).isAfter(event.endDate)
                          ) {
                            event.startDate = moment(
                              v.subtract(1, "hour").$d
                            ).format("YYYY-MM-DDTHH:mm:ssZ");
                          }
                        } else {
                          event.endDate = null;
                        }
                        return { event };
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled={isLoading}
                        value={
                          !!event.endDate && moment(event.endDate).isValid()
                            ? moment(event.endDate).format(
                                "DD/MM/YYYY | hh:mm A"
                              )
                            : eventTranslation.date.validation
                        }
                        inputProps={{ style: { fontSize: 13 } }}
                        error={
                          showError
                            ? !(
                                event.endDate &&
                                moment(event.endDate).isValid() &&
                                moment(event.endDate).isAfter(event.startDate)
                              )
                            : false
                        }
                      />
                    )}
                  />
                  {showError ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        display: "flex",
                        width: "90%",
                        alignSelf: "flex-start",
                      }}
                    >
                      {!(event.endDate && moment(event.endDate).isValid())
                        ? eventTranslation.date.selectEndDateValidation
                        : ""}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </LocalizationProvider>
            <p className="sub-title">{eventTranslation.categories.header}</p>

            <SelectorModal
              isLoading={isLoading}
              items={categories?.length ? categories : []}
              selectedItems={
                event.categories
                  ? Array.isArray(event.categories)
                    ? event.categories
                    : [event.categories]
                  : []
              }
              header={"Select Categories"}
              confirmButton={"Apply"}
              clearButton={"Clear"}
              onSubmit={(value) => {
                const { event } = this.state;
                event["categories"] = value;
                this.setState({
                  event,
                });
              }}
            >
              {({ open, setOpen }) => {
                return (
                  <div>
                    <Select
                      // showArrow={true}
                      disabled={isLoading}
                      showSearch={false}
                      name="categories"
                      mode="multiple"
                      allowClear={false}
                      style={{ width: "100%" }}
                      placeholder={eventTranslation.categories.placeholder}
                      defaultValue={[]}
                      onChange={(v) => this.onSelectChange("categories", v)}
                      onClick={() => {
                        !isLoading && setOpen(true);
                      }}
                      status={
                        showError
                          ? !(!!event.categories && event.categories)?.length
                            ? "error"
                            : ""
                          : ""
                      }
                      open={false}
                    ></Select>
                    <p
                      style={{
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: "13px",
                        marginBottom: "0",
                      }}
                    >
                      {eventTranslation.categoriesAddNote}
                    </p>
                    {showError ? (
                      !(!!event.categories && event.categories)?.length ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "13px",
                          }}
                        >
                          {eventTranslation.categories.validation}
                        </p>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                );
              }}
            </SelectorModal>

            {/* <Dropdown
              disabled={isLoading}
              search
              multiple
              fluid
              className="input"
              items={modifiedCategories}
              placeholder={eventTranslation.categories.placeholder}
              value={eventSelectedCategories}
              defaultValue={eventSelectedCategories}
              getA11ySelectionMessage={{
                onAdd: (item) => {
                  const { event } = this.state;
                  let oldCategories = event["categories"]
                    ? Array.isArray(event["categories"])
                      ? event["categories"]
                      : [event["categories"]]
                    : [];
                  event["categories"] = [...oldCategories, item.id];
                  this.setState({
                    event,
                  });
                },
                onRemove: (item) => {
                  const { event } = this.state;
                  let oldCategories = event["categories"]
                    ? Array.isArray(event["categories"])
                      ? event["categories"]
                      : [event["categories"]]
                    : [];
                  event["categories"] = oldCategories.filter(
                    (category) => category !== item.id
                  );
                  this.setState({
                    event,
                  });
                },
              }}
              noResultsMessage={"No results"}
            /> */}

            <p className="sub-title">{eventTranslation.tags.header}</p>
            <SelectorModal
              isLoading={isLoading}
              items={tags?.length ? tags : []}
              selectedItems={
                event.tags
                  ? Array.isArray(event.tags)
                    ? event.tags
                    : [event.tags]
                  : []
              }
              header={"Select Tags"}
              confirmButton={"Apply"}
              clearButton={"Clear"}
              onSubmit={(value) => {
                const { event } = this.state;
                event["tags"] = value;
                this.setState({
                  event,
                });
              }}
            >
              {({ open, setOpen }) => {
                return (
                  <div>
                    <Select
                      // showArrow={true}
                      disabled={isLoading}
                      showSearch={false}
                      name="tags"
                      mode="multiple"
                      allowClear={false}
                      style={{ width: "100%" }}
                      onChange={(v) => this.onSelectChange("tags", v)}
                      onClick={() => {
                        !isLoading && setOpen(true);
                      }}
                      placeholder={eventTranslation.tags.placeholder}
                      defaultValue={[]}
                      status={
                        showError
                          ? !(!!event.tags && event.tags)?.length
                            ? "error"
                            : ""
                          : ""
                      }
                      open={false}
                    ></Select>
                    <p
                      style={{
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: "13px",
                        marginBottom: 0,
                      }}
                    >
                      {eventTranslation.tagsAddNote}
                    </p>
                    {showError ? (
                      !(!!event.tags && event.tags)?.length ? (
                        <p style={{ color: "red", fontSize: "13px" }}>
                          {eventTranslation.tags.validation}
                        </p>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                );
              }}
            </SelectorModal>
            {/* <Dropdown
              disabled={isLoading}
              search
              multiple
              fluid
              className="input"
              items={modifiedTags}
              placeholder={eventTranslation.tags.placeholder}
              value={eventSelectedTags}
              defaultValue={eventSelectedTags}
              getA11ySelectionMessage={{
                onAdd: (item) => {
                  const { event } = this.state;
                  let oldTags = event["tags"]
                    ? Array.isArray(event["tags"])
                      ? event["tags"]
                      : [event["tags"]]
                    : [];

                  event["tags"] = [...oldTags, item.id];
                  this.setState({
                    event,
                  });
                },
                onRemove: (item) => {
                  const { event } = this.state;
                  let oldTags = event["tags"]
                    ? Array.isArray(event["tags"])
                      ? event["tags"]
                      : [event["tags"]]
                    : [];
                  event["tags"] = oldTags.filter((tag) => tag !== item.id);
                  this.setState({
                    event,
                  });
                },
              }}
              noResultsMessage={"No results"}
            /> */}
            {/* <div style={{ marginBottom: "20px" }}>
              <Select
                name="tags"
                mode="multiple"
                allowClear={false}
                style={{ width: "100%" }}
                placeholder={eventTranslation.tags.placeholder}
                defaultValue={(!!event.tags && event.tags) || []}
                onChange={(v) => this.onSelectChange("tags", v)}
                status={
                  showError
                    ? !(!!event.tags && event.tags)?.length
                      ? "error"
                      : ""
                    : ""
                }
              >
                {tagChilds}
              </Select>
              <p style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "13px" }}>
                Note: SuperAdmin can add tag from setting.
              </p>
              {showError ? (
                !(!!event.tags && event.tags)?.length ? (
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {eventTranslation.tags.validation}
                  </p>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div> */}
            {!event.isMSTeamMeeting && !event.isGoogleMeetMeeting ? (
              <>
                <p className="sub-title">{eventTranslation.location}</p>
                <Input
                  disabled={isLoading}
                  className="input"
                  name="location"
                  value={event.location}
                  onChange={this.onChange}
                />
              </>
            ) : (
              <></>
            )}

            <EventLogin
              event={event}
              user={this.props.user}
              handleMeeting={this.handleMeeting}
              eventTranslation={eventTranslation}
              isLoading={isLoading}
            />

            {!!event.isMSTeamMeeting && (
              <div style={{ marginBottom: 5 }}>
                <p className="sub-title">
                  {eventTranslation.MSTeamsMeeting.type}
                </p>
                <Radio.Group
                  name={"eventType"}
                  onChange={this.onChange}
                  disabled={isLoading}
                  value={
                    event.eventType === "AUDIO"
                      ? 1
                      : event.eventType === "VIDEO_CONFERENCE"
                      ? 2
                      : null
                  }
                >
                  <Radio value={1} disabled={isLoading}>
                    {eventTranslation.MSTeamsMeeting.audioOnly}
                  </Radio>
                  <Radio value={2} disabled={isLoading}>
                    {eventTranslation.MSTeamsMeeting.videoConference}
                  </Radio>
                </Radio.Group>
              </div>
            )}
            {/* {!event.isGoogleMeetMeeting && (
              <>
                <p className="sub-title">
                  {eventTranslation.availability.header}
                </p>
                <Radio.Group
                  name={"availability"}
                  style={{ marginBottom: 24 }}
                  onChange={this.onChange}
                  value={event.availability === "PRIVATE" ? 1 : 2}
                >
                  <Radio value={1}>
                    {eventTranslation.availability.private}
                  </Radio>
                  <Radio value={2}>
                    {eventTranslation.availability.public}
                  </Radio>
                </Radio.Group>
              </>
            )} */}
            <div className="max-seats-registration-section">
              <div className="limited-seats-section">
                <Flex gap="gap.medium" vAlign="center" wrap>
                  <div>
                    <Flex gap="gap.smaller">
                      <Switch
                        disabled={isLoading}
                        checked={event.isParentMeeting}
                        onChange={(value) => {
                          const updatedEvent = event;
                          updatedEvent.isParentMeeting = value;
                          this.setState({
                            event: updatedEvent,
                          });
                        }}
                        title={eventTranslation.parentMeeting}
                      />
                      <p className="title">{eventTranslation.parentMeeting}</p>
                    </Flex>
                  </div>
                  <div>
                    <Flex gap="gap.smaller">
                      <Switch
                        disabled={isLoading}
                        checked={event.isLimitedSeats}
                        onChange={(value) => {
                          const updatedEvent = event;
                          updatedEvent.isLimitedSeats = value;
                          updatedEvent.maxSeats = value ? 5 : 0;
                          this.setState({
                            event: updatedEvent,
                          });
                        }}
                        title={eventTranslation.limitedSeats}
                      />
                      <p className="title">{eventTranslation.limitedSeats}</p>
                    </Flex>
                  </div>

                  {!!event.isLimitedSeats && (
                    <div>
                      <InputNumber
                        readOnly={isLoading}
                        min={1}
                        disabled={!event.isLimitedSeats}
                        value={event.maxSeats}
                        onChange={(value) => {
                          const updatedEvent = event;
                          updatedEvent.maxSeats = value;
                          this.setState({
                            event: updatedEvent,
                          });
                        }}
                      />
                    </div>
                  )}
                </Flex>
              </div>
              <div className="registration-close-section">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <p
                    style={{ alignSelf: "flex-start", marginTop: 0 }}
                    className="sub-title"
                  >
                    {eventTranslation.registrationEnd.header}
                  </p>
                  <Flex column>
                    <Flex>
                      <DateTimePicker
                        disabled={isLoading}
                        clearable
                        value={
                          !!event.registrationCloseDate &&
                          moment(event.registrationCloseDate).isValid()
                            ? moment(event.registrationCloseDate)
                            : null
                        }
                        disablePast
                        onChange={(v) => {
                          this.setState((prevState) => {
                            let date = null;
                            if (!!v) {
                              date = moment(v.$d).format(
                                "YYYY-MM-DDTHH:mm:ssZ"
                              );
                            } else {
                              date = null;
                            }
                            return {
                              event: {
                                ...prevState.event,
                                registrationCloseDate: date,
                              },
                            };
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            disabled={isLoading}
                            {...params}
                            size="small"
                            variant="outlined"
                            value={
                              !!event.registrationCloseDate &&
                              moment(event.registrationCloseDate).isValid()
                                ? moment(event.registrationCloseDate).format(
                                    "DD/MM/YYYY | hh:mm A"
                                  )
                                : eventTranslation.registrationEnd.placeholder
                            }
                            inputProps={{ style: { fontSize: 13 } }}
                            error={
                              showError
                                ? event.registrationEnd &&
                                  moment(event.registrationEnd).isValid() &&
                                  moment(event.startDate).isSameOrBefore(
                                    moment()
                                  )
                                : false
                            }
                          />
                        )}
                      />
                      <Tooltip
                        content={translation.common.clear}
                        trigger={
                          <Button
                            disabled={isLoading}
                            type="text"
                            icon={<CloseIcon />}
                            onClick={() => {
                              this.setState((prevState) => {
                                return {
                                  event: {
                                    ...prevState.event,
                                    registrationCloseDate: null,
                                  },
                                };
                              });
                            }}
                          />
                        }
                      ></Tooltip>
                    </Flex>

                    {showError ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          marginBottom: 0,
                          maxWidth: "250px",
                        }}
                      >
                        {event.registrationCloseDate &&
                        moment(event.registrationCloseDate).isValid()
                          ? moment(event.registrationCloseDate).isSameOrBefore(
                              moment()
                            )
                            ? eventTranslation.registrationEnd.validation
                            : event.startDate &&
                              moment(event.startDate).isValid() &&
                              moment(event.startDate).isBefore(
                                event.registrationCloseDate
                              )
                            ? eventTranslation.registrationEnd
                                .beforStartDateValidation
                            : ""
                          : ""}
                      </p>
                    ) : (
                      ""
                    )}
                  </Flex>
                </LocalizationProvider>
              </div>
            </div>
          </Col>
          <Col
            xxl={10}
            md={10}
            sm={24}
            className="tour-create-event-right-details event-form-right-section"
          >
            <Dragger
              className="event-cover-image-dragger"
              {...cprops}
              disabled={isLoading}
            >
              {this.state.imageUrl ? (
                <img
                  src={this.state.imageUrl}
                  alt="avatar"
                  style={{
                    maxHeight: 190,
                    maxWidth: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : event.coverImage ? (
                <img
                  src={
                    event.coverImage +
                    this.props.user?.SASToken +
                    `&t=${new Date().getTime()}`
                  }
                  style={{
                    maxHeight: 190,
                    maxWidth: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <>
                  <p className="ant-upload-text">
                    {eventTranslation.coverImage}
                  </p>
                  <p className="ant-upload-hint">
                    {eventTranslation.dragAndDrop}
                  </p>
                </>
              )}
            </Dragger>
            <Divider plain orientation="center">
              OR
            </Divider>
            <Flex hAlign="center">
              <FButton
                disabled={isLoading}
                style={{
                  cursor: "pointer",
                  borderRadius: "4px",
                }}
                primary
                onClick={() => {
                  this.setState({ isShowStockImageModel: true });
                }}
              >
                Select from stock images
              </FButton>
            </Flex>

            <div className="event-name-image">
              <ResponsiveImage src="/Images/Events/speaker.svg" />
              <p className="sub-title"> {eventTranslation.speakers.header}</p>
            </div>
            {teacherData.length > 0 && (
              <Dropdown
                disabled={isLoading}
                search
                multiple
                fluid
                className="input"
                items={notSelectedTeachersForSpeaker}
                placeholder={eventTranslation.speakers.placeholder}
                value={selectedSpeakers}
                defaultValue={event.speakers}
                getA11ySelectionMessage={{
                  onAdd: (item) => {
                    selectedSpeakers.push(item);
                    this.setState({
                      selectedSpeakers,
                    });
                  },
                  onRemove: (item) => {
                    const updatedlist = selectedSpeakers.filter(
                      (s) => s?.key && s.key !== item.key
                    );
                    this.setState({
                      selectedSpeakers: Array.isArray(updatedlist)
                        ? updatedlist
                        : [],
                    });
                  },
                }}
                noResultsMessage={eventTranslation.speakers.noResult}
              />
            )}
            {/* <div className="event-name-image">
              <ResponsiveImage src="/Images/Events/link.svg" />
              <p className="sub-title">{eventTranslation.link.header}</p>
            </div>
            <Input
              className="input"
              style={{ marginBotton: 12 }}
              placeholder={eventTranslation.link.titlePlaceholder}
              name="linkTitle"
              value={event.linkTitle}
              onChange={this.onChange}
            />
            <Input
              className="input"
              placeholder={eventTranslation.link.urlPlaceholder}
              name="linkURL"
              value={event.linkURL}
              onChange={this.onChange}
            />
            <div className="event-name-image">
              <ResponsiveImage src="/Images/Events/attachment.svg" />
              <p className="sub-title">{eventTranslation.addAttachments}</p>
            </div>
            <Upload multiple={true} {...dprops}>
              <Button>{eventTranslation.chooseFiles}</Button>
            </Upload> */}

            <div>
              <p className="sub-title">
                {eventAttachmentTranslation.resources}
              </p>
              <AttachmentOption
                disabled={isLoading}
                eventData={this.state.event}
                setAttachmentData={this.setAttachmentData}
                isEditable={true}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(EventForm);
