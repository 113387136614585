import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dropdown, Flex, Input, Text } from "@fluentui/react-northstar";
import { Controller, useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";

import Footer from "../Footer";

const TitleText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
`;

const years = [
  { header: "Year 1", key: "1" },
  { header: "Year 2", key: "2" },
  { header: "Year 3", key: "3" },
  { header: "Year 4", key: "4" },
];

const fieldsOfStudy = [
  { header: "Engineering", key: "Engineering" },
  { header: "Commerce", key: "Commerce" },
  { header: "Arts", key: "Arts" },
  { header: "Management", key: "Management" },
  { header: "Marketing", key: "Marketing" },
];

const EducationInformation = ({
  currentStep,
  setCurrentStep,
  totalStep,
  data,
  setData,
  t,
}) => {
  const [initialValue, setInitialValue] = useState({
    fieldOfStudy: null,
    yearOfStudy: "",
  });
  const [searchValue, setSearchValue] = useState("");

  const translation = t("feedback").body.forms.educationInformation;

  const form = useForm({
    defaultValues: {
      fieldOfStudy: "",
      yearOfStudy: "",
    },
    // values: initialValue,
    shouldUseNativeValidation: false,
  });

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
    control,
  } = form;

  const { errors } = formState;

  useEffect(() => {
    setSearchValue(data.fieldOfStudy ?? "");
    reset({
      fieldOfStudy: data.fieldOfStudy ?? "",
      yearOfStudy: data.yearOfStudy ?? "",
    });
  }, [data, reset]);

  const nextHandler = (values) => {
    setCurrentStep((prev) => prev + 1);
    setData((prevData) => ({
      ...prevData,
      ...values,
      yearOfStudy: values.yearOfStudy ? values.yearOfStudy : null,
    }));
  };
  const backHandler = () => {
    setCurrentStep((prev) => prev - 1);
    setData((prevData) => ({ ...prevData, ...getValues() }));
  };

  const getA11ySelectionYear = {
    onAdd: (item) => {
      //   updateState("selectedyear", item.header);
      return `${item} ${translation.inputSelectionMessage}`;
    },
    onRemove: (item) => `${item} ${translation.inputSelectionRemoved}`,
    itemsCount: (count) => {
      return count;
    },
  };

  const getA11ySelectionField = {
    onAdd: (item) => {
      //   updateState("field", item.header);
      return `${item} ${translation.inputSelectionMessage}`;
    },
    onRemove: (item) => `${item} ${translation.inputSelectionRemoved}`,
    itemsCount: (count) => {
      return count;
    },
  };

  return (
    <div className="ms-Grid education-information-container">
      <div className="ms-Grid-row d-flex justify-content-center margin-device-wise">
        <div className="ms-Grid-col ms-sm12 ms-lg8 ms-xl6">
          <form onSubmit={handleSubmit(nextHandler)}>
            <div className="ms-Grid">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <TitleText>{translation.header}</TitleText>
                </div>
              </div>

              <div className="ms-Grid-row mt-3">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <Flex column>
                    <Text>{translation.studyFieldTitle}</Text>
                    <Controller
                      name="fieldOfStudy"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Dropdown
                            {...field}
                            className="field-study-dropdown"
                            defaultValue={fieldsOfStudy.find(
                              (item) => item.key === field.value
                            )}
                            value={fieldsOfStudy.find(
                              (item) => item.key === field.value
                            )}
                            fluid
                            search
                            items={fieldsOfStudy}
                            // placeholder="e.g Computer Science"
                            label={translation.studyFieldTitle}
                            noResultsMessage={translation.noMatchFound}
                            getA11ySelectionMessage={getA11ySelectionField}
                            //   defaultSearchQuery={state.field}
                            onChange={(e, data) => {
                              const { value } = data;
                              let selectedValue = null;
                              if (value) {
                                const { key } = value;
                                selectedValue = key;
                              } else {
                                selectedValue = null;
                              }
                              field.onChange(selectedValue);
                            }}
                            searchQuery={searchValue}
                            onSearchQueryChange={(e, { searchQuery }) => {
                              setSearchValue(searchQuery);
                            }}
                          />
                        );
                      }}
                    />
                  </Flex>
                </div>
              </div>

              <div className="ms-Grid-row mt-3">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <Flex column>
                    <Text className="">{translation.studyYearTitle}</Text>
                    <Controller
                      name="yearOfStudy"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          className="year-dropdown"
                          fluid
                          getA11ySelectionMessage={getA11ySelectionYear}
                          items={years}
                          onChange={(e, { value }) => {
                            let selectedValue = null;
                            if (value) {
                              const { key } = value;
                              selectedValue = key;
                            } else {
                              selectedValue = null;
                            }
                            field.onChange(parseInt(selectedValue));
                          }}
                        />
                      )}
                    />
                  </Flex>
                </div>
              </div>
              <div className="ms-Grid-row d-flex justify-content-end mt-4">
                <Footer
                  step={currentStep}
                  totalStep={totalStep}
                  backHandler={backHandler}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(EducationInformation);
