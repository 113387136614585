import { Flex } from "@fluentui/react-northstar";
import React from "react";
import { Result, Typography } from "antd";
const { Paragraph, Text } = Typography;
import "antd/dist/antd.css";
import { HighlightOffOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const SuccessConfirmation = (props) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const multipleParentsDialog = t("setting").parent.multipleParentsDialog;
  const successResult = multipleParentsDialog.successResult;
  const failedResult = multipleParentsDialog.failedResult;
  const common = multipleParentsDialog.common;
  return (
    <Flex hAlign="center" column style={{ minHeight: "250px" }}>
      <Result
        status={props?.response?.success ? "success" : "error"}
        title={
          props?.response?.success ? successResult.title : failedResult.title
        }
        subTitle={
          <Text
            strong
            style={{
              fontSize: 18,
              // color:"#6264a7"
            }}
          >
            {props?.response?.success &&
            props?.response?.data?.successCount !== 0
              ? props?.response?.data?.successCount +
                " " +
                successResult.subTitle
              : failedResult.subTitle}
          </Text>
        }
      >
        {props?.response?.data?.notFoundedStudents.length ||
        props?.response?.data?.studentsWithParents.length ? (
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                {common.errorTitle}
              </Text>
            </Paragraph>
            {props?.response?.data?.notFoundedStudents.length ? (
              <Paragraph>
                <Flex gap="gap.smaller">
                  <HighlightOffOutlined color="error" fontSize="small" />
                  {props?.response?.data?.notFoundedStudents.length +
                    " " +
                    common.studentNotFound}
                  <a
                    onClick={props.downloadNotFoundStudentsExcel}
                    style={{ marginLeft: 5 }}
                  >
                    {common.exportData} &gt;
                  </a>
                </Flex>
              </Paragraph>
            ) : null}
            {props?.response?.data?.studentsWithParents.length ? (
              <Paragraph>
                <Flex gap="gap.smaller">
                  <HighlightOffOutlined color="error" fontSize="small" />
                  {props?.response?.data?.studentsWithParents.length +
                    " " +
                    common.alreadyHaveParent}
                  <a
                    onClick={props.downloadStudentsWithParentsExcel}
                    style={{ marginLeft: 5 }}
                  >
                    {common.exportData} &gt;
                  </a>
                </Flex>
              </Paragraph>
            ) : null}
          </div>
        ) : null}
      </Result>
    </Flex>
  );
};

export default SuccessConfirmation;
