import { BlobServiceClient } from "@azure/storage-blob";

export const uploadFileToBlob = async (file, storagePath) => {
  const containerName = `feedback`;
  const storageAccountName = "acvstorageprod";
  const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN;

  if (!file) return [];
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  const containerClient = blobService.getContainerClient(containerName);

  const blobClient = containerClient.getBlockBlobClient(storagePath);

  const option = { blobHTTPHeaders: { blobContentType: file.type } };

  let response = await blobClient.uploadBrowserData(file, option);

  // getBlobsInContainer(containerClient);

  return response._response.request.url;
};
