import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import PersonalInformation from "./Forms/PersonalInformation";
import ContactInformation from "./Forms/ContactInformation";
import EducationInformation from "./Forms/EducationInformation";
import SkillInformation from "./Forms/SkillInformation";
import InterestInformation from "./Forms/InterestInformation";
import Profile from "./Profile";

import "./EditProfile.scss";

const ProfileWrapper = styled.div`
  padding: 30px 0;
`;

const baseUrl = process.env.REACT_APP_EP_URL;

const EditProfile = (props) => {
  const {
    refreshUserProfileData,
    userProfileData,
    setIsEditProfile,
    isEditProfile,
    user,
    setIsRefreshData,
  } = props;

  const headers = {
    Authorization: "Bearer " + user.accessToken,
  };

  const [data, setData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [skills, setSkills] = useState([]);
  const [interests, setInterests] = useState([]);

  const getSkills = async () => {
    try {
      const { slug } = user;
      const { data: responseData } = await axios.get(
        `${baseUrl}/api/profile/get-skills${slug}`,
        {
          headers,
        }
      );
      if (responseData?.data?.length) {
        setSkills(responseData.data);
      } else {
        setSkills([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInterests = async () => {
    try {
      const { slug } = user;
      const { data: responseData } = await axios.get(
        `${baseUrl}/api/profile/get-interests${slug}`,
        {
          headers,
        }
      );
      if (responseData?.data?.length) {
        setInterests(responseData.data);
      } else {
        setInterests([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSkills();
    getInterests();
  }, [user]);

  const getCurrentStepForm = (step) => {
    const role = user.role?.toLowerCase();

    const getTotalSteps = (role) => {
      switch (role) {
        case "student":
          return 6;
        case "teacher":
          return 5;
        case "admin":
        case "superadmin":
          return 3;
        default:
          return 6;
      }
    };

    const values = {
      data,
      setData,
      currentStep,
      setCurrentStep,
      totalStep: getTotalSteps(role),
      setIsEditProfile,
      isEditProfile,
      user,
      skills,
      interests,
      setIsRefreshData,
      refreshUserProfileData,
    };

    switch (role) {
      case "student":
        switch (step) {
          case 1:
            return <PersonalInformation {...values} />;
          case 2:
            return <ContactInformation {...values} />;
          case 3:
            return <EducationInformation {...values} />;
          case 4:
            return <SkillInformation {...values} />;
          case 5:
            return <InterestInformation {...values} />;
          case 6:
            return <Profile {...values} />;
          default:
            return <PersonalInformation {...values} />;
        }
      case "teacher":
        switch (step) {
          case 1:
            return <PersonalInformation {...values} />;
          case 2:
            return <ContactInformation {...values} />;
          case 3:
            return <SkillInformation {...values} />;
          case 4:
            return <InterestInformation {...values} />;
          case 5:
            return <Profile {...values} />;
          default:
            return <PersonalInformation {...values} />;
        }
      case "admin":
      case "superadmin":
        switch (step) {
          case 1:
            return <PersonalInformation {...values} />;
          case 2:
            return <ContactInformation {...values} />;
          case 3:
            return <Profile {...values} />;
          default:
            return <PersonalInformation {...values} />;
        }
      default:
        switch (step) {
          case 1:
            return <PersonalInformation {...values} />;
          case 2:
            return <ContactInformation {...values} />;
          case 3:
            return <EducationInformation {...values} />;
          case 4:
            return <SkillInformation {...values} />;
          case 5:
            return <InterestInformation {...values} />;
          case 6:
            return <Profile {...values} />;
          default:
            return <PersonalInformation {...values} />;
        }
    }
  };

  useEffect(() => {
    setData(userProfileData);
  }, [userProfileData]);

  return (
    <ProfileWrapper className="edit-profile-wrapper">
      {getCurrentStepForm(currentStep)}
    </ProfileWrapper>
  );
};

export default EditProfile;
