import React from "react";
import ReactExport from "react-export-excel";

import { Button, DownloadIcon } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";

import {
  bulkEventImportData,
  bulkEventImportTemplateData,
  lessionData,
  lessionDayData,
  membersData,
  profileData,
} from "./TempData";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const LessonTemplate = () => {
  const { t } = useTranslation();
  return (
    <ExcelFile
      filename="Lesson-Data-Template"
      element={
        <Button
          icon={<DownloadIcon />}
          content={t("startUp").controls.action[0]}
          title={t("startUp").controls.action[0]}
        />
      }
    >
      <ExcelSheet data={lessionData} name="Lesson Data">
        <ExcelColumn label={"LessonDate"} value={(col) => col.LessonDate} />
        <ExcelColumn label={"StartTime"} value={(col) => col.StartTime} />
        <ExcelColumn label={"EndTime"} value={(col) => col.EndTime} />
        {/* <ExcelColumn label={"ClassName"} value={(col) => col.ClassName} /> */}
        <ExcelColumn label={"SubjectType"} value={(col) => col.SubjectType} />
        <ExcelColumn label={"SubjectName"} value={(col) => col.SubjectName} />
        <ExcelColumn label={"SubjectCode"} value={(col) => col.SubjectCode} />
        <ExcelColumn label={"Title"} value={(col) => col.Title} />
        <ExcelColumn
          label={"TeacherEmailAlias"}
          value={(col) => col.TeacherEmailAlias}
        />
        <ExcelColumn
          label={"FacultadMateria"}
          value={(col) => col.FacultadMateria}
        />
        <ExcelColumn label={"Tema"} value={(col) => col.Tema} />
      </ExcelSheet>
    </ExcelFile>
  );
};

const LessonDayTemplate = () => {
  const { t } = useTranslation();
  return (
    <ExcelFile
      filename="Day-Wise-Lessons-Template"
      element={
        <Button
          icon={<DownloadIcon />}
          content={t("startUp").controls.action[0]}
          title={t("startUp").controls.action[0]}
        />
      }
    >
      <ExcelSheet data={lessionDayData} name="Lesson Data">
        {/* <ExcelColumn label={"StartDate"} value={(col) => col.StartDate} /> */}
        {/* <ExcelColumn label={"EndDate"} value={(col) => col.EndDate} /> */}
        {/* <ExcelColumn label={"ClassName"} value={(col) => col.ClassName} /> */}
        <ExcelColumn label={"SubjectType"} value={(col) => col.SubjectType} />
        <ExcelColumn label={"SubjectName"} value={(col) => col.SubjectName} />
        <ExcelColumn label={"SubjectCode"} value={(col) => col.SubjectCode} />
        <ExcelColumn
          label={"TeacherEmailAlias"}
          value={(col) => col.TeacherEmailAlias}
        />
        <ExcelColumn label={"StartTime"} value={(col) => col.StartTime} />
        <ExcelColumn label={"EndTime"} value={(col) => col.EndTime} />
        <ExcelColumn label={"Sunday"} value={(col) => col.Sunday} />
        <ExcelColumn label={"Monday"} value={(col) => col.Monday} />
        <ExcelColumn label={"TuesDay"} value={(col) => col.TuesDay} />
        <ExcelColumn label={"Wednesday"} value={(col) => col.Wednesday} />
        <ExcelColumn label={"Thursday"} value={(col) => col.Thursday} />
        <ExcelColumn label={"Friday"} value={(col) => col.Friday} />
        <ExcelColumn label={"Saturday"} value={(col) => col.Saturday} />
        {/* <ExcelColumn label={"ClassType"} value={(col) => col.ClassType} />
        <ExcelColumn
          label={"FacultadMateria"}
          value={(col) => col.FacultadMateria}
        /> */}
      </ExcelSheet>
    </ExcelFile>
  );
};

const MembersTemplate = ({ lessonExcelData = [] }) => {
  const { t } = useTranslation();
  const clonedLessonData = lessonExcelData.flatMap((lessonElement) => [
    lessonElement,
  ]);

  const clonedMemberData = membersData.flatMap((memberElement) => [
    memberElement,
  ]);

  const renderingLessonData =
    clonedLessonData.length > 0
      ? clonedLessonData.map((member, index) => {
          if (index < 4) {
            clonedMemberData[index] = {
              ...clonedMemberData[index],
              ...member,
            };
            return clonedMemberData[index];
          } else {
            return member;
          }
        })
      : clonedMemberData;
  return (
    <ExcelFile
      filename="Members-Data-Template"
      element={
        <Button
          icon={<DownloadIcon />}
          content={t("startUp").controls.action[0]}
          title={t("startUp").controls.action[0]}
        />
      }
    >
      <ExcelSheet data={renderingLessonData} name="Members Data">
        {/* <ExcelColumn label={"Teams_Name"} value={(col) => col.Teams_Name} /> */}
        <ExcelColumn label={"SubjectType"} value={(col) => col.SubjectType} />
        <ExcelColumn label={"SubjectName"} value={(col) => col.SubjectName} />
        <ExcelColumn label={"SubjectCode"} value={(col) => col.SubjectCode} />
        <ExcelColumn label={"Member_Name"} value={(col) => col.Member_Name} />
        <ExcelColumn label={"Member_Mail"} value={(col) => col.Member_Mail} />
        <ExcelColumn label={"Role"} value={(col) => col.Role} />
        <ExcelColumn label={"Provider"} value={(col) => col.Provider} />
      </ExcelSheet>
    </ExcelFile>
  );
};

const ProfileTemplate = () => {
  const { t } = useTranslation();
  return (
    <ExcelFile
      filename="Profile-Data-Template"
      element={
        <Button
          icon={<DownloadIcon />}
          content={t("startUp").controls.action[0]}
          title={t("startUp").controls.action[0]}
        />
      }
    >
      <ExcelSheet data={profileData} name="Skill Data">
        <ExcelColumn label={"Title"} value={(col) => col.title} />
        <ExcelColumn label={"Category"} value={(col) => col.category} />
      </ExcelSheet>
    </ExcelFile>
  );
};

const BulkEventTemplate = ({ tags, categories }) => {
  const { t } = useTranslation();
  return (
    <ExcelFile
      filename="Event-Import-Template"
      element={
        <Button
          icon={<DownloadIcon />}
          content={t("startUp").controls.action[0]}
          title={t("startUp").controls.action[0]}
        />
      }
    >
      <ExcelSheet data={bulkEventImportData} name="Events">
        <ExcelColumn label={"title"} value={(col) => col.title} />
        <ExcelColumn label={"description"} value={(col) => col.description} />
        <ExcelColumn label={"startDate"} value={(col) => col.startDate} />
        <ExcelColumn label={"endDate"} value={(col) => col.endDate} />
        <ExcelColumn label={"categories"} value={(col) => col.categories} />
        <ExcelColumn label={"tags"} value={(col) => col.tags} />
        <ExcelColumn label={"speakers"} value={(col) => col.speakers} />
        <ExcelColumn label={"linkTitle"} value={(col) => col.linkTitle} />
        <ExcelColumn label={"linkURL"} value={(col) => col.linkURL} />
        <ExcelColumn label={"location"} value={(col) => col.location} />
        <ExcelColumn label={"meetingType"} value={(col) => col.meetingType} />
      </ExcelSheet>
      <ExcelSheet data={tags} name="Tags">
        <ExcelColumn label={"Title"} value={(col) => col.title} />
      </ExcelSheet>
      <ExcelSheet data={categories} name="Categories">
        <ExcelColumn label={"Title"} value={(col) => col.title} />
      </ExcelSheet>
      <ExcelSheet data={bulkEventImportTemplateData} name="Template">
        <ExcelColumn label={"title"} value={(col) => col.title} />
        <ExcelColumn label={"description"} value={(col) => col.description} />
        <ExcelColumn label={"startDate"} value={(col) => col.startDate} />
        <ExcelColumn label={"endDate"} value={(col) => col.endDate} />
        <ExcelColumn label={"categories"} value={(col) => col.categories} />
        <ExcelColumn label={"tags"} value={(col) => col.tags} />
        <ExcelColumn label={"speakers"} value={(col) => col.speakers} />
        <ExcelColumn label={"linkTitle"} value={(col) => col.linkTitle} />
        <ExcelColumn label={"linkURL"} value={(col) => col.linkURL} />
        <ExcelColumn label={"location"} value={(col) => col.location} />
        <ExcelColumn label={"meetingType"} value={(col) => col.meetingType} />
      </ExcelSheet>
    </ExcelFile>
  );
};

export {
  BulkEventTemplate,
  LessonDayTemplate,
  LessonTemplate,
  MembersTemplate,
  ProfileTemplate,
};
