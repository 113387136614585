import React from "react";

import {
  Flex,
  Segment,
  Image,
  Text,
  Loader,
  Status,
  Dialog,
  Input,
  Dropdown,
  Divider,
  Button,
} from "@fluentui/react-northstar";

import {
  PollIcon,
  AcceptIcon,
  CloseIcon,
  ShiftActivityIcon,
  SurveyIcon,
  ExclamationCircleIcon,
  SearchIcon,
  SyncIcon,
} from "@fluentui/react-icons-northstar";

import axios from "axios";

import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import userSvg from "../../../../Assets/images/svg/user.svg";

import "../StudentData/StudentData.css";
import "./TeacherData.css";
import TabMobileViewMenu from "../StudentData/TabMobileViewMenu";
import InfiniteScroll from "react-infinite-scroller";
import { Grid } from "@mui/material";
import TeacherCard from "./TeacherCard";

const url = process.env.REACT_APP_EP_URL;

const inputItems = [20, 50, 100];
let d = new Date();

const TeacherData = (props) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState({});
  const [load, setLoad] = React.useState(true);
  const [load2, setLoad2] = React.useState(false);
  const [accDlg, setAccDlg] = React.useState(false);
  const [loadedInfo, setLoadedInfo] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(21);
  const [isScrollEnabled, setIsScrollEnabled] = React.useState(true);
  const [lessonDate, setLessonDate] = React.useState([
    new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), 0, 0, 0, 0),
    new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0),
  ]);

  React.useEffect(() => {
    getAllTeachers();
  }, []);

  const getAllTeachers = (page = 1, limit = 21) => {
    axios
      .get(
        `${url}/api/admin/get-teachers-info${props.user.slug}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        setPage(page);
        const response = res.data.result.rows;
        const pageCount = Math.ceil(res.data.result.count / limit);
        setIsScrollEnabled(page < pageCount);

        setState((prevState) => ({
          teachers:
            page === 1 ? response : [...prevState.teachers, ...response],
          pageCount,
        }));

        setLoad(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
  };

  const getInfo = (email) => {
    let d = new Date();

    setLoad2(true);
    setLoadedInfo(null);
    props.user.slug === "?slug=acvpreprod" || props.user.slug === "?slug=acvapp"
      ? setLoad2(false)
      : axios
          .post(
            `${url}/api/chart/get-chart-by-AttendanceTakenBy${props.user.slug}`,
            {
              TeacherName: email ? email : null,
              LessonDate: lessonDate,
              // CF_1: null,
              // CF_2: null,
              // CF_3: null,
              // CF_4: null,
              // CF_5: null,
            },

            {
              headers: {
                Authorization: "Bearer " + props.user.accessToken,
              },
            }
          )
          .then((res) => {
            const response = res.data?.result[0] || null;
            setLoad2(false);
            setLoadedInfo(response);
          })
          .catch((err) => {
            setLoad2(false);
            toast.error(err?.response?.data?.message);
            console.log(err);
            if (err.response?.status === 401) {
              setAccDlg(true);
            }
          });
  };

  const onSearchTeacherHandler = async (e) => {
    const value = e.target.value || "";
    axios
      .get(
        `${url}/api/admin/get-teachers-info${props.user.slug}&s=${value}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const { rows, count } = res.data.result;
        const pageCount = Math.ceil(count / limit);
        setState({
          teachers: rows,
          pageCount: pageCount,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
    const page = data.selected + 1;
    getAllTeachers(page);
  };

  const changeRoleHandler = (email) => {
    return (
      <Button
        icon={<SyncIcon />}
        content={t("setting").teacher[6]}
        iconPosition="before"
        disabled={
          props.user.slug === "?slug=acvpreprod" ||
          props.user.slug === "?slug=acvapp"
        }
        onClick={() => {
          axios
            .post(
              `${url}/api/admin/change-teacher-role-to-admin${props.user.slug}`,
              {
                email: email,
              },
              {
                headers: {
                  Authorization: "Bearer " + props.user.accessToken,
                },
              }
            )
            .then((res) => {
              getAllTeachers(page);
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
              console.log(err);
              if (err.response?.status === 401) {
                setAccDlg(true);
              }
            });
        }}
      />
    );
  };

  return load ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <div className="teacherData">
      <TabMobileViewMenu
        isTabletOrMobileView={props.isTabletOrMobileView}
        onSearchStudentHandler={onSearchTeacherHandler}
      />
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton="Refresh Now"
        content={
          <p className="refreshMsg">
            No big deal — but we need to refresh to show your content.
          </p>
        }
        header="Authorization expire"
      />

      {/* {state.teachers.length > 0 ? ( */}
      <>
        {!props.isTabletOrMobileView && (
          <>
            <Flex
              vAlign="center"
              hAlign="center"
              style={{ justifyContent: "space-around" }}
              wrap
            >
              <Input
                icon={<SearchIcon />}
                clearable
                placeholder={t("setting").init[2]}
                onChange={onSearchTeacherHandler}
              />
              <ReactPaginate
                previousLabel={t("setting").init[5]}
                nextLabel={t("setting").init[4]}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
              <Dropdown
                items={inputItems}
                placeholder={t("setting").init[6]}
                checkable
                getA11ySelectionMessage={{
                  onAdd: (item) => {
                    setLimit(item);
                    getAllTeachers(page, item);
                  },
                }}
                className="SmallSelector"
              />
            </Flex>
            <Divider />
          </>
        )}
      </>
      {/* ) : (
        <p className="btsp text-center" style={{ margin: "50px auto" }}>
          Teachers are not available!
        </p>
      )} */}
      <Flex
        className="t-teacherData"
        gap="gap.small"
        vAlign="center"
        hAlign="center"
        wrap
        style={{
          overflowY: "scroll",
          position: "relative",
          height: props.isTabletOrMobileView
            ? "calc(100vh - 150px)"
            : "calc(100vh - 290px)",
          // height: state.teachers.length < 5 && "306px",
        }}
      >
        {props.isTabletOrMobileView ? (
          <>
            <InfiniteScroll
              pageStart={1}
              loadMore={() => {
                getAllTeachers(page + 1);
              }}
              hasMore={isScrollEnabled}
              loader={<Loader key={0} size="medium" />}
              useWindow={false}
              style={{ width: "100%" }}
            >
              <Grid
                container
                spacing={1}
                style={{ justifyContent: "center", marginTop: "0px" }}
              >
                {state.teachers.length > 0 ? (
                  state.teachers?.map((teacher) => {
                    return (
                      <TeacherCard
                        teacher={teacher}
                        loadedInfo={loadedInfo}
                        userSvg={userSvg}
                        props={props}
                        t={t}
                        getInfo={getInfo}
                        isMobileView={props.isMobileView}
                        changeRoleHandler={changeRoleHandler}
                        load2={load2}
                        user={props.user}
                      />
                    );
                  })
                ) : (
                  <p
                    className="btsp text-center"
                    style={{ margin: "50px auto" }}
                  >
                    Teachers are not available!
                  </p>
                )}
              </Grid>
            </InfiniteScroll>
            {!isScrollEnabled && (
              <p className="btsp text-center" style={{ margin: "50px auto" }}>
                All teachers are loaded!
              </p>
            )}
          </>
        ) : state.teachers.length > 0 ? (
          state.teachers?.map((teacher, index) => {
            // console.log("::::::", teacher);
            return (
              // index === 1 && (
              <>
                <Dialog
                  key={teacher.TeacherEmailID}
                  confirmButton={t("setting").init[3]}
                  content={
                    <Flex wrap>
                      <Image
                        src={
                          teacher.UrlPhoto
                            ? props.user.slug === "?slug=acvpreprod" ||
                              props.user.slug === "?slug=acvapp"
                              ? teacher.UrlPhoto
                              : teacher.UrlPhoto + props.user.SASToken
                            : userSvg
                        }
                        className="d-img"
                      />
                      <Flex
                        style={{
                          flexDirection: "column",
                          marginLeft: "10px",
                        }}
                      >
                        <Flex wrap>
                          <Text
                            content={teacher.TeacherName}
                            className="Karla fw6 m1 tcap"
                          />
                          {teacher.role == "Admin" ? (
                            <>
                              <Text
                                color="brand"
                                content={
                                  <>
                                    <Status
                                      style={{
                                        backgroundColor: "#6264a7",
                                        margin: "0 3px 3px 0",
                                      }}
                                    />
                                    Admin
                                  </>
                                }
                                className="Karla fw6 m1 tcap"
                              />
                              {props.user.actualRole === "SuperAdmin"
                                ? changeRoleHandler(teacher.TeacherEmailID)
                                : null}
                            </>
                          ) : teacher.role === "SuperAdmin" ? (
                            <Text
                              color="Yellow"
                              content={
                                <>
                                  <Status
                                    style={{
                                      backgroundColor: "#FFAA44",
                                      margin: "0 3px 3px 0",
                                    }}
                                  />
                                  SuperAdmin
                                </>
                              }
                              className="Karla fw6 m1 tcap"
                            />
                          ) : props.user.actualRole === "SuperAdmin" ? (
                            changeRoleHandler(teacher.TeacherEmailID)
                          ) : null}
                        </Flex>
                        <Text
                          content={teacher.TeacherEmailID}
                          className="Karla fw6 m1"
                        />
                        <br />
                        <Divider content={t("setting").teacher[0]} />
                        {loadedInfo ? (
                          <>
                            <Text
                              content={
                                <>
                                  <PollIcon outline className="pb2 pr2" />
                                  <Text
                                    content={t("setting").teacher[1]}
                                    color="brand"
                                    className="fw6"
                                  />{" "}
                                  - {loadedInfo.Total}
                                </>
                              }
                              className="Karla m1 tcap"
                            />
                            <Text
                              content={
                                <>
                                  <AcceptIcon outline className="pb2 pr2" />
                                  <Text
                                    content={t("setting").teacher[2]}
                                    color="brand"
                                    className="fw6"
                                  />{" "}
                                  - {parseInt(loadedInfo.Presente)}
                                </>
                              }
                              className="Karla m1 tcap"
                            />
                            <Text
                              content={
                                <>
                                  <CloseIcon outline className="pb2 pr2" />
                                  <Text
                                    content={t("setting").teacher[3]}
                                    color="brand"
                                    className="fw6"
                                  />{" "}
                                  - {parseInt(loadedInfo.Ausente)}
                                </>
                              }
                              className="Karla m1 tcap"
                            />
                            <Text
                              content={
                                <>
                                  <ShiftActivityIcon
                                    outline
                                    className="pb2 pr2"
                                  />
                                  <Text
                                    content={t("setting").teacher[4]}
                                    color="brand"
                                    className="fw6"
                                  />{" "}
                                  - {parseInt(loadedInfo.Retardo)}
                                </>
                              }
                              className="Karla m1 tcap"
                            />
                            <Text
                              content={
                                <>
                                  <SurveyIcon outline className="pb2 pr2" />
                                  <Text
                                    content={t("setting").teacher[5]}
                                    color="brand"
                                    className="fw6"
                                  />{" "}
                                  - {parseInt(loadedInfo.Justificado)}
                                </>
                              }
                              className="Karla m1 tcap"
                            />
                          </>
                        ) : load2 ? (
                          <Loader size="small" style={{ paddingTop: "50px" }} />
                        ) : (
                          <Text
                            content={
                              <>
                                <ExclamationCircleIcon
                                  outline
                                  className="pb2 pr2"
                                />
                                <Text
                                  content={t("setting").teacher[7]}
                                  className="fw6"
                                />
                              </>
                            }
                            className="Karla m1 tcap"
                            color="red"
                          />
                        )}
                      </Flex>
                    </Flex>
                  }
                  trigger={
                    <Segment
                      key={teacher.TeacherEmailID}
                      content={
                        <Flex style={{ flexDirection: "column" }}>
                          <Image
                            src={
                              teacher.UrlPhoto
                                ? props.user.slug === "?slug=acvpreprod" ||
                                  props.user.slug === "?slug=acvapp"
                                  ? teacher.UrlPhoto
                                  : teacher.UrlPhoto + props.user.SASToken
                                : userSvg
                            }
                            width="200px"
                            height="200px"
                            className="img"
                          />
                          <Flex style={{ width: "200px" }} wrap>
                            <Text
                              content={teacher.TeacherName}
                              className="Karla fw6 m1 tcap"
                            />
                            {teacher.role === "Admin" ? (
                              <Text
                                color="brand"
                                content={
                                  <>
                                    <Status
                                      style={{
                                        backgroundColor: "#6264a7",
                                        margin: "0 3px 3px 0",
                                      }}
                                    />
                                    Admin
                                  </>
                                }
                                className="Karla fw6 m1 tcap"
                              />
                            ) : teacher.role === "SuperAdmin" ? (
                              <Text
                                color="Yellow"
                                content={
                                  <>
                                    <Status
                                      style={{
                                        backgroundColor: "#FFAA44",
                                        margin: "0 3px 3px 0",
                                      }}
                                    />
                                    Admin
                                  </>
                                }
                                className="Karla fw6 m1 tcap"
                              />
                            ) : (
                              ""
                            )}
                          </Flex>
                        </Flex>
                      }
                      className="seg"
                      onClick={() => getInfo(teacher.TeacherEmailID)}
                      title={teacher.TeacherEmailID}
                      style={{
                        height: "fit-content",
                      }}
                    />
                  }
                />
              </>
              // )
            );
          })
        ) : (
          <p className="btsp text-center" style={{ margin: "50px auto" }}>
            Teachers are not available!
          </p>
        )}
      </Flex>
    </div>
  );
};

export default TeacherData;
