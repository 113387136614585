import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Dialog, Divider, Flex } from "@fluentui/react-northstar";
import { Subscription } from "../../components/Subscription/Subscription";
import CustomFields from "./components/CustomFields/CustomFields";
import School from "./components/School/School";
import StudentData from "./components/StudentData/StudentData";
import TeacherData from "./components/TeacherData/TeacherData";
import SettingPage from "../../Assets/images/svg/SettingPage.svg";
import { Collections } from "../Collections/index";
import { Events } from "../Events/events";
import { LessonImagePicker } from "../LessonImagePicker/LessonImagePicker";
import ProfileSetting from "./components/Profile";
import RubricBank from "./components/Assignment";
import RBACSetting from "./components/RBAC";

import "./Settings.css";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  AssignmentImage,
  CustomFeilds,
  Event,
  Lessons,
  Profile,
  SchoolImage,
  Student,
  Teacher,
  Whiteboard,
} from "../../Assets/icons/icons";
import Credits from "../GptCredits/Credits";
import ACVExtension from "../ACVExtension/ACVExtension";
import axios from "axios";
import { toast } from "react-toastify";
import Parents from "./components/Parents";

const Settings = (props) => {
  const { t } = useTranslation();
  const [accDlg, setAccDlg] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [load, setLoad] = useState(false);

  const history = useHistory();

  const [isTabletOrMobileView, setIsTabletOrMobileView] = useState(
    window.innerWidth <= 991
  );
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  const [creditHistory, setCreditHistory] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [addCategory, setAddCategory] = useState({
    isEdit: false,
    title: "",
  });
  const [addTag, setAddTag] = useState({
    isEdit: false,
    title: "",
  });
  const [addSkill, setAddSkill] = useState({
    isEdit: false,
    title: "",
    category: "",
  });
  const [addInterest, setAddInterest] = useState({
    isEdit: false,
    title: "",
    category: "",
  });
  const [checkInputCaptchaBlankOrNot, setCheckInputCaptchaBlankOrNot] =
    useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getExtentions();
  }, []);

  const getExtentions = async () => {
    const res = await axios
      .get(`${process.env.REACT_APP_EP_URL}/api/extensions${props.user.slug}`, {
        headers: {
          Authorization: "Bearer " + props.user.accessToken,
        },
      })
      .then(async (response) => {
        if (response.data.result.length > 0) {
          props.setSelectedExtension(response.data.result[0]);
        } else {
          const data = {
            whiteBoard: 1,
            attendance: 1,
            assignment: 1,
            feedback: 1,
            dashboard: 1,
            events: 1,
          };
          await updateSettingExtentions(data);
        }
      });
  };

  const updateSettingExtentions = async (
    data,
    setIsOpenConfirmationModal = () => false,
    storeExtention
  ) => {
    const res = await axios
      .post(
        `${process.env.REACT_APP_EP_URL}/api/extensions${props.user.slug}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((response) => {
        setLoader(false);
        toast.success(response.data.result);
        if (setIsOpenConfirmationModal) setIsOpenConfirmationModal(false);
        if (storeExtention) {
          props.setSelectedExtension((prevState) => ({
            ...prevState,
            [storeExtention.name]: !prevState[storeExtention.name],
          }));
        } else {
          props.setSelectedExtension({
            whiteBoard: 1,
            attendance: 1,
            assignment: 1,
            feedback: 1,
            dashboard: 1,
            events: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateExtension = async (
    data,
    setIsOpenConfirmationModal,
    storeExtention,
    userInput,
    captchaText,
    canvasRef,
    initializeCaptcha
  ) => {
    if (!userInput) {
      setCheckInputCaptchaBlankOrNot(true);
    } else {
      setCheckInputCaptchaBlankOrNot(false);
      if (userInput === captchaText) {
        setLoader(true);
        await updateSettingExtentions(
          data,
          setIsOpenConfirmationModal,
          storeExtention
        );
      } else {
        setCheckInputCaptchaBlankOrNot(true);
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        initializeCaptcha(ctx);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsTabletOrMobileView(window.innerWidth <= 991);
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth > 767 && window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.user?.dbData?.subscription) {
      if (props.user?.dbData?.subscription?.dashboard) {
        setIsSubscribed(true);
        setLoad(false);
        return;
      }
      setIsSubscribed(false);
      setLoad(false);
    } else {
      setIsSubscribed(false);
      setLoad(false);
    }

    if (props.user?.dbData?.dayRemaining <= 0) {
      setIsExpire(true);
    }
  }, []);

  useEffect(() => {
    if (props.user.role === "Admin") {
      if (
        props.location.pathname === "/settings/manage/customfields" ||
        props.location.pathname === "/settings/manage/school"
      ) {
        history.push("/settings");
      }
    }
    if (props.user.role === "Teacher" || props.user.role === "Student") {
      history.push("/");
    }
  }, [props.location.pathname]);

  return (
    <div>
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton="Refresh Now"
        content={
          <p className="refreshMsg">
            No big deal — but we need to refresh to show your content.
          </p>
        }
        header="Authorization expire"
      />
      <Header
        path="settings"
        user={{ ...props.user }}
        logout={props.logout.bind()}
        accessToken={props.user.accessToken}
        getDb={props.getDb.bind()}
        switchDb={props.switchDb}
        isNew={isNew}
        addCategory={addCategory}
        addTag={addTag}
        addSkill={addSkill}
        addInterest={addInterest}
        selectedExtension={props.selectedExtension}
        setSelectedExtension={props.setSelectedExtension}
      />
      <div
        className="setting"
        style={{
          overflow:
            !isTabletOrMobileView &&
            props.location.pathname === "/settings/manage/customfields"
              ? "auto"
              : "hidden",
        }}
      >
        {!isExpire || (isSubscribed && load) ? (
          <div
            className="manage"
            style={{
              position: "relative",
              paddingLeft: isTabletOrMobileView ? "10px" : "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            {!isTabletOrMobileView && (
              <Flex
                gap="gap.small"
                vAlign="center"
                hAlign="center"
                wrap
                style={{
                  position: "sticky",
                  top: "3%",
                  backgroundColor: "white",
                  zIndex: "1",
                }}
              >
                <p className="f30 bold">{t("setting").init[0]}</p>
                <Flex gap="gap.small" vAlign="center" hAlign="center" wrap>
                  {props.location.pathname === "/settings/manage/lessons" ? (
                    <Button
                      className="m2"
                      content={t("setting").names[0]}
                      onClick={() => history.push("/settings/manage/lessons")}
                      primary
                    />
                  ) : (
                    <Button
                      className="m2"
                      content={t("setting").names[0]}
                      onClick={() => history.push("/settings/manage/lessons")}
                      secondary
                    />
                  )}

                  {props.location.pathname === "/settings/manage/students" ? (
                    <Button
                      className="m2"
                      content={t("setting").names[1]}
                      onClick={() => history.push("/settings/manage/students")}
                      primary
                    />
                  ) : (
                    <Button
                      className="m2"
                      content={t("setting").names[1]}
                      onClick={() => history.push("/settings/manage/students")}
                      secondary
                    />
                  )}

                  {props.location.pathname === "/settings/manage/teachers" ? (
                    <Button
                      className="m2"
                      content={t("setting").names[2]}
                      onClick={() => history.push("/settings/manage/teachers")}
                      primary
                    />
                  ) : (
                    <Button
                      className="m2"
                      content={t("setting").names[2]}
                      onClick={() => history.push("/settings/manage/teachers")}
                      secondary
                    />
                  )}
                  {props.location.pathname === "/settings/manage/parents" ? (
                    <Button
                      className="m2"
                      content={t("setting").names[9]}
                      onClick={() => history.push("/settings/manage/parents")}
                      primary
                    />
                  ) : (
                    <Button
                      className="m2"
                      content={t("setting").names[9]}
                      onClick={() => history.push("/settings/manage/parents")}
                      secondary
                    />
                  )}
                  {props.user && props.user.role === "SuperAdmin" ? (
                    props.location.pathname ===
                    "/settings/manage/customfields" ? (
                      <Button
                        className="m2"
                        content={t("setting").names[3]}
                        onClick={() =>
                          history.push("/settings/manage/customfields")
                        }
                        primary
                      />
                    ) : (
                      <Button
                        className="m2"
                        content={t("setting").names[3]}
                        onClick={() =>
                          history.push("/settings/manage/customfields")
                        }
                        secondary
                      />
                    )
                  ) : null}
                  {props.user && props.user.role === "SuperAdmin" ? (
                    props.location.pathname === "/settings/manage/school" ? (
                      <Button
                        className="m2"
                        content={t("setting").names[4]}
                        onClick={() => history.push("/settings/manage/school")}
                        primary
                      />
                    ) : (
                      <Button
                        className="m2"
                        content={t("setting").names[4]}
                        onClick={() => history.push("/settings/manage/school")}
                        secondary
                      />
                    )
                  ) : null}
                  {props.user && props.user.role === "SuperAdmin" ? (
                    <Button
                      className="m2"
                      content={t("setting").names[5]}
                      onClick={() =>
                        history.push("/settings/manage/assignment")
                      }
                      primary={
                        props.location.pathname ===
                        "/settings/manage/assignment"
                      }
                    />
                  ) : null}
                  {props.user && props.user.role === "SuperAdmin" ? (
                    props.location.pathname ===
                    "/settings/manage/whiteboard" ? (
                      <Button
                        className="m2"
                        content={t("setting").names[6]}
                        onClick={() =>
                          history.push("/settings/manage/whiteboard")
                        }
                        primary
                      />
                    ) : (
                      <Button
                        className="m2"
                        content={t("setting").names[6]}
                        onClick={() =>
                          history.push("/settings/manage/whiteboard")
                        }
                        secondary
                      />
                    )
                  ) : null}

                  {props.user && props.user.role === "SuperAdmin" ? (
                    props.location.pathname === "/settings/manage/events" ? (
                      <Button
                        className="m2"
                        content={t("setting").names[7]}
                        onClick={() => history.push("/settings/manage/events")}
                        primary
                      />
                    ) : (
                      <Button
                        className="m2"
                        content={t("setting").names[7]}
                        onClick={() => history.push("/settings/manage/events")}
                        secondary
                      />
                    )
                  ) : null}
                  {props.user && props.user.role === "SuperAdmin" ? (
                    <>
                      <Button
                        className="m2"
                        content={t("setting").names[8]}
                        onClick={() => history.push("/settings/manage/profile")}
                        primary={
                          props.location.pathname === "/settings/manage/profile"
                        }
                      />
                      <Button
                        className="m2"
                        content={"RBAC"}
                        onClick={() => history.push("/settings/manage/rbac")}
                        primary={
                          props.location.pathname === "/settings/manage/rbac"
                        }
                      />
                    </>
                  ) : null}
                  {props.user &&
                  props.user.role === "SuperAdmin" &&
                  process.env.REACT_APP_PRE_VERSION ? (
                    <Button
                      className="m2"
                      content="GPTCredit"
                      onClick={() => history.push("/settings/manage/gptcredit")}
                      primary={
                        props.location.pathname === "/settings/manage/gptcredit"
                      }
                    />
                  ) : null}
                  {props.user && props.user.role === "SuperAdmin" ? (
                    <Button
                      className="m2"
                      content="Extensions"
                      onClick={() =>
                        history.push("/settings/manage/extensions")
                      }
                      primary={
                        props.location.pathname ===
                        "/settings/manage/extensions"
                      }
                    />
                  ) : null}
                </Flex>
              </Flex>
            )}

            <Divider style={{ display: isTabletOrMobileView && "none" }} />

            <Box className="lesson-heading">
              {props.location.pathname === "/settings/manage/lessons" && (
                <h1 className="Heading-settings">Lessons Image</h1>
              )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/students" && (
                  <h1 className="Heading-settings">Students</h1>
                )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/teachers" && (
                  <h1 className="Heading-settings">Teachers</h1>
                )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/parents" && (
                  <h1 className="Heading-settings">Parents</h1>
                )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/customfields" && (
                  <h1 className="Heading-settings">Custom Fields</h1>
                )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/school" && (
                  <h1 className="Heading-settings">Schools</h1>
                )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/assignment" && (
                  <h1 className="Heading-settings">Assignments</h1>
                )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/whiteboard" && (
                  <h1 className="Heading-settings">Whiteboard</h1>
                )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/events" && (
                  <h1 className="Heading-settings">Events</h1>
                )}
              {isTabletOrMobileView &&
                props.location.pathname === "/settings/manage/profile" && (
                  <h1 className="Heading-settings">Profile</h1>
                )}
            </Box>

            <Box
              sx={{
                ...(isTabletOrMobileView ? { display: "flex" } : {}),
              }}
            >
              {isTabletOrMobileView && (
                <div
                  className="manage-smallScreen"
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    // padding: 12,
                    paddingRight: 12,
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    background: "white",
                    // boxShadow:
                    //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    borderRadius: 5,
                    // zIndex: 2,
                  }}
                >
                  <Tooltip title="Lessons" placement="right">
                    <IconButton
                      onClick={() => history.push("/settings/manage/lessons")}
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/lessons" && "rgb(98, 100, 167)",
                      }}
                    >
                      <Lessons
                        color={
                          props.location.pathname === "/settings/manage/lessons"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Students" placement="right">
                    <IconButton
                      onClick={() => history.push("/settings/manage/students")}
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/students" && "rgb(98, 100, 167)",
                      }}
                    >
                      <Student
                        color={
                          props.location.pathname ===
                          "/settings/manage/students"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Teachers" placement="right">
                    <IconButton
                      onClick={() => history.push("/settings/manage/teachers")}
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/teachers" && "rgb(98, 100, 167)",
                      }}
                    >
                      <Teacher
                        color={
                          props.location.pathname ===
                          "/settings/manage/teachers"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Parents" placement="right">
                    <IconButton
                      onClick={() => history.push("/settings/manage/parents")}
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/parents" && "rgb(98, 100, 167)",
                      }}
                    >
                      <Teacher
                        color={
                          props.location.pathname === "/settings/manage/parents"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Custom Fields" placement="right">
                    <IconButton
                      onClick={() =>
                        history.push("/settings/manage/customfields")
                      }
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/customfields" &&
                          "rgb(98, 100, 167)",
                      }}
                    >
                      <CustomFeilds
                        color={
                          props.location.pathname ===
                          "/settings/manage/customfields"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Schools" placement="right">
                    <IconButton
                      onClick={() => history.push("/settings/manage/school")}
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/school" && "rgb(98, 100, 167)",
                      }}
                    >
                      <SchoolImage
                        color={
                          props.location.pathname === "/settings/manage/school"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Assignments" placement="right">
                    <IconButton
                      onClick={() =>
                        history.push("/settings/manage/assignment")
                      }
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/assignment" &&
                          "rgb(98, 100, 167)",
                      }}
                    >
                      <AssignmentImage
                        color={
                          props.location.pathname ===
                          "/settings/manage/assignment"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Whiteboard" placement="right">
                    <IconButton
                      onClick={() =>
                        history.push("/settings/manage/whiteboard")
                      }
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/whiteboard" &&
                          "rgb(98, 100, 167)",
                      }}
                    >
                      <Whiteboard
                        color={
                          props.location.pathname ===
                          "/settings/manage/whiteboard"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Events" placement="right">
                    <IconButton
                      onClick={() => history.push("/settings/manage/events")}
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/events" && "rgb(98, 100, 167)",
                      }}
                    >
                      <Event
                        color={
                          props.location.pathname === "/settings/manage/events"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Profile" placement="right">
                    <IconButton
                      onClick={() => history.push("/settings/manage/profile")}
                      style={{
                        background:
                          props.location.pathname ===
                            "/settings/manage/profile" && "rgb(98, 100, 167)",
                      }}
                    >
                      <Profile
                        color={
                          props.location.pathname === "/settings/manage/profile"
                            ? "white"
                            : "#292D32"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              <div
                className="container2"
                style={{
                  overflow:
                    props.location.pathname === "/settings/manage/school"
                      ? "auto"
                      : "hidden",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {props.location.pathname === "/settings/manage/events" ? (
                  <Events
                    user={props.user}
                    isMobileView={isMobileView}
                    addCategory={addCategory}
                    setAddCategory={setAddCategory}
                    addTag={addTag}
                    setAddTag={setAddTag}
                  />
                ) : props.location.pathname ===
                  "/settings/manage/whiteboard" ? (
                  <Collections
                    user={props.user}
                    isTabletOrMobileView={isTabletOrMobileView}
                    isMobileView={isMobileView}
                    isTabletView={isTabletView}
                  />
                ) : props.location.pathname === "/settings/manage/lessons" ? (
                  <LessonImagePicker
                    user={props.user}
                    isTabletOrMobileView={isTabletOrMobileView}
                  />
                ) : props.location.pathname === "/settings/manage/students" ? (
                  <StudentData
                    user={props.user}
                    setAccDlg={(e) => {
                      setAccDlg(e);
                    }}
                    isTabletOrMobileView={isTabletOrMobileView}
                    isMobileView={isMobileView}
                    isTabletView={isTabletView}
                  />
                ) : props.location.pathname === "/settings/manage/parents" ? (
                  <Parents
                    user={props.user}
                    setAccDlg={(e) => {
                      setAccDlg(e);
                    }}
                    isTabletOrMobileView={isTabletOrMobileView}
                    isMobileView={isMobileView}
                  />
                ) : props.location.pathname === "/settings/manage/teachers" ? (
                  <TeacherData
                    user={props.user}
                    setAccDlg={(e) => {
                      setAccDlg(e);
                    }}
                    isTabletOrMobileView={isTabletOrMobileView}
                    isMobileView={isMobileView}
                  />
                ) : props.location.pathname ===
                  "/settings/manage/customfields" ? (
                  <CustomFields
                    user={props.user}
                    setAccDlg={(e) => {
                      setAccDlg(e);
                    }}
                    isMobileView={isMobileView}
                    isTabletOrMobileView={isTabletOrMobileView}
                  />
                ) : props.location.pathname === "/settings/manage/school" ? (
                  <School
                    user={props.user}
                    setAccDlg={(e) => {
                      setAccDlg(e);
                    }}
                    getDb={() => props.getDb()}
                    isTabletOrMobileView={isTabletOrMobileView}
                  />
                ) : props.location.pathname === "/settings/manage/profile" ? (
                  <ProfileSetting
                    addSkill={addSkill}
                    setAddSkill={setAddSkill}
                    user={props.user}
                    addInterest={addInterest}
                    setAddInterest={setAddInterest}
                  />
                ) : props.location.pathname ===
                  "/settings/manage/assignment" ? (
                  <RubricBank
                    user={props.user}
                    isMobileView={isMobileView}
                    isNew={isNew}
                    setIsNew={setIsNew}
                  />
                ) : props.location.pathname === "/settings/manage/rbac" ? (
                  <RBACSetting user={props.user} />
                ) : props.location.pathname === "/settings/manage/gptcredit" ? (
                  <Credits
                    user={props.user}
                    isTabletOrMobileView={isTabletOrMobileView}
                    isMobileView={isMobileView}
                    isTabletView={isTabletView}
                    setCreditHistory={setCreditHistory}
                    creditHistory={creditHistory}
                  />
                ) : props.location.pathname ===
                  "/settings/manage/extensions" ? (
                  <ACVExtension
                    user={props.user}
                    selectedExtension={props.selectedExtension}
                    setSelectedExtension={props.setSelectedExtension}
                    updateExtension={updateExtension}
                    setCheckInputCaptchaBlankOrNot={
                      setCheckInputCaptchaBlankOrNot
                    }
                    checkInputCaptchaBlankOrNot={checkInputCaptchaBlankOrNot}
                    loader={loader}
                  />
                ) : (
                  <Flex
                    wrap
                    vAlign="center"
                    hAlign="center"
                    // style={{ flexDirection: "column" }}
                  >
                    <p className="Karla">{t("setting").init[1]}</p>
                    <img
                      alt="ACV - Tangible IT"
                      src={SettingPage}
                      style={{ width: "90%" }}
                      draggable="false"
                    />
                  </Flex>
                )}
              </div>
            </Box>
          </div>
        ) : (
          <Subscription
            isExpire={isExpire}
            role={props.user.role}
            pageName={"Setting"}
          />
        )}
      </div>
    </div>
  );
};

export default Settings;
