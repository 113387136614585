import React from "react";
import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";

import Dashboard from "./Reports/Dashboard";

import SubjectReport from "./Reports/SubjectReport";
import StudentReport from "./Reports/StudentReport";
import TeacherReport from "./Reports/TeacherReport";
import SpecialReport from "./Reports/SpecialReport";

import AssignmentSubmissionReport from "./Reports/AssignmentReports/AssignmentSubmissionReport";
import AssignmentReportByTeacher from "./Reports/AssignmentReports/AssignmentReportByTeacher";
import AssignmentReportBySubject from "./Reports/AssignmentReports/AssignmentReportBySubject";

import CategoryReport from "./Reports/EventReports/CategoryReport";
import TagReport from "./Reports/EventReports/TagReport";

import FeedbackReport from "./Reports/FeedbackReports/FeedbackReport";
import { useChartContext } from "../../context/ChartContext";

const ChartRouter = (data) => {
  const { reportOptions, selectedReportPath, setSelectedReportPath } =
    useChartContext();
  let { path } = useRouteMatch();

  const formattedReports = reportOptions?.length
    ? reportOptions.flatMap(({ reports }) => reports)
    : [];

  let reports = [
    {
      ReportComponent: SubjectReport,
      mainPath: "attendance-report-by-subject",
    },
    {
      ReportComponent: StudentReport,
      mainPath: "attendance-report-by-student",
    },
    {
      ReportComponent: TeacherReport,
      mainPath: "attendance-report-by-teacher",
    },
    {
      ReportComponent: SpecialReport,
      mainPath: "attendance-special-report",
    },
    {
      ReportComponent: AssignmentReportBySubject,
      mainPath: "assignment-report-by-subject",
    },
    {
      ReportComponent: AssignmentSubmissionReport,
      mainPath: "assignment-report-by-student",
    },
    {
      ReportComponent: AssignmentReportByTeacher,
      mainPath: "assignment-report-by-teacher",
    },
    {
      ReportComponent: CategoryReport,
      mainPath: "event-report-by-category",
    },
    {
      ReportComponent: TagReport,
      mainPath: "event-report-by-tag",
    },
    {
      ReportComponent: FeedbackReport,
      mainPath: "feedback-report",
    },
  ];

  return (
    <>
      {selectedReportPath &&
        reports
          .filter(({ mainPath }) => mainPath === selectedReportPath)
          .map(({ ReportComponent, mainPath }) => (
            <ReportComponent
              key={mainPath}
              {...data}
              setSelectedReportPath={setSelectedReportPath}
              mainPath={selectedReportPath}
            />
          ))}

      {!selectedReportPath && <Dashboard {...data} />}
    </>
  );
};

export default ChartRouter;
