import React, { useState, useEffect } from "react";
import {
  Flex,
  CardHeader,
  CardBody,
  Card,
  Text,
  Grid,
  Loader,
} from "@fluentui/react-northstar";
import axios from "axios";

import { Avatar, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import { ResponsiveImage } from "../../../../components/blocks/Images";

import Imgart from "../../../../Assets/images/subjects/art.png";
import Imgcivil from "../../../../Assets/images/subjects/civil.png";
import Imghistory from "../../../../Assets/images/subjects/history.png";
import ImgnaturalScience from "../../../../Assets/images/subjects/natural-science.png";
import Imgphilosophy from "../../../../Assets/images/subjects/philosophy.png";
import ImgscienceExact from "../../../../Assets/images/subjects/science-exact.png";
import Imgtech from "../../../../Assets/images/subjects/tech.png";
import Imgother from "../../../../Assets/images/subjects/other.png";

import NoClassRooms from "../../../../Assets/images/svg/school.svg";

import "./Classrooms.scss";

const url = process.env.REACT_APP_EP_URL;

const ClassRoomSection = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));

  @media (min-width: 375px) and (max-width: 936px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const ClassRoomCard = styled(Flex)`
  margin: 10px;
`;

const TitleStudentSection = styled(Flex)`
  .title-wrapper {
    max-width: calc(100% - 110px);
    .title-section {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .title {
        font-size: 16px;
        display: unset;
      }
    }
  }
`;

const Classrooms = (props) => {
  const { user, t, globalSearch } = props;

  const translation = t("feedbackModule");

  const feedbackTranslation = translation.body.classroom;

  const history = useHistory();

  const [classrooms, setClassrooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredClassrooms, setFilteredClassrooms] = useState([]);

  const getClassrooms = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${url}/api/classroom${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result?.length) {
        setClassrooms(
          data.result.map(({ ClassID, ClassName, StudentData, ImageURL }) => ({
            classroomId: ClassID,
            classroomTitle: ClassName,
            students: StudentData?.length
              ? StudentData.map(
                  ({ firstName, lastName, email, photo, ...rest }) => ({
                    ...rest,
                    studentEmailId: email,
                    name: `${firstName}${lastName ?? ""}`,
                    studentImageUrl: photo,
                  })
                )
              : [],
            classroomsImageUrl: ImageURL,
          }))
        );
      } else {
        setClassrooms([]);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getClassrooms();
  }, []);

  useEffect(() => {
    let filteredData = classrooms;
    if (globalSearch) {
      filteredData = filteredData.filter((classroom) =>
        classroom.classroomTitle
          ?.replace(/\s/g, "")
          ?.toLowerCase()
          ?.includes(globalSearch)
      );
    }
    setFilteredClassrooms(filteredData);
  }, [globalSearch, classrooms]);

  const getDefaultClassroomImage = (title) => {
    if (title) {
      return title?.includes("Art")
        ? Imgart
        : title.includes("Civismo")
        ? Imgcivil
        : title.includes("Hist")
        ? Imghistory
        : title.includes("Science")
        ? ImgnaturalScience
        : title.includes("losof")
        ? Imgphilosophy
        : title.includes("Ciencias Exactas")
        ? ImgscienceExact
        : title.includes("Tec")
        ? Imgtech
        : Imgother;
    } else {
      return Imgother;
    }
  };

  const myCard = ({ classroom }) => {
    return (
      <Card
        elevated
        className="fit-content"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
        }}
        onClick={() => {
          history.push(`/feedback/classrooms/${classroom.classroomId}`, {
            classroom,
          });
        }}
      >
        <CardHeader>
          <Flex gap="gap.small" column>
            <img
              alt={feedbackTranslation.imgAlt}
              src={
                classroom.classroomsImageUrl
                  ? classroom.classroomsImageUrl + user.SASToken
                  : getDefaultClassroomImage(classroom.classroomTitle)
              }
              onError={(e) => {
                e.target.src = Imgother;
              }}
              style={{
                height: "160px",
                borderRadius: "10px 10px 0 0",
                objectFit: "cover",
              }}
              draggable="false"
              className="img-width"
            />
          </Flex>
        </CardHeader>
        <CardBody>
          <TitleStudentSection
            space="between"
            vAlign="center"
            style={{ padding: "0 10px", minHeight: "32px" }}
          >
            <Flex className="title-wrapper">
              <div className="title-section">
                <Tooltip placement="bottom" title={classroom.classroomTitle}>
                  <Text weight="semibold" align="start" className="title">
                    {classroom.classroomTitle}
                  </Text>
                </Tooltip>
              </div>
            </Flex>
            <Flex>
              {classroom.students?.length ? (
                <Avatar.Group
                  maxPopoverTrigger="hover"
                  maxCount={2}
                  size="default"
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    cursor: "pointer",
                  }}
                >
                  {classroom.students.map((student, index) => (
                    <Avatar key={index} src={student.studentImageUrl}>
                      <Tooltip title={student.name}>
                        <Text>
                          {student.name?.length
                            ? student.name[0]
                            : student.studentEmailId?.[0]}
                        </Text>
                      </Tooltip>
                    </Avatar>
                  ))}
                </Avatar.Group>
              ) : (
                <div />
              )}
            </Flex>
          </TitleStudentSection>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="classrooms-container">
      <div className="classrooms">
        <div className="all-classrooms">
          <Text
            size="larger"
            weight="semibold"
            style={{ marginLeft: "10px", color: "#6264a7" }}
          >
            {feedbackTranslation.header}
          </Text>

          {isLoading ? (
            <Loader style={{ paddingTop: "50px" }} />
          ) : filteredClassrooms.length ? (
            <div className="classrooms-cards-wrapper">
              <ClassRoomSection>
                {filteredClassrooms.map((classroom) => {
                  return (
                    <ClassRoomCard key={classroom.classroomId}>
                      {myCard({ classroom })}
                    </ClassRoomCard>
                  );
                })}
              </ClassRoomSection>
            </div>
          ) : (
            <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
              <ResponsiveImage src={NoClassRooms} style={{ width: "450px" }} />
              <Text weight="semibold" size="large">
                {classrooms.length
                  ? feedbackTranslation.noClassroomForFilter
                  : feedbackTranslation.noClassroom}
              </Text>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Classrooms);
