import React from "react";

import Chart from "react-apexcharts";

import { Flex } from "@fluentui/react-northstar";

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {},
    };
  }

  updateStates() {
    const url = window.location.pathname.split("/").pop();
    this.setState({ series: [...this.props.series] });
    this.setState({
      options: {
        chart: {
          type: "bar",
          // height: 350,
          stacked: this.props.stacked,
        },
        // colors: ['#0A81AB', '#F54748', '#FB9300', '#39A6A3', '#343F56'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "80%",
            endingShape: "rounded",
            // borderRadius: document.documentElement.clientWidth>1360?this.props.radius:2,
            borderRadius: this.props.radius || 2,
          },
        },
        dataLabels: {
          enabled: this.props.stacked,
        },
        legend: {
          position: "top",
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.props.categories,
          labels: {
            show: true,
            hideOverlappingLabels: true,
          },
          title: {
            text: this.props.lableX,
            offsetY:
              url === "attendance-report-by-subject"
                ? 140
                : url === "attendance-report-by-student"
                ? 105
                : 95,
          },
        },
        yaxis: {
          title: {
            text: this.props.lableY,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        responsive: [
          {
            breakpoint: 1360,
            options: {
              chart: {
                width: 700,
                height: 400,
              },
            },
          },
          {
            breakpoint: 1350,
            options: {
              chart: {
                width: 650,
              },
            },
          },
          {
            breakpoint: 1300,
            options: {
              chart: {
                width: 800,
                height: 450,
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                },
              },
            },
          },
          {
            breakpoint: 1060,
            options: {
              chart: {
                width: 700,
                height: 450,
              },
            },
          },
          {
            breakpoint: 800,
            options: {
              chart: {
                width: 650,
              },
              plotOptions: {
                bar: {
                  borderRadius: 8,
                },
              },
            },
          },
          {
            breakpoint: 750,
            options: {
              chart: {
                width: 630,
              },
              plotOptions: {
                bar: {
                  borderRadius: 6,
                },
              },
            },
          },
          {
            breakpoint: 700,
            options: {
              chart: {
                width: 580,
              },
            },
          },
          {
            breakpoint: 650,
            options: {
              chart: {
                width: 500,
                height: 400,
              },
            },
          },
          {
            breakpoint: 560,
            options: {
              chart: {
                width: 400,
                height: 350,
              },
              plotOptions: {
                bar: {
                  borderRadius: 3,
                },
              },
              // dataLabels: {
              //   enabled: ,
              // },
            },
          },
          {
            breakpoint: 460,
            options: {
              chart: {
                width: 370,
              },
            },
          },
          {
            breakpoint: 440,
            options: {
              chart: {
                width: 320,
                height: 300,
              },
            },
          },
          {
            breakpoint: 390,
            options: {
              chart: {
                width: 280,
                height: 280,
              },
            },
          },
        ],
      },
    });
  }

  componentWillReceiveProps() {
    this.updateStates();
  }

  render() {
    let width =
      document.documentElement.clientWidth < 500
        ? document.documentElement.clientWidth - 56
        : (document.documentElement.clientWidth * 51) / 100;
    return (
      <div className="app">
        <div className="row" style={{ padding: 0 }}>
          <div className="mixed-chart" style={{ maxWidth: "100%" }}>
            <Flex gap="gap.small" className="Boxes">
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                width={width}
                height={500}
              />
            </Flex>
          </div>
        </div>
      </div>
    );
  }
}

export default ApexChart;
